import { Box } from "@mui/material";
import { FilterDropdown, PageHeaderTitle, SearchContainer, SearchInput } from "suregifts-component-lib";
const statuses = [{ text: "All" }, { text: "Pending", value: 0 }, { text: "Declined", value: 2 }];
function FilterSection({ handleFilter, filter }) {
  return (
    <div>
      <SearchContainer sx={{ marginTop: "40px" }}>
        <PageHeaderTitle title="Applications" />
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <FilterDropdown
            display="ALL"
            options={statuses}
            value={filter.status}
            onChange={(index) => {
              handleFilter({ status: statuses[index].value });
            }}
          />
          <SearchInput
            sx={{ width: "25rem" }}
            value={filter.search}
            onChange={(e) => {
              handleFilter({ search: e, page: 1 });
            }}
          />
        </Box>
      </SearchContainer>
    </div>
  );
}

export default FilterSection;
