import { Box } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SearchContainer, PageHeaderTitle, SearchButton } from "suregifts-component-lib";
function FilterSection({}) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  return (
    <div style={{ marginTop: "40px" }}>
      <SearchContainer>
        <PageHeaderTitle title={`${searchParams.get("store") || ""} Personnels`} />
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <SearchButton sx={{ py: "0.125rem" }} label="Add Personnel" onClick={() => navigate("add")} />
        </Box>
      </SearchContainer>
    </div>
  );
}

export default FilterSection;
