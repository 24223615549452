import { useQuery, useMutation } from "react-query";
import { useAxios, Count, ServerError, WalletType, RefundStatusType, PageFilter } from "suregifts-component-lib";

export const useGetRefunds = (filter: RefundFilter) => {
  const axios = useAxios();

  const path = "/transactions/api/refunds";
  return useQuery<Count<RefundData>, ServerError>({
    queryKey: ["refunds", filter],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    queryFn: () =>
      axios.get(path, {
        params: filter,
      }),
  });
};

export const useRejectRefund = () => {
  const axios = useAxios();

  return useMutation<unknown, ServerError, RefundModel>((data) => {
    const path = `/transactions/api/refunds/${data.refundId}/reject`;
    return axios.post(path, data);
  });
};

export const useConfirmRefund = () => {
  const axios = useAxios();

  return useMutation<unknown, ServerError, RefundModel>((data) => {
    const path = `/transactions/api/refunds/${data.refundId}/confirm`;
    return axios.post(path, data);
  });
};
export const useExportRefunds = (filter: RefundFilter) => {
  var axios = useAxios();

  const path = `/transactions/api/refunds/export`;
  return useMutation(() => {
    return axios.get(path, {
      params: filter,
    });
  });
};
export interface RefundData {
  refundNumber: string;
  amount: string;
  extraInfo: object;
  walletTypeId: WalletType;
  walletType: string;
  reviewedTime: string;
  reviewedBy: string;
  statusId: RefundStatusType;
  status: string;
  note: string;
  externalId: string;
  transactionTypeId: number;
  transactionType: string;
  narration: string;
  dateCreated: Date;
  email: string;
  firstName: string;
  lastName: string;
}

export interface RefundModel {
  note: string;
  refundMethod: any;
  refundId: any;
}

export interface RefundFilter extends PageFilter {
  fromDate?: Date;
  toDate?: Date;
  status?: any;
}
