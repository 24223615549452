import { useMutation, useQuery } from "react-query";
import { Count, PageFilter, ServerError, UserStatusType, useAxios } from "suregifts-component-lib";

export const useGetUsers = (filter: UserFilter) => {
  const axios = useAxios();
  return useQuery<Count<UserData>, ServerError>({
    queryKey: ["users", filter],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    queryFn: () =>
      axios.get("/user-security/api/customers", {
        params: filter,
      }),
  });
};
export const useExportUsers = (filter: UserFilter) => {
  var axios = useAxios();
  return useMutation(() => {
    return axios.get(`/user-security/api/customers/export`, {
      params: filter,
    });
  });
};
export const useGetUserById = (userId: any) => {
  const axios = useAxios();
  return useQuery<UserInfoData, ServerError>({
    queryKey: ["user-byId", { userId }],
    keepPreviousData: true,
    queryFn: () => axios.get(`/user-security/api/customers/${userId}`, {}),
  });
};
export const useChangeUserStatus = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, ChangeStatusModel>((model) => {
    return axios.post(`/user-security/api/customers/${model.userId}/status`, model);
  });
};
export const useVerifyUser = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, any>((userId) => {
    return axios.post(`/user-security/api/customers/${userId}/verify`);
  });
};
export interface UserFilter extends PageFilter {
  status: any;
}
export interface UserData {
  isVerified: boolean;
  id: string;
  phoneNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  statusId: UserStatusType;
  status: string;
  registeredDate: Date;
  lastLoginDate: string;
}
export interface UserInfoData extends UserData {
  walletBalance: number;
}
export interface ChangeStatusModel {
  statusId: UserStatusType;
  userId: any;
}
