import { Outlet, createBrowserRouter } from "react-router-dom";
import AccountLandingScreen from "../pages/account/AccountLandingScreen";
import CustomerSupportLanding from "../pages/customer-support/CustomerSupportLanding";
import AuthScreen from "../pages/auth/AuthScreen";
import SuperAdminLanding from "../pages/super-admin/SuperAdminLanding";
import { accountRoutes } from "./accountRoutes";
import { customerSuppporRoutes } from "./customerSupport.routes";
import { RequireAuth } from "./RequireAuth";
import { superAdminRoutes } from "./superAdminRoutes";
import MerchantBackendLanding from "../pages/merchant-backend/MerchantBackendLanding";
import { merchanBackendRoutes } from "./merchanBackendRoutes";
import { merchanDashboardRoutes } from "./merchanDashboardRoutes";
import NavBar from "../pages/root/nav/NavBar";
import { AdminRootScreen, ScrollToTop } from "suregifts-component-lib";
import { LoginForm } from "../pages/auth/login/LoginForm";
import { ForgotPasswordForm } from "../pages/auth/login/ForgotPasswordForm";
import { MerchantDashboardContextProvider } from "../pages/merchant-dashboard/MerchantDashboardContext";

export const router = createBrowserRouter([
  {
    element: (
      <ScrollToTop>
        <Outlet />
      </ScrollToTop>
    ),
    children: [
      {
        element: <AuthScreen />,
        children: [
          { path: "login", element: <LoginForm /> },
          {
            path: "forgot-password",
            element: <ForgotPasswordForm />,
          },
        ],
      },
      {
        path: "merchant-dashboard",
        element: <MerchantDashboardContextProvider />,
        children: merchanDashboardRoutes,
      },
      {
        path: "/",
        element: (
          <RequireAuth>
            <AdminRootScreen nav={<NavBar />} showModeView={false} />
          </RequireAuth>
        ),
        errorElement: (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", flexDirection: "column" }}>
            <span style={{ fontSize: "120px", fontWeight: 700 }}>404</span>
            <span>Not found</span>
            <a style={{ textDecoration: "none" }} href="/">
              Go to home page
            </a>
          </div>
        ),
        children: [
          {
            path: "customer-support",
            element: <CustomerSupportLanding />,
            children: customerSuppporRoutes,
          },
          {
            path: "super-admin",
            element: <SuperAdminLanding />,
            children: superAdminRoutes,
          },
          {
            path: "account",
            element: <AccountLandingScreen />,
            children: accountRoutes,
          },

          {
            path: "merchant-backend",
            element: <MerchantBackendLanding />,
            children: merchanBackendRoutes,
          },
        ],
      },
    ],
  },
]);
