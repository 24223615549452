import { Box, Typography, Button } from "@mui/material";
type CheckBalanceButtonViewProps = {
  onClick?: () => void;
  label?: string;
};
export const CheckBalanceButtonView = ({ onClick, label = "Check Balance" }: CheckBalanceButtonViewProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        borderRadius: 6,
        backgroundColor: "primary.main",
        padding: "1.5625rem 1.25rem 1.5625rem 1.875rem",
        alignItems: "center",
        paddingInline: 3,
        gap: "0.9375rem",
        textTransform: "uppercase",
        maxHeight: "6.25rem",
        position: "relative",
      }}
    >
      <Typography sx={{ color: "text.secondary", fontSize: "0.625rem", alignSelf: "start", lineHeight: "0.75rem" }}>Check voucer Balance</Typography>
      <Button onClick={onClick} variant="contained" color="success" sx={{ height: "2.25rem", borderRadius: "0.625rem" }} fullWidth>
        {label}
      </Button>
    </Box>
  );
};
