import { Dialog, DialogActions, DialogContent, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { DialogTitle, FormButton, FormInput } from "suregifts-component-lib";
import { CreditDebitModel } from "../../../services/merchant-wallet";
type CreditDebitModalProps = {
  title: string;
  onSubmit?: (amount: CreditDebitModel) => void;
  handleClose?: () => void;
};
function CreditDebitModal({ title, onSubmit = () => {}, handleClose }: CreditDebitModalProps) {
  const {
    register,
    formState: { isValid },
    handleSubmit,
  } = useForm<CreditDebitModel>({
    defaultValues: {
      amount: "",
      narration: "",
    },
  });
  return (
    <Dialog open maxWidth="xs">
      <DialogTitle title={title} handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={2}>
          <FormInput label="Amount" {...register("amount")} />
          <FormInput label="Amount" {...register("narration")} multiline size="medium" />
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton disabled={!isValid} onClick={handleSubmit(onSubmit)} label="Submit" fullWidth />
      </DialogActions>
    </Dialog>
  );
}

export default CreditDebitModal;
