import { Box } from "@mui/material";
import { FilterDropdown, OptionNaming, PageHeaderTitle, SearchButton, SearchContainer, SearchInput, emptyOption } from "suregifts-component-lib";
import { useGetMerchantNames } from "../../../services/merchants";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

function FilterSection({ handleFilter, filter }) {
  const navigate = useNavigate();
  const { data: merchantNames } = useGetMerchantNames();
  const merchants = useMemo(() => {
    var temp = merchantNames?.filter((x) => !x.isGeneric).map((item) => ({ text: item.name, value: item.id } as OptionNaming));
    temp?.splice(0, 0, emptyOption);
    return temp;
  }, [merchantNames]);
  return (
    <div>
      <SearchContainer sx={{ marginTop: "40px" }}>
        <PageHeaderTitle title={"Stores"} />
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <FilterDropdown
            display="ALL"
            options={merchants}
            value={filter.merchantId}
            onChange={(index) => {
              handleFilter({ merchantId: merchants![index].value });
            }}
          />
          <SearchInput
            sx={{ width: "25rem" }}
            value={filter.search}
            onChange={(e) => {
              handleFilter({ search: e, page: 1 });
            }}
          />
          <SearchButton sx={{ py: "0.125rem" }} label="Create Store" onClick={() => navigate("add")} />
        </Box>
      </SearchContainer>
    </div>
  );
}

export default FilterSection;
