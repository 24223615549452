import { Box } from "@mui/material";
import { useEffect } from "react";
import RecentActivationView from "../../../components/organisms/RecentActivationView";
import RecentRedemptionView from "../../../components/organisms/RecentRedemptionView";
import ActivateVoucherModal from "./modals/ActivateVoucherModal";
import RedeemVoucherModal from "./modals/RedeemVoucherModal";
import CheckBalanceModal from "./modals/CheckBalanceModal";
import { SummarySection } from "./SummarySection";

import { useGetRecentActivations, useGetRecentRedemptions } from "../../../services/merchant-dashboard/voucher";
import { Outlet } from "react-router-dom";
import { useDocumentTitle } from "suregifts-component-lib";

function VoucherOperationScreen() {
  const { data: activations, isFetching: isLoadingActivation } = useGetRecentActivations();
  const { data: redemptions, isFetching: isLoadingRedemption } = useGetRecentRedemptions();
  useDocumentTitle("Dashboard");
  return (
    <div>
      <SummarySection />
      <Box
        sx={(theme) => ({
          display: "grid",
          marginTop: "40px",
          gap: "3rem",
          gridTemplateColumns: "1fr",
          [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "2fr 1fr",
          },
        })}
      >
        <RecentActivationView data={activations} to="/merchant-dashboard/activations" isloading={isLoadingActivation} />
        <RecentRedemptionView data={redemptions} to="/merchant-dashboard/redemptions" isloading={isLoadingRedemption} />
      </Box>
      <Outlet />
    </div>
  );
}

export default VoucherOperationScreen;
