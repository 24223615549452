import { Box, SxProps } from "@mui/system";
import { LoginForm } from "./login/LoginForm";
import { Outlet } from "react-router-dom";
const styles: SxProps = {
  backgroundImage: "url(/assets/images/auth-main-bg.png)",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
function AuthScreen() {
  return (
    <Box sx={styles}>
      <Box sx={{ width: "26.875rem", border: "1px solid #adadad", maxWidth: "26.875rem", background: "#000000", backdropFilter: " blur(1.25rem)", borderRadius: "2.1875rem" }}>
        <LogoImage />
        <Outlet />
      </Box>
    </Box>
  );
}
const LogoImage = () => {
  return <img src="/assets/images/login-top-bg.png" style={{ width: "100%", borderTopLeftRadius: "32px", borderTopRightRadius: "30px" }} />;
};
export default AuthScreen;
