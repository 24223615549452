import { useState } from "react";
import { Box } from "@mui/material";
import { MarginTop40Box, PageFilter, PageHeaderTitle, SearchContainer, SearchInput, useDocumentTitle, useFilter } from "suregifts-component-lib";
import TableView from "./TableView";

function AbandonCartScreen() {
  useDocumentTitle("Abandoned Carts");
  const [filter, setFilter] = useFilter<PageFilter>({ page: 1, search: "" } as PageFilter);
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  return (
    <div>
      <SearchContainer sx={{ marginTop: "40px" }}>
        <PageHeaderTitle title="Abandoned Carts" />
        <div style={{ flexGrow: 1 }}></div>
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <SearchInput
            value={filter.search}
            onChange={(e) => {
              handleFilter({ search: e });
            }}
          />
        </Box>
      </SearchContainer>
      <MarginTop40Box>
        <TableView filter={filter} handlerFilter={handleFilter} />
      </MarginTop40Box>
    </div>
  );
}

export default AbandonCartScreen;
