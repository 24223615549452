import { Outlet } from "react-router-dom";
import FilterSection from "./FilterSection";
import TableView from "./TableView";
import { useDocumentTitle } from "suregifts-component-lib";

function StoreScreen() {
  useDocumentTitle("Stores");
  return (
    <div>
      <FilterSection />
      <TableView />
      <Outlet />
    </div>
  );
}

export default StoreScreen;
