import React, { useState } from "react";
import TableView from "./TableView";
import { Box } from "@mui/material";
import SummaryView from "./SummaryView";
import FilterSection from "./FilterSection";
import { useDocumentTitle, useFilter } from "suregifts-component-lib";
import { OrderFilter } from "../../../../services/order";

function OrdersScreen() {
  useDocumentTitle("Orders");
  const [filter, setFilter] = useFilter<OrderFilter>({ page: 1, search: "" } as OrderFilter);
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };

  return (
    <div>
      <SummaryView />
      <FilterSection filter={filter} handleFilter={handleFilter} />
      <TableView filter={filter} handlerFilter={handleFilter} />
    </div>
  );
}

export default OrdersScreen;
