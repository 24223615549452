import { Dialog, DialogContent, Stack } from "@mui/material";
import { TitleAndDetailsView, TransactionStatusLabel, ApprovalStatusLabel, DialogTitle, dateTime } from "suregifts-component-lib";
import { PaymentData } from "../../../shared/transaction";

type ApprovePaymentModalProps = {
  handleClose?: () => void;
  payment: PaymentData;
};

function PaymentDetailsModal({ handleClose = () => {}, payment }: ApprovePaymentModalProps) {
  return (
    <div>
      <Dialog maxWidth={"sm"} open={true} sx={{}}>
        <DialogTitle title={"Payment Details"} handleClose={handleClose} />
        <DialogContent>
          <Stack spacing={4}>
            <PaymnetDetailsView payment={payment} />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}

type PaymnetDetailsViewProps = {
  payment: PaymentData;
};

export const PaymnetDetailsView = ({ payment }: PaymnetDetailsViewProps) => {
  return (
    <>
      <TitleAndDetailsView title="Reference" details={payment.invoiceNumber} />
      <TitleAndDetailsView title="Amount" details={payment.amount} />
      <TitleAndDetailsView title="Payment Method" details={payment.paymentMethod} />
      <TitleAndDetailsView title="Transaction Type" details={payment.paymentType} />
      <TitleAndDetailsView title="Sender's Name" details={`${payment.firstName} ${payment.lastName}`} />
      <TitleAndDetailsView title="Sender's Email" details={payment.email} />
      <TitleAndDetailsView title="Payment Status" details={<TransactionStatusLabel status={payment.paymentStatusId} text={payment.paymentStatus} />} color="success.main" />
      <TitleAndDetailsView title="Approval Status" details={<ApprovalStatusLabel status={payment.approvalStatusId} text={payment.approvalStatus} />} color="success.main" />
      {payment.reviewedBy && (
        <>
          <TitleAndDetailsView title="Reviewed By" details={payment.reviewedBy} />
          <TitleAndDetailsView title="Reviewed Time" details={dateTime(payment.reviewedTime)} />
        </>
      )}
      {Object.keys(payment.extraInfo).map((key, index) => {
        return <TitleAndDetailsView key={key} title={key} details={payment.extraInfo[key]} />;
      })}
    </>
  );
};

export default PaymentDetailsModal;
