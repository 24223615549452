import { TableHead, TableRow, TableCell, IconButton, Box } from "@mui/material";
import { useGetPayments } from "../../../services/transaction";
import { ApprovalStatusLabel, ApprovalStatusType, MainTable, OptionNaming, Pagination, SelectPopover, TableBody, dateOnly, dateTime } from "suregifts-component-lib";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { PaymentFilter } from "../../../shared/transaction";
import { Outlet, useNavigate } from "react-router-dom";
type TableViewProps = {
  filter: PaymentFilter;
  handlerFilter: (params: any) => void;
};
const getOptions = (statusId: ApprovalStatusType) => {
  var d: OptionNaming[] = [{ text: "View" }];
  if (statusId === ApprovalStatusType.AwaitingReview || statusId === ApprovalStatusType.Pending) {
    d.push({ text: "Approve", color: "success" });
    d.push({ text: "Reject", color: "error" });
  }
  return d;
};

function TableView({ filter, handlerFilter }: TableViewProps) {
  const { data, isFetching } = useGetPayments(filter);

  const navigate = useNavigate();
  return (
    <div>
      <MainTable
        loading={isFetching}
        sx={{
          marginTop: "40px",
          "& .MuiIconButton-colorError": {
            color: "error.dark",
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Reference</TableCell>
            <TableCell>Sender</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Narration</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Date/Time</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items.map((item) => (
            <TableRow key={item.paymentNumber}>
              <TableCell>{item.paymentNumber}</TableCell>
              <TableCell>
                {item.firstName + " " + item.lastName}
                <br />
                <Box sx={{ fontSize: "13px", color: "text.secondary" }}>{item.email}</Box>
              </TableCell>
              <TableCell>{item.amount}</TableCell>
              <TableCell>{item.narration}</TableCell>
              <TableCell>
                <ApprovalStatusLabel text={item.approvalStatus} status={item.approvalStatusId} />
              </TableCell>
              <TableCell>{dateOnly(item.dateCreated)}</TableCell>
              <TableCell>
                <SelectPopover
                  onChange={(index) => {
                    sessionStorage.setItem("PAYMENT_DATA", JSON.stringify(item));
                    if (index == 0) {
                      navigate(`${item.paymentNumber}/view`);
                    } else if (index == 1) {
                      navigate(`${item.paymentNumber}/approve`);
                    } else {
                      navigate(`${item.paymentNumber}/reject`);
                    }
                  }}
                  options={getOptions(item.approvalStatusId)}
                  render={(handleClick) => (
                    <IconButton onClick={handleClick}>
                      <MoreHorizIcon />
                    </IconButton>
                  )}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination page={filter.page} total={data?.total} onChange={(page) => handlerFilter({ page })} />
      </div>
      <Outlet />
    </div>
  );
}

export default TableView;
