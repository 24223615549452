import { TableHead, TableRow, TableCell, IconButton } from "@mui/material";
import { toast } from "react-toastify";
import { Pagination, MainTable, useNotificationContext, TableBody, BooleanLabel, ThreePeopleIcon, DeleteIcon, SelectPopover, EditPencilIcon } from "suregifts-component-lib";
import { useGetMerchants, useChangeMerchantStatus, useDeleteMerchant, MerchantData, useGetMerchantById, useToggleShowOnWeb } from "../../../services/merchants";
import { useNavigate } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const merchantOptions: any = (item: MerchantData) => {
  if (item.isGeneric) {
    return [{ text: item.isVisibleOnline ? "Hide Online" : "Show Online", value: 2 }];
  }
  return [
    { text: item.isActive ? "Deactivate" : "Activate", color: item.isActive ? "error" : "success", value: 0 },
    { text: "Edit", value: 1 },
    { text: item.isVisibleOnline ? "Hide Online" : "Show Online", value: 2 },
    { text: "Delete", color: "error", value: 3 },
  ];
};
function TableView({ filter, handleFilter }) {
  const navigate = useNavigate();
  const { data: merchants, isFetching, refetch } = useGetMerchants(filter);
  const { mutate: changeStatus, isLoading: changingStatus } = useChangeMerchantStatus();
  const { mutate: deleteRecord, isLoading: deleting } = useDeleteMerchant();
  const { mutate: getMerchantById, isLoading: findingMerchant } = useGetMerchantById();
  const { mutate: showOnWeb } = useToggleShowOnWeb();
  const { confirm } = useNotificationContext();
  const handleChangeStatus = (merchantId: any) => {
    changeStatus(merchantId, {
      onSuccess: () => {
        toast.success("Status changed successufully.");
        refetch();
      },
    });
  };
  const handleEdit = (merchant: MerchantData) => {
    getMerchantById(merchant.id, {
      onSuccess: (data) => {
        sessionStorage.setItem("MERCHANT_INFO", JSON.stringify(data));
        navigate(`${merchant.id}/edit`);
      },
    });
  };
  const handleDelete = (merchantId: any) => {
    confirm({
      onConfirm: () => {
        deleteRecord(merchantId, {
          onSuccess: () => {
            toast.success("Merchant deleted successufully.");
            refetch();
          },
        });
      },
    });
  };

  return (
    <div>
      <MainTable loading={isFetching || findingMerchant} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Minimum Amount</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Visible Online</TableCell>
            <TableCell>Visibility</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {merchants?.items.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.email}</TableCell>
              <TableCell>{item.phone}</TableCell>
              <TableCell>{item.minAmount}</TableCell>
              <TableCell>
                <BooleanLabel type="active-in" value={item.isActive} />
              </TableCell>
              <TableCell>
                <BooleanLabel value={item.isVisibleOnline} type="yesno" />
              </TableCell>
              <TableCell>
                <IconButton onClick={() => navigate(`${item.id}/personnels`)}>
                  <ThreePeopleIcon />
                </IconButton>
                <IconButton onClick={() => handleEdit(item)}>
                  <EditPencilIcon />
                </IconButton>

                <SelectPopover
                  onChange={(index, value) => {
                    if (index == 0) {
                      handleChangeStatus(item.id);
                    } else if (index == 2) {
                      showOnWeb(item.id, {
                        onSuccess: () => {
                          toast.success("Merchant updated successfully.");
                          refetch();
                        },
                      });
                    } else if (index == 3) {
                      handleDelete(item.id);
                    }
                  }}
                  options={merchantOptions(item)}
                  render={(handleClick) => (
                    <IconButton onClick={handleClick}>
                      <MoreHorizIcon />
                    </IconButton>
                  )}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination
          page={filter.page}
          total={merchants?.total}
          onChange={(page) => {
            handleFilter({ page });
          }}
        />
      </div>
    </div>
  );
}

export default TableView;
