import { RouteObject } from "react-router";
import DashboardScreen from "../pages/super-admin/dashboard/DashboardScreen";
import BillCategoriesScreen from "../pages/super-admin/utilities/bill-categories/BillCategoriesScreen";
import BillersScreen from "../pages/super-admin/utilities/billers/BillersScreen";
import PackagesScreen from "../pages/super-admin/utilities/packages/PackagesScreen";

import { adminRoutes, couponRoutes } from "suregifts-component-lib";
import BillsPaymentScreen from "../pages/super-admin/billsPayment/BillsPaymentScreen";

export const superAdminRoutes: RouteObject[] = [
  { path: "dashboard", element: <DashboardScreen /> },
  { element: <DashboardScreen />, index: true },
  ,
  adminRoutes() as any,
  couponRoutes() as any,
  { path: "bill-categories", element: <BillCategoriesScreen /> },
  { path: ":categoryId/billers", element: <BillersScreen /> },
  { path: ":billerId/packages", element: <PackagesScreen /> },
  { path: "bills-payments", element: <BillsPaymentScreen /> },
];
