import * as yup from "yup";
import { CreateEditStoreModel } from "../../../../shared/merchants";
import { YupShape } from "suregifts-component-lib";
export const storeSchema = yup
  .object<YupShape<CreateEditStoreModel>>({
    name: yup.string().required(),
    address: yup.string().required(),
    contactPhone: yup.string().required(),
    email: yup.string().required(),
  })
  .required();
