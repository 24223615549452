import { DeliveryStatusType, OrderItemStatusLabel, FilterDropdown, OptionNaming } from "suregifts-component-lib";
import { useOrderScreenContext } from "./context/ScreenContext";

const options = [
  { text: "Processing", value: DeliveryStatusType.Generated, color: "warning" },
  { text: "Delivered", value: DeliveryStatusType.Delivered, color: "success" },
];
type GiftCardStatusViewProps = {
  statusId: DeliveryStatusType;
  display: any;
  onChange?: (statusId: DeliveryStatusType) => void;
};
export const GiftCardStatusView = ({ statusId, display, onChange = () => {} }: GiftCardStatusViewProps) => {
  const { state } = useOrderScreenContext();
  if (state.recipient?.statusId !== DeliveryStatusType.Generated) {
    return <OrderItemStatusLabel label={display} status={state.recipient?.statusId} />;
  }
  return (
    <>
      <FilterDropdown
        display={display}
        onChange={(index) => {
          onChange(options[index].value);
        }}
        options={options as OptionNaming[]}
      />
    </>
  );
};
