import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { LoadingBox } from "suregifts-component-lib";
import { useGetMerchantWalletTransactions, useGetMerchantWalletById, useCreditMerchantWallet, useDebitMerchantWallet } from "../../../../services/merchant-wallet";

import { TransactionHistoryView } from "../../../../components/organisms/merchant-wallets/TransactionHistoryView";
import { BalanceCard } from "../../../../components/organisms/merchant-wallets/BalanceCard";
import { CompanyCard } from "../../../../components/organisms/merchant-wallets/CompanyCard";
import { WalletTransactionFilter } from "../../../../components/organisms/merchant-wallets/data";

function MerchantWalletHistoryScreen() {
  const { merchantId } = useParams();
  const [filter, setFilter] = useState({ page: 1, search: "" } as WalletTransactionFilter);
  const { data: history, isLoading } = useGetMerchantWalletTransactions(merchantId, filter);
  const { data: merchant } = useGetMerchantWalletById(merchantId);
  const { mutate: credit } = useCreditMerchantWallet(merchantId);
  const { mutate: debit } = useDebitMerchantWallet(merchantId);

  const handleFilter = (params) => setFilter((prev) => ({ ...prev, ...params }));
  if (!merchant) return <LoadingBox loading loadingPosition="center" />;
  return (
    <div>
      <LoadingBox
        sx={(theme) => ({
          display: "grid",
          gap: "1.875rem",
          [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "3fr 5fr",
          },
        })}
      >
        <CompanyCard store={merchant} />
        <BalanceCard store={merchant} credit={credit} debit={debit} />
      </LoadingBox>
      <TransactionHistoryView pageData={merchant} filter={filter} handleFilter={handleFilter} data={history} />
    </div>
  );
}

export default MerchantWalletHistoryScreen;
