import { Box } from "@mui/material";
import React, { useState } from "react";
import { MarginTop40Box, SummaryCard, useDocumentTitle, useFilter } from "suregifts-component-lib";
import FilterSection from "./FilterSection";
import { UserFilter } from "../../../../services/user-security";
import TableView from "./TableView";
import { useGetUsersSummary } from "../../../../services/customer-support/general";
import { SummaryCardsContainer } from "../../../../components/organisms/SummaryWith4CardsBox";

function UsersScreen() {
  useDocumentTitle("Customers");
  const [filter, setFilter] = useFilter<UserFilter>({ page: 1, search: "" });
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  const { data: summary } = useGetUsersSummary();
  return (
    <div>
      <SummaryCardsContainer sx={{ marginTop: "40px" }}>
        <SummaryCard title={summary?.totalUsers} info="total users" />
        <SummaryCard title={summary?.activeUsers} info="Active users" />
        <SummaryCard title={summary?.inactiveUsers} info="in-active users" />
        <SummaryCard title={summary?.totalSubscribers} info="total subscribers" />
      </SummaryCardsContainer>
      <FilterSection filter={filter} handleFilter={handleFilter} />
      <TableView filter={filter} handleFilter={handleFilter} />
    </div>
  );
}

export default UsersScreen;
