import { Box } from "@mui/material";
import { SearchContainer, FilterDropdown, SearchInput, SearchButton, PageHeaderTitle } from "suregifts-component-lib";

import { useNavigate } from "react-router-dom";
import { BulkVoucherStatusType } from "../../../services/vouchers";
const typeOptions: any[] = [{ text: "All" }, { text: "Sales", value: "1" }, { text: "In-Store", value: "3" }];
const filterOptions: any[] = [
  { text: "All" },
  { text: "Processing", value: BulkVoucherStatusType.Processing.toString() },
  { text: "Unactivated", value: BulkVoucherStatusType.UnActivated.toString() },
  { text: "Activated", value: BulkVoucherStatusType.Activated.toString() },
  { text: "Failed", value: BulkVoucherStatusType.Failed.toString() },
  { text: "Caceled", value: BulkVoucherStatusType.Canceled.toString() },
];
export const FilterSection = ({ filter, handleFilter }) => {
  const navigate = useNavigate();

  return (
    <SearchContainer sx={{ marginTop: "40px" }}>
      <PageHeaderTitle title="Bulk Vouchers" />
      <Box
        sx={{
          display: "flex",
          gap: "1.25rem",
          alignItems: "center",
        }}
      >
        <FilterDropdown display="Type" value={filter.type} options={typeOptions} onChange={(index, value) => handleFilter({ type: value, page: 1 })} />
        <FilterDropdown display="Status" value={filter.status} options={filterOptions} onChange={(index, value) => handleFilter({ status: value, page: 1 })} />
        <SearchInput value={filter.search} onChange={(e) => handleFilter({ search: e, page: 1 })} />
        <SearchButton sx={{ py: "0.125rem" }} onClick={() => navigate("generate")} label="Generate Vouchers" />
      </Box>
    </SearchContainer>
  );
};
