import { Box } from "@mui/material";
import { SummaryCardWithIcon, MerchantPotIcon, CheckListIcon, PeopleIcon, MarginTop40Box } from "suregifts-component-lib";
import { useGetSummaryData } from "../../../services/super-admin/general";
import { SummaryCardsContainer } from "../../../components/organisms/SummaryWith4CardsBox";

export const SummaryView = () => {
  const { data, isFetching } = useGetSummaryData();
  return (
    <SummaryCardsContainer sx={{ marginTop: "40px" }} isLoading={isFetching}>
      <SummaryCardWithIcon title={data?.pendingOrders} info="pending orders" icon={MerchantPotIcon} iconColor="success.main" />
      <SummaryCardWithIcon title={data?.totalOrders} info="total orders" icon={CheckListIcon} iconColor="error.main" />
      <SummaryCardWithIcon title={data?.allMerchants} info="total merchants" icon={PeopleIcon} iconColor="warning.main" />
      <SummaryCardWithIcon title={data?.totalPayments} info="total payments" icon={CheckListIcon} iconColor="info.main" />
    </SummaryCardsContainer>
  );
};
