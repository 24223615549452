import { Box } from "@mui/material";
import { FilterDropdown, PageHeaderTitle, SearchButton, SearchContainer, SearchInput } from "suregifts-component-lib";
import { UserFilter, useExportUsers } from "../../../../services/user-security";
const statuses = [
  {
    text: "All",
  },
  {
    text: "Active",
    value: 1,
  },
  { text: "Blocked", value: 2 },
  { text: "Blacklisted", value: 3 },
];
type FilterSectionProps = {
  filter: UserFilter;
  handleFilter: (params) => void;
};
function FilterSection({ handleFilter, filter }: FilterSectionProps) {
  const { mutate: exportUsers, isLoading } = useExportUsers(filter);
  function handleExport(): void {
    exportUsers();
  }

  return (
    <SearchContainer sx={{ marginTop: "40px" }}>
      <PageHeaderTitle title={"Users"} />
      <Box
        sx={{
          display: "flex",
          gap: "1.25rem",
          alignItems: "center",
        }}
      >
        <FilterDropdown
          display="Status"
          value={filter.status}
          options={statuses}
          onChange={(index, value) => {
            handleFilter({ status: value, page: 1 });
          }}
        />
        <SearchInput
          sx={{ width: "25rem" }}
          value={filter.search}
          onChange={(e) => {
            handleFilter({ search: e, page: 1 });
          }}
        />
        <SearchButton sx={{ py: "0.125rem" }} label="Export" onClick={handleExport} />
      </Box>
    </SearchContainer>
  );
}

export default FilterSection;
