import { YupShape } from "suregifts-component-lib";
import { CreateEditMerchantModel } from "../../../../services/merchants";
import * as yup from "yup";
export const merchantSchema = yup
  .object<YupShape<CreateEditMerchantModel>>({
    email: yup.string().email().required(),
    paymentType: yup.string().required(),
    name: yup.string().required(),
    address: yup.string().required(),
    description: yup.string().required(),
    phone: yup.string().required(),
    paymentMargin: yup.number().required(),
    minAmount: yup.number().required(),
    photos: yup.array().min(1),
  })
  .required();
