import { TableHead, TableRow, TableCell, IconButton } from "@mui/material";
import { RefundStatusLabel } from "./RefundStatusLabel";
import { RefundFilter, useGetRefunds } from "../../../services/refunds";
import { MainTable, RefundStatusType, Pagination, TableBody, useDocumentTitle, dateOnly, dateTime, OptionNaming, SelectPopover } from "suregifts-component-lib";
import { useNavigate } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
type TableViewProps = {
  filter: RefundFilter;
  handlerFilter: (params: any) => void;
};
const getOptions = (statusId: RefundStatusType) => {
  var d: OptionNaming[] = [{ text: "View" }];
  if (statusId === RefundStatusType.Pending) {
    d.push({ text: "Approve", color: "success" });
    d.push({ text: "Decline", color: "error" });
  }
  return d;
};
function TableView({ filter, handlerFilter }: TableViewProps) {
  useDocumentTitle("Refunds");
  const { data, isFetching, refetch } = useGetRefunds(filter);
  const navigate = useNavigate();
  return (
    <div>
      <MainTable loading={isFetching} sx={{ marginTop: "2.5rem" }}>
        <TableHead>
          <TableRow>
            <TableCell>Reference</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Refund Method</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Date Created</TableCell>
            <TableCell>Approved Date/Time</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items.map((item) => (
            <TableRow key={item.refundNumber}>
              <TableCell>{item.refundNumber}</TableCell>
              <TableCell>{item.amount}</TableCell>
              <TableCell>{item.walletType}</TableCell>
              <TableCell>
                <RefundStatusLabel text={item.status} status={item.statusId} />
              </TableCell>
              <TableCell>{dateOnly(item.dateCreated)}</TableCell>
              <TableCell>{dateTime(item.reviewedTime)}</TableCell>
              <TableCell>
                <SelectPopover
                  onChange={(index) => {
                    sessionStorage.setItem("REFUND_DATA", JSON.stringify(item));
                    if (index == 0) {
                      navigate(`${item.refundNumber}/view`);
                    } else if (index == 1) {
                      navigate(`${item.refundNumber}/approve`);
                    } else {
                      navigate(`${item.refundNumber}/decline`);
                    }
                  }}
                  options={getOptions(item.statusId)}
                  render={(handleClick) => (
                    <IconButton onClick={handleClick}>
                      <MoreHorizIcon />
                    </IconButton>
                  )}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination page={filter.page} total={data?.total} onChange={(page) => handlerFilter({ page })} />
      </div>
    </div>
  );
}

export default TableView;
