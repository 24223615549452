import { useNavigate, useParams } from "react-router-dom";
import { useGetPackages } from "../../../../services/utility";
import ListView from "./ListView";
import { MarginTop40Box, PageTitleWithBackLink, useDocumentTitle } from "suregifts-component-lib";
import { BackLinkView } from "../../../../components/molecules/BackLinkView";

function PackagesScreen() {
  const { billerId } = useParams();
  const { data, refetch } = useGetPackages(billerId!);
  useDocumentTitle("Packages");
  const navigate = useNavigate();
  return (
    <div>
      <BackLinkView
        label={`${data?.billerName}`}
        onClick={() => {
          navigate("/super-admin/bill-categories");
        }}
      />
      <ListView data={data?.packages} reload={refetch} />
    </div>
  );
}

export default PackagesScreen;
