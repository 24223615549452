import { Box } from "@mui/material";
import { Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { UserInfoView } from "./UserInfoView";
import { useGetUserById } from "../../../../services/user-security";

import { LoadingBox, TabController, useDocumentTitle } from "suregifts-component-lib";
import UserTransactionsScreen from "./UserTransactionsScreen";
import UserOrdersScreen from "./UserOrdersScreen";
import { BackLinkView } from "../../../../components/molecules/BackLinkView";
import DebitUserModal from "./modals/DebitUserModal";

function UserInfoScreen() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { data, isFetching, refetch } = useGetUserById(userId);
  const [searchParams, setSearchParam] = useSearchParams();
  useDocumentTitle("Customers");
  if (!data) {
    return <LoadingBox loading />;
  }
  return (
    <div>
      <BackLinkView
        label={`${data?.firstName} ${data?.lastName}`}
        onClick={() => {
          navigate("/customer-support/users");
        }}
      />
      <Box
        sx={(theme) => ({
          marginTop: "2.5rem",
          display: "grid",
          gap: "2.375rem",
          gridTemplateColumns: "1fr",
          [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "2fr minmax(400px,1fr)",
          },
        })}
      >
        <div>
          <TabController
            onChange={(value) => {
              setSearchParam({ tab: value });
            }}
            tabs={[
              { label: "Orders", value: `orders` },
              { label: "Wallet History", value: `transactions` },
            ]}
            value={searchParams.get("tab") || "orders"}
          />
          {searchParams.get("tab") === "transactions" ? <UserTransactionsScreen /> : <UserOrdersScreen />}
        </div>
        <div>
          <UserInfoView data={data} />
        </div>
      </Box>
      <Outlet />
    </div>
  );
}

export default UserInfoScreen;
