import { useState } from "react";
import { MerchantFilter } from "../../../services/merchants";
import FilterSection from "./FilterSection";
import TableView from "./TableView";
import { Outlet } from "react-router-dom";
import { useDocumentTitle, useFilter } from "suregifts-component-lib";

function MerchantsScreen() {
  const [filter, setFilter] = useFilter<MerchantFilter>({ page: 1, search: "" });
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  useDocumentTitle("Merchants");
  return (
    <div>
      <FilterSection filter={filter} handleFilter={handleFilter} />
      <TableView filter={filter} handleFilter={handleFilter} />
      <Outlet />
    </div>
  );
}

export default MerchantsScreen;
