import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PageHeaderTitle, SearchButton, SearchContainer } from "suregifts-component-lib";
function FilterSection({}) {
  const navigate = useNavigate();
  return (
    <div>
      <SearchContainer sx={{ marginTop: "40px" }}>
        <PageHeaderTitle title={"Stores"} />
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <SearchButton sx={{ py: "0.125rem" }} label="Add Store" onClick={() => navigate("add")} />
        </Box>
      </SearchContainer>
    </div>
  );
}

export default FilterSection;
