import * as yup from "yup";
import { ApprovalStatusType, PageFilter, TransactionStatusType, YupShape } from "suregifts-component-lib";
import { CreditDebitModel } from "../services/transaction";
export const debitCreditSchema = yup
  .object<YupShape<CreditDebitModel>>({
    amount: yup.string().required(),
    reason: yup.string().required(),
  })
  .required();
export interface PaymentData {
  paymentNumber: string;
  invoiceNumber: string;
  amount: string;
  paymetMethodId: number;
  paymentMethod: string;
  paymentTypeId: number;
  paymentType: string;
  approvalStatusId: ApprovalStatusType;
  approvalStatus: string;
  paymentStatusId: TransactionStatusType;
  paymentStatus: string;
  email: string;
  firstName: string;
  lastName: string;
  dateCreated: string;
  reviewedTime: string;
  reviewedBy: string;
  rejectionReason: string;
  extraInfo: any;
  narration: string;
}

export interface PaymentFilter extends PageFilter {
  fromDate?: Date;
  toDate?: Date;
  method: any;
  statusId: any;
}
