import { useQuery } from "react-query";
import { useAxios, ServerError } from "suregifts-component-lib";

export const useGetVoucherSummary = () => {
  const axios = useAxios();
  return useQuery<SummaryData, ServerError>({
    queryKey: ["users-summary"],
    keepPreviousData: true,
    queryFn: () => axios.get("/general/api/merchant-dashboard/summary", {}),
  });
};
export interface SummaryData {
  activations: string;
  redemptions: string;
  stores: string;
}
