import { Dialog, DialogActions, DialogContent, Stack } from "@mui/material";
import React from "react";
import { DialogTitle, FormButton, FormInput, YupShape } from "suregifts-component-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { TransferVoucherModel, useTransferVoucher } from "../../../../services/vouchers";
const schema = yup
  .object<YupShape<TransferVoucherModel>>({
    email: yup.string().required().email(),
    name: yup.string().required(),
    voucherCode: yup.string().required(),
  })
  .required();
function TransferVoucherModal() {
  const { voucherCode } = useParams();
  const { mutate: transferVoucher, isLoading } = useTransferVoucher();
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("..");
  };
  const {
    formState: { isValid },
    handleSubmit,
    register,
  } = useForm<TransferVoucherModel>({
    defaultValues: {
      voucherCode: voucherCode,
    },
    resolver: yupResolver(schema),
  });
  const onSubmit = (data: TransferVoucherModel) => {
    transferVoucher(data, {
      onSuccess: () => {
        toast.success(`${data.voucherCode} was assigned to ${data.email} successfully.`);
        handleClose();
      },
    });
  };
  return (
    <Dialog open maxWidth="xs" onClose={handleClose}>
      <DialogTitle title="Transfer Voucher" handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={2}>
          <FormInput label="Voucher Code" disabled value={voucherCode} />
          <FormInput {...register("email")} label="Recipient Email" />
          <FormInput {...register("name")} label="Recipient Name" />
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton loading={isLoading} disabled={!isValid} title="Send" fullWidth onClick={handleSubmit(onSubmit)} />
      </DialogActions>
    </Dialog>
  );
}

export default TransferVoucherModal;
