import { useQuery } from "react-query";
import { useAxios, ServerError } from "suregifts-component-lib";

export const useGetDashboardData = () => {
  const axios = useAxios();
  return useQuery<DashboardData, ServerError>({
    queryKey: ["dashboard"],
    keepPreviousData: true,
    queryFn: () => axios.get("/general/api/account/dashboard", {}),
  });
};
export interface DashboardData {
  orderPayments: string;
  refunds: string;
  walletTopups: string;
  merchantRedemptions: string;
}
