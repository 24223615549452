import { Dialog, DialogActions, DialogContent } from "@mui/material";

import { Stack } from "@mui/system";
import { DialogTitle, FormButton, FormInput } from "suregifts-component-lib";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { debitCreditSchema } from "../../../../../shared/transaction";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { CreditDebitModel, useDebitWallet } from "../../../../../services/transaction";

function DebitUserModal({ handleClose }) {
  const { userId } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreditDebitModel>({
    defaultValues: {},
    resolver: yupResolver(debitCreditSchema),
  });
  const { mutate: debit, isLoading } = useDebitWallet(userId);
  const submitForm = (data) => {
    debit(data, {
      onSuccess: () => {
        toast.success("Wallet Debitted successfully.");
        handleClose();
      },
    });
  };

  return (
    <Dialog open maxWidth={"xs"}>
      <DialogTitle title="Debit Wallet" handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={3}>
          <FormInput label="Debit Amout" helperText={errors.amount?.message} {...register("amount")} />
          <FormInput label="Reason" size="medium" multiline helperText={errors.reason?.message} {...register("reason")} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton fullWidth label="Debit Wallet" loading={isLoading} onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}

export default DebitUserModal;
