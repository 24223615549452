import { useMutation, useQuery } from "react-query";
import { Count, MerchantApprovalType, NameAndId, ServerError, useAxios } from "suregifts-component-lib";
import { StoreFilter, CreateEditStoreModel, PersonnelData, AddPersonnelModel, StoreData } from "../../shared/merchants";

export const useGetMerchantById = () => {
  const axios = useAxios();
  return useQuery<MerchantDto, ServerError>({
    queryKey: ["merchantById"],
    keepPreviousData: true,

    queryFn: () => axios.get("/merchants/api/merchant-dashboard", {}),
  });
};

export const useGetStoreNames = () => {
  const axios = useAxios();
  return useQuery<NameAndId[], ServerError>({
    queryKey: ["store-names1"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get("/merchants/api/merchant-dashboard/stores/names", {}),
  });
};

export const useGetStates = () => {
  const axios = useAxios();
  return useQuery<NameAndId[], ServerError>({
    queryKey: ["states"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get("/merchants/api/merchant-dashboard/states", {}),
  });
};
export const useGetRoles = () => {
  const axios = useAxios();
  return useQuery<NameAndId[], ServerError>({
    queryKey: ["roles"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get("/merchants/api/merchant-dashboard/roles", {}),
  });
};

//Store section
export const useGetStores = (filter: StoreFilter) => {
  const axios = useAxios();
  return useQuery<StoreData[], ServerError>({
    queryKey: ["stores", filter],
    keepPreviousData: true,
    cacheTime: 0,
    initialData: [],
    queryFn: () => axios.get("/merchants/api/merchant-dashboard/stores", { params: filter }),
  });
};
export const useCreateStore = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, CreateEditStoreModel>((model) => {
    return axios.post(`/merchants/api/merchant-dashboard/stores`, model);
  });
};
export const useEditStore = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, CreateEditStoreModel>((model) => {
    return axios.post(`/merchants/api/merchant-dashboard/stores/${model.id}`, model);
  });
};
export const useDeleteStore = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, StoreData>((model) => {
    return axios.delete(`/merchants/api/merchant-dashboard/stores/${model.id}`, {});
  });
};

//Store personel section
export const useGetPersonnels = () => {
  const axios = useAxios();
  return useQuery<PersonnelData[], ServerError>({
    queryKey: ["personnels"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () =>
      axios.get(`/merchants/api/merchant-dashboard/personnels`, {
        params: {},
      }),
  });
};
export const useCreatePersonnel = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, AddPersonnelModel>((model) => {
    return axios.post(`/merchants/api/merchant-dashboard/personnels`, model);
  });
};

export const useDeletePersonnel = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, PersonnelData>((data) => {
    return axios.delete(`/merchants/api/merchant-dashboard/personnels/${data.id}`, {});
  });
};
export interface MerchantDto {
  swapRate: number;
  paymentTypeId: MerchantPaymentType;
  dateCreated: string;
  address: string;
  isGeneric: boolean;
  id: number;
  name: string;
  email: string;
  phone: string;
  hasWallet: boolean;
}
export enum MerchantPaymentType {
  Regular = 1,
  PrepaidStore = 2,
  PrepaidVendor = 3,
}
