import { Box } from "@mui/material";
import moment from "moment";
import { VoucherTransactionData } from "../../shared/voucher";
import { LoadingBox, PageTitleWithLink, dateTime } from "suregifts-component-lib";
import RecentRedemptionsCard from "../molecules/RecentRedemptionsCard/RecentRedemptionsCard";
type RecentRedemptionViewProps = {
  data?: VoucherTransactionData[];
  isloading?: boolean;
  to?: string;
};
const RecentRedemptionView = ({ data, isloading, to }: RecentRedemptionViewProps) => {
  return (
    <LoadingBox loading={isloading}>
      <PageTitleWithLink title={"Recent Redemptions"} to={to} />
      <Box sx={{ display: "grid", gap: "2.5rem", marginTop: "1.5625rem" }}>
        {data?.map((item) => (
          <RecentRedemptionsCard key={item.id} amount={item.amount} merchant={item.giftCardType} reference={item.code} pointOfUsage={item.pointOfUsage} dateTime={dateTime(item.dateTime)} />
        ))}
      </Box>
    </LoadingBox>
  );
};
export default RecentRedemptionView;
