import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { Stack } from "@mui/system";
import { Controller, useForm } from "react-hook-form";
import { DialogTitle, FormButton, FormInput, FormSelect } from "suregifts-component-lib";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";

import { ActivateVoucherModel, activationSchema } from "../../../../shared/voucher";
import { useGetStoreNames } from "../../../../services/merchant-dashboard/merchant";
import { useActivateVoucher } from "../../../../services/merchant-dashboard/voucher";
import { useNavigate } from "react-router-dom";

function ActivateVoucherModal() {
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<ActivateVoucherModel>({
    defaultValues: {
      storeId: "",
      serialNumber: "",
      value: "",
    },
    resolver: yupResolver(activationSchema),
  });
  const { data: stores } = useGetStoreNames();

  const { mutate: activate, isLoading } = useActivateVoucher();
  const submitForm = (data) => {
    activate(data, {
      onSuccess: () => {
        toast.success("Voucher activated successfully.");
        handleClose();
      },
    });
  };
  const navigate = useNavigate();
  const handleClose = () => {
    if (isLoading) return;
    navigate("..");
  };

  return (
    <Dialog open maxWidth="xs" onClose={handleClose}>
      <DialogTitle title="Activate Voucher" handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={2}>
          <Controller control={control} name="storeId" render={({ field }) => <FormSelect required helperText={errors.merchantId?.message} {...field} label={"Store"} options={stores} />} />
          <FormInput isNumber value={watch("serialNumber")} required label="Serial Number" {...register("serialNumber")} helperText={errors.serialNumber?.message} />
          <FormInput isNumber required value={watch("value")} label="Amount" {...register("value")} helperText={errors.value?.message} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton disabled={!isValid} label="Activate" onClick={handleSubmit(submitForm)} fullWidth loading={isLoading} />
      </DialogActions>
    </Dialog>
  );
}

export default ActivateVoucherModal;
