import { DialogContent, DialogActions, Dialog, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormInput, FormButton, DialogTitle, FormSelect, YupShape } from "suregifts-component-lib";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CheckBalanceModel } from "../../services/vouchers";

type CheckBalanceFormProps = {
  checkBalance: (model: CheckBalanceModel) => void;
  isLoading;
};
export const schema = yup
  .object<YupShape<CheckBalanceModel>>({
    voucherCode: yup.string().when("typeId", {
      is: "1",
      then: (schema) => schema.required(),
    }),
    serialNumber: yup.string().when("typeId", {
      is: "2",
      then: (schema) => schema.required(),
    }),
  })
  .required();
export const CheckBalanceForm = ({ checkBalance, isLoading }) => {
  const navigate = useNavigate();
  const handleClose = () => {
    if (isLoading) return;
    navigate("..");
  };

  var {
    register,
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm<CheckBalanceModel>({
    defaultValues: { voucherCode: "", serialNumber: "", typeId: "1" },
    resolver: yupResolver(schema),
  });
  const model = watch();
  useEffect(() => {
    setValue("serialNumber", "");
    setValue("voucherCode", "");
  }, [model.typeId]);

  return (
    <>
      <Dialog open maxWidth="xs" onClose={handleClose}>
        <DialogTitle title={"Check Voucher"} handleClose={handleClose} />
        <DialogContent>
          <Stack spacing={2}>
            <Controller
              control={control}
              name="typeId"
              render={({ field }) => (
                <FormSelect
                  label="Method"
                  fullWidth
                  options={[
                    { id: "1", name: "Voucher Code" },
                    { id: "2", name: "Serial Number" },
                  ]}
                  {...field}
                />
              )}
            />
            {model.typeId === "1" ? (
              <FormInput isNumber value={model.voucherCode} required label="Voucher Code" {...register("voucherCode")} />
            ) : (
              <FormInput isNumber value={model.serialNumber} required label="Serial Number" {...register("serialNumber")} />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <FormButton disabled={!isValid} loading={isLoading} onClick={handleSubmit(checkBalance)} fullWidth label="Check Balance" color="secondary" />
        </DialogActions>
      </Dialog>
    </>
  );
};
