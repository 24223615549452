import { SummaryCardsContainer } from "../../../../components/organisms/SummaryWith4CardsBox";
import { useGetOrdersSummary } from "../../../../services/customer-support/general";
import { MarginTop40Box, SummaryCardWithIcon, MerchantPotIcon, CheckListIcon, PeopleIcon } from "suregifts-component-lib";

function SummaryView() {
  const { data } = useGetOrdersSummary();
  return (
    <MarginTop40Box>
      <SummaryCardsContainer>
        <SummaryCardWithIcon title={data?.pendingOrders} info="Pending orders" icon={MerchantPotIcon} />
        <SummaryCardWithIcon title={data?.deliveredOrders} info="Delivered orders" icon={CheckListIcon} iconColor="success.main" />
        <SummaryCardWithIcon title={data?.canceledOrders} info="Cancelled orders" icon={PeopleIcon} iconColor="#a082f4" />
        <SummaryCardWithIcon title={data?.abandonedCarts} info="Abandoned Cart" icon={CheckListIcon} iconColor="#f47c6a" />
      </SummaryCardsContainer>
    </MarginTop40Box>
  );
}

export default SummaryView;
