import { Box, IconButton, TableCell, TableHead, TableRow } from "@mui/material";
import { MainTable, PageHeaderTitle, SearchButton, SearchContainer, TableBody, formatMoney, useDocumentTitle } from "suregifts-component-lib";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { useGetMerchantWallets } from "../../../../services/merchant-wallet";
import { useNavigate } from "react-router-dom";

function MerchantsScreen() {
  useDocumentTitle("Prepaid Merchant Wallets");
  const { data, isLoading } = useGetMerchantWallets();
  const navigate = useNavigate();
  return (
    <div>
      <SearchContainer sx={{ marginTop: "40px" }}>
        <PageHeaderTitle title="Prepaid Merchant Wallets" />
        <div style={{ flexGrow: 1 }}></div>
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <SearchButton label="Export" />
        </Box>
      </SearchContainer>
      <MainTable sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>

            <TableCell>Balance</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item) => (
            <TableRow>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.email}</TableCell>

              <TableCell sx={{ color: item.balance <= 0 ? "error.main" : "inherit" }}>{formatMoney(item.balance)}</TableCell>
              <TableCell>
                <IconButton onClick={() => navigate(`${item.merchantId}/transactions`)}>
                  <FormatListBulletedIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
    </div>
  );
}

export default MerchantsScreen;
