import { Box } from "@mui/material";
import { SummaryCardWithIcon, MerchantPotIcon, CheckListIcon, PeopleIcon, SummaryCard, LoadingBox } from "suregifts-component-lib";
import { useGetDashboardData } from "../../../services/account/general";

export const SummaryView = () => {
  const { data, isFetching } = useGetDashboardData();
  return (
    <LoadingBox
      loading={isFetching}
      sx={(theme) => ({
        display: "grid",
        marginTop: "40px",
        gridTemplateColumns: "1fr",
        gap: "1.25rem",
        [theme.breakpoints.up("sm")]: {
          gridTemplateColumns: "repeat(2,1fr)",
        },
        [theme.breakpoints.up("lg")]: {
          gridTemplateColumns: "repeat(4,1fr)",
        },
      })}
    >
      <SummaryCard info="order payment" title={data?.orderPayments} />
      <SummaryCard info="refunds" title={data?.refunds} />
      <SummaryCard info="wallet top-up" title={data?.walletTopups} />
      <SummaryCard info="merchant demeption" title={data?.merchantRedemptions} />
    </LoadingBox>
  );
};
