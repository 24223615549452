import { useQuery } from "react-query";
import { useAxios, ServerError, Count } from "suregifts-component-lib";
import { WalletByIdData, WalletTransactionFilter, WalletTransactionDto, StoreData } from "../../components/organisms/merchant-wallets/data";

export const useGetMerchantWalletById = () => {
    const axios = useAxios();
    const path = `/merchants/api/merchant-dashboard/merchant-wallet`;
    return useQuery<WalletByIdData, ServerError>({
      queryKey: ["merchant-wallet-by-id", {  }],
      queryFn: () => axios.get(path),
    });
  };

  export const useGetMerchantWalletTransactions = (params: WalletTransactionFilter) => {
    const axios = useAxios();
    const path = `/merchants/api/merchant-dashboard/merchant-wallet/transactions`;
    return useQuery<Count<WalletTransactionDto>, ServerError>({
      queryKey: ["merchant-wallet-history", params],
      keepPreviousData: true,
      queryFn: () => axios.get(path, { params }),
    });
  };


export const useGetStoreWallets = () => {
    const axios = useAxios();
    const path = "/merchants/api/merchant-dashboard/store-wallets";
    return useQuery<StoreData[], ServerError>({
      queryKey: ["store-wallets"],
      keepPreviousData: true,
      initialData: [],
      queryFn: () => axios.get(path),
    });
  };
  export const useGetStoreWalletById = (storeId) => {
    const axios = useAxios();
    const path = `/merchants/api/merchant-dashboard/store-wallets/${storeId}`;
    return useQuery<WalletByIdData, ServerError>({
      queryKey: ["store-wallet-by-id", { storeId }],
      queryFn: () => axios.get(path),
    });
  };

  export const useGetStoreWalletTransactions = (storeId: any, params: WalletTransactionFilter) => {
    const axios = useAxios();
    const path = `/merchants/api/merchant-dashboard/store-wallets/${storeId}/transactions`;
    return useQuery<Count<WalletTransactionDto>, ServerError>({
      queryKey: ["store-wallet-history", params],
      keepPreviousData: true,
      queryFn: () => axios.get(path, { params }),
    });
  };