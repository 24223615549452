import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { TableRow, TableCell } from "@mui/material";
import moment from "moment";
import { LoadingBox, OrderStatusLabel, Pagination, SelectionTable, SelectionTableBody, SelectionTableHead, useFilter } from "suregifts-component-lib";
import { OrderFilter, useGetOrders } from "../../../../services/order";

function UserOrdersScreen() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [filter, setFilter] = useFilter<OrderFilter>({ page: 1 } as OrderFilter);
  const { data, isFetching } = useGetOrders({ ...filter, userId });

  return (
    <div style={{ marginTop: "1.25rem", marginLeft: "-1.25rem", marginRight: "-1.25rem" }}>
      <LoadingBox loading={isFetching}>
        <SelectionTable>
          <SelectionTableHead>
            <TableRow>
              <TableCell>Order No.</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </SelectionTableHead>
          <SelectionTableBody>
            {data?.items.map((item) => (
              <TableRow key={item.orderNumber} sx={{ cursor: "pointer" }} onClick={() => navigate(`/customer-support/orders/${item.id}`)}>
                <TableCell>{item.orderNumber}</TableCell>
                <TableCell>{moment(item.dateCreated).format("DD.MM.YYYY")}</TableCell>
                <TableCell>{item.quantity}</TableCell>
                <TableCell>
                  <OrderStatusLabel label={item.status} status={item.statusId} />
                </TableCell>
                <TableCell>{item.amountDue}</TableCell>
              </TableRow>
            ))}
          </SelectionTableBody>
        </SelectionTable>
      </LoadingBox>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination
          size={filter.size}
          page={filter.page}
          total={data?.total}
          onChange={(page) => {
            setFilter((prev) => ({ ...prev, page }));
          }}
        />
      </div>
      <Outlet />
    </div>
  );
}

export default UserOrdersScreen;
