import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { Stack } from "@mui/system";
import { DialogTitle, FormButton, FormInput } from "suregifts-component-lib";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { debitCreditSchema } from "../../../../../shared/transaction";
import { toast } from "react-toastify";

import { useParams } from "react-router-dom";
import { CreditDebitModel, useCreditWallet } from "../../../../../services/transaction";

function CreditUserModal({ handleClose }) {
  const { userId } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreditDebitModel>({
    defaultValues: {},
    resolver: yupResolver(debitCreditSchema),
  });
  const { mutate: credit, isLoading } = useCreditWallet(userId);
  const submitForm = (data) => {
    credit(data, {
      onSuccess: () => {
        toast.success("Wallet Credited successfully.");

        handleClose();
      },
    });
  };
  return (
    <Dialog open maxWidth={"xs"}>
      <DialogTitle title="Fund Wallet" handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={3}>
          <FormInput label="Credit Amout" helperText={errors.amount?.message} {...register("amount")} />
          <FormInput label="Reason" size="medium" multiline helperText={errors.reason?.message} {...register("reason")} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton fullWidth label="Fund Wallet" loading={isLoading} onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}

export default CreditUserModal;
