import { useNavigate, useParams } from "react-router-dom";
import { useGetBillers } from "../../../../services/utility";
import ListView from "./ListView";
import { MarginTop40Box, PageTitleWithBackLink, useDocumentTitle } from "suregifts-component-lib";
import { BackLinkView } from "../../../../components/molecules/BackLinkView";

function BillersScreen() {
  useDocumentTitle("Billers");
  const { categoryId } = useParams();
  const navigate = useNavigate();
  const { data, refetch } = useGetBillers(categoryId!);
  return (
    <div>
      <BackLinkView
        label={`${data?.categoryName}`}
        onClick={() => {
          navigate("/super-admin/bill-categories");
        }}
      />
      <ListView data={data?.billers} reload={refetch} />
    </div>
  );
}

export default BillersScreen;
