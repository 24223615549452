import { TableHead, TableRow, TableCell, IconButton } from "@mui/material";
import { Pagination, ErrorTickIcon, SuccessTickIcon, MainTable, MerchantApprovalType, TableBody } from "suregifts-component-lib";
import { useGetApplications } from "../../../services/merchants";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { fixedCellStyle } from "../../../helpers/cssHelper";

function TableView({ filter, handleFilter }) {
  const { data: merchants, isLoading, refetch } = useGetApplications(filter);
  const navigate = useNavigate();

  return (
    <div>
      <MainTable loading={isLoading} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Merchant Name</TableCell>
            <TableCell>Phone number</TableCell>
            <TableCell>Contact name</TableCell>
            <TableCell>Contact Email</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {merchants?.items.map((item) => (
            <TableRow key={item.id}>
              <TableCell style={fixedCellStyle}>{item.name}</TableCell>
              <TableCell style={fixedCellStyle}>{item.phone}</TableCell>
              <TableCell>{item.contactName}</TableCell>
              <TableCell style={fixedCellStyle}>{item.email}</TableCell>
              <TableCell>{moment(item.dateCreated).format("DD.MM.YYYY")}</TableCell>
              <TableCell>
                {item.approvalStatusId === MerchantApprovalType.Pending && (
                  <IconButton
                    color="error"
                    onClick={() => {
                      sessionStorage.setItem("APPLICATION_DATA", JSON.stringify(item));
                      navigate(`${item.id}/reject`);
                    }}
                  >
                    <ErrorTickIcon />
                  </IconButton>
                )}
                {item.approvalStatusId !== MerchantApprovalType.Accepted && (
                  <IconButton
                    color="success"
                    onClick={() => {
                      sessionStorage.setItem("APPLICATION_DATA", JSON.stringify(item));
                      navigate(`${item.id}/accept`);
                    }}
                  >
                    <SuccessTickIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination
          page={filter.page}
          total={merchants?.total}
          onChange={(page) => {
            handleFilter({ page });
          }}
        />
      </div>
    </div>
  );
}

export default TableView;
