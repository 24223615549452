import { Typography } from "@mui/material";

export const TopBottomLabel = ({ top, bottom }) => {
  return (
    <div>
      <span>{top}</span>
      <Typography sx={{ fontSize: "14px", color: "text.secondary" }}>{bottom}</Typography>
    </div>
  );
};
