import { Box } from "@mui/material";
import RecentOrderView from "../../../components/organisms/RecentOrderView";
import RecentRedemptionView from "../../../components/organisms/RecentRedemptionView";
import { SummaryView } from "./SummaryView";
import { MarginTop40Box, VoucherOperationType, useDocumentTitle } from "suregifts-component-lib";
import { useGetRecentOrders } from "../../../services/order";
import { useGetRecentRedemptions } from "../../../services/vouchers";

function DashboardScreen() {
  useDocumentTitle("Dashboard");
  const { data: recentOrders, isFetching: loadingOrders } = useGetRecentOrders();
  const { data: redemptions, isFetching: loadingRedemptions } = useGetRecentRedemptions();
  return (
    <div>
      <SummaryView />
      <Box
        sx={(theme) => ({
          marginTop: "40px",
          display: "grid",
          gap: "2.375rem",
          gridTemplateColumns: "1fr",
          [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "2fr 1fr",
          },
        })}
      >
        <RecentOrderView data={recentOrders} viewAllTo="/customer-support/orders" isLoading={loadingOrders} />
        <RecentRedemptionView data={redemptions} to={`/merchant-backend/reports?typeId=${VoucherOperationType.Redemption}`} isloading={loadingRedemptions} />
      </Box>
    </div>
  );
}

export default DashboardScreen;
