import FilterSection from "./FilterSection";
import TableView from "./TableView";

import { StoreFilter } from "../../../shared/merchants";
import { useDocumentTitle, useFilter } from "suregifts-component-lib";
import { Outlet } from "react-router-dom";

function StoresScreen() {
  const [filter, setFilter] = useFilter<StoreFilter>({ page: 1, search: "" });

  useDocumentTitle("Stores");
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  return (
    <div>
      <FilterSection handleFilter={handleFilter} filter={filter} />
      <TableView filter={filter} handleFilter={handleFilter} />

      <Outlet />
    </div>
  );
}

export default StoresScreen;
