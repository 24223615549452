import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RefundData, RefundModel } from "../../../../services/refunds";

export const useRefundModalState = () => {
  const [refund] = useState(() => {
    const data = sessionStorage.getItem("REFUND_DATA");
    if (!data) return {} as RefundData;
    return JSON.parse(data) as RefundData;
  });
  const [model, setModel] = useState({ refundId: refund?.refundNumber } as RefundModel);
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("..");
  };
  return { refund, handleClose, model, setModel };
};
