import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { useForm } from "react-hook-form";
import { useEditStore } from "../../../../services/merchants";
import { DialogTitle, FormButton } from "suregifts-component-lib";
import { toast } from "react-toastify";
import AddEditStoreView from "./AddEditStoreView";
import { yupResolver } from "@hookform/resolvers/yup";
import { storeSchema } from "./schema";
import { CreateEditStoreModel, StoreData } from "../../../../shared/merchants";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function EditStoreModal() {
  const nagivate = useNavigate();
  const queryClient = useQueryClient();
  const handleClose = () => {
    if (isLoading) return;
    nagivate("..");
  };

  const [store] = useState(() => {
    const data = sessionStorage.getItem("STORE_DATA");
    if (!data) return {} as StoreData;
    return JSON.parse(data) as StoreData;
  });

  const { mutate: editStore, isLoading } = useEditStore();

  const submitForm = (data) => {
    editStore(data, {
      onSuccess: () => {
        toast.success("Store created successfully.");
        queryClient.invalidateQueries("stores");
        handleClose();
      },
    });
  };
  const {
    register,
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<CreateEditStoreModel>({
    defaultValues: {
      ...store!,
      stateId: store?.stateId ?? "",
    },
    resolver: yupResolver(storeSchema),
  });
  return (
    <Dialog open maxWidth={"xs"} onClose={handleClose}>
      <DialogTitle title="Edit Store" handleClose={handleClose} />
      <DialogContent>
        <AddEditStoreView control={control} errors={errors} register={register} />
      </DialogContent>
      <DialogActions>
        <FormButton label="Edit" disabled={!isValid} loading={isLoading} fullWidth onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}
export default EditStoreModal;
