import { Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import moment from "moment";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { DialogTitle, FormButton, FormInput, TitleAndDetailsView, VoucherStatusType } from "suregifts-component-lib";

import { VoucherInfoData } from "../../../../shared/voucher";
import { useCheckBalance } from "../../../../services/merchant-dashboard/voucher";
import { useNavigate } from "react-router-dom";
import { VoucherStatusLabel } from "../../../../components/molecules/VoucherStatusLabel";

function CheckBalanceModal() {
  const { mutate: checkBalance, isLoading } = useCheckBalance();
  const [info, setVoucherInfo] = useState<VoucherInfoData | undefined>();

  const [voucherCode, setVoucherCode] = useState("");

  const navigate = useNavigate();
  const handleClose = () => {
    if (isLoading) return;
    navigate("..");
  };

  const handleCheckBalance = () => {
    if (!voucherCode) {
      toast.error("Voucher code is required.");
      return;
    }
    checkBalance(voucherCode, {
      onSuccess: (data) => {
        setVoucherInfo(data);
      },
    });
  };
  return (
    <Dialog open maxWidth="xs">
      <DialogTitle title="Voucher Details" handleClose={handleClose} />
      {info ? (
        <>
          <DialogContent>
            <Stack spacing={3}>
              <TitleAndDetailsView title="Voucher Code" details={info.voucherCode} />
              <TitleAndDetailsView title="Serial Number" details={info.serialNumber} />
              <TitleAndDetailsView title="Initial Value" details={info.value} />
              <TitleAndDetailsView title="Current Balance" details={info.balance} />
              <TitleAndDetailsView title="Vouher Type" details={info.merchantName} />
              <TitleAndDetailsView title="Voucher Status" details={<VoucherStatusLabel text={info.statusId === VoucherStatusType.Active ? "Usable" : "Not Usable"} status={info.statusId} />} />
              <TitleAndDetailsView title="Expiry Date" details={moment(info.expiryDate).format("DD-MM-YYYY")} />
            </Stack>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogContent>
            <FormInput label="Voucher Code" value={voucherCode} onChange={(e) => setVoucherCode(e.target.value)} />
          </DialogContent>
          <DialogActions>
            <FormButton loading={isLoading} onClick={handleCheckBalance} fullWidth label="Check Balance" color="secondary" />
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default CheckBalanceModal;
