import { Dialog, DialogActions, DialogContent, Stack } from "@mui/material";
import { type } from "@testing-library/user-event/dist/type";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DialogTitle, FormButton, FormInput } from "suregifts-component-lib";
import { useApprovePayment, useRejectPayment } from "../../../../services/transaction";
import { PaymentData } from "../../../../shared/transaction";
import { PaymnetDetailsView } from "../../../customer-support/payments/PaymentDetailsModal";
import { useQueryClient } from "react-query";
import { usePaymentModalState } from "./usePaymentModalState";

function ApprovePaymentModal() {
  const queryClient = useQueryClient();
  const { mutate: approve, isLoading } = useApprovePayment();
  const { handleClose, payment } = usePaymentModalState();
  const handleApprove = () => {
    approve(payment!.paymentNumber, {
      onSuccess: () => {
        toast.success("Payment approved successfully.");
        queryClient.invalidateQueries("payments");
        handleClose();
      },
    });
  };
  return (
    <div>
      <Dialog maxWidth={"xs"} open={true} sx={{}}>
        <DialogTitle title={"Approve Payment"} handleClose={handleClose} />
        <DialogContent>
          <Stack spacing={4}>
            <PaymnetDetailsView payment={payment!} />
          </Stack>
          <DialogActions>
            <FormButton color="success" fullWidth loading={isLoading} onClick={handleApprove} label="Approve Payment"></FormButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ApprovePaymentModal;
