import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { useForm } from "react-hook-form";
import { useCreateStore } from "../../../../services/merchants";
import { DialogTitle, FormButton } from "suregifts-component-lib";
import { toast } from "react-toastify";
import AddEditStoreView from "./AddEditStoreView";
import { yupResolver } from "@hookform/resolvers/yup";
import { storeSchema } from "./schema";
import { CreateEditStoreModel } from "../../../../shared/merchants";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";

function AddStoreModal() {
  const nagivate = useNavigate();
  const queryClient = useQueryClient();
  const handleClose = () => {
    if (isLoading) return;
    nagivate("..");
  };
  const { mutate: createStore, isLoading } = useCreateStore();

  const submitForm = (data) => {
    createStore(data, {
      onSuccess: () => {
        toast.success("Store created successfully.");
        queryClient.invalidateQueries("stores");
        handleClose();
      },
    });
  };
  const {
    register,
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<CreateEditStoreModel>({
    resolver: yupResolver(storeSchema),
    defaultValues: {
      stateId: "",
    },
  });
  return (
    <Dialog open maxWidth={"xs"} onClose={handleClose}>
      <DialogTitle title="Add Store" handleClose={handleClose} />
      <DialogContent>
        <AddEditStoreView control={control} errors={errors} register={register} />
      </DialogContent>
      <DialogActions>
        <FormButton label="Create" disabled={!isValid} loading={isLoading} fullWidth onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}
export default AddStoreModal;
