import { Dialog, DialogContent, Stack } from "@mui/material";
import { DialogTitle } from "suregifts-component-lib";
import { PaymnetDetailsView } from "../../../customer-support/payments/PaymentDetailsModal";
import { usePaymentModalState } from "./usePaymentModalState";

function ViewPaymentModal() {
  const { handleClose, payment } = usePaymentModalState();
  return (
    <div>
      <Dialog maxWidth={"xs"} open={true} sx={{}}>
        <DialogTitle title={"Payment Details"} handleClose={handleClose} />
        <DialogContent>
          <Stack spacing={4}>
            <PaymnetDetailsView payment={payment!} />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ViewPaymentModal;
