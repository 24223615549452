import { TableHead, TableRow, TableCell } from "@mui/material";
import moment from "moment";
import React from "react";
import { MarginTop40Box, PageTitleWithLink, MainTable, OrderStatusLabel, TableBody, dateOnly } from "suregifts-component-lib";
import { useGetRecentOrders } from "../../../services/order";
import { useNavigate } from "react-router-dom";

function RecentOrderSection() {
  const { data, isLoading } = useGetRecentOrders();
  const navigate = useNavigate();
  return (
    <MarginTop40Box>
      <PageTitleWithLink title={"Recent Orders"} to="/orders" />
      <MainTable
        loading={isLoading}
        sx={{
          marginTop: "1.85rem",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Order No</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Sender Name</TableCell>
            <TableCell>Sender Email</TableCell>
            <TableCell>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item) => (
            <TableRow
              key={item.id}
              onClick={() => navigate(`${item.id}`)}
              sx={{
                cursor: "pointer",
              }}
            >
              <TableCell>{item.orderNumber}</TableCell>
              <TableCell>{dateOnly(item.date)}</TableCell>
              <TableCell>
                <OrderStatusLabel label={item.status} status={item.statusId} />
              </TableCell>
              <TableCell>{item.senderEmail}</TableCell>
              <TableCell>{item.senderName}</TableCell>
              <TableCell>{item.amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
    </MarginTop40Box>
  );
}

export default RecentOrderSection;
