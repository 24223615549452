import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { useForm } from "react-hook-form";
import { DialogTitle, FormButton } from "suregifts-component-lib";
import { toast } from "react-toastify";
import AddEditStoreView from "./AddEditStoreView";
import { yupResolver } from "@hookform/resolvers/yup";
import { storeSchema } from "./schema";
import { CreateEditStoreModel } from "../../../../shared/merchants";
import { useCreateStore } from "../../../../services/merchant-dashboard/merchant";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";

function AddStoreModal() {
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("..");
  };
  const { mutate: createStore, isLoading } = useCreateStore();
  const queryClient = useQueryClient();
  const submitForm = (data) => {
    createStore(data, {
      onSuccess: () => {
        toast.success("Store created successfully.");
        queryClient.invalidateQueries("stores");
        handleClose();
      },
    });
  };
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<CreateEditStoreModel>({ resolver: yupResolver(storeSchema) });
  return (
    <Dialog open maxWidth={"xs"} scroll="paper">
      <DialogTitle title="Add Store" handleClose={handleClose} />
      <DialogContent>
        <AddEditStoreView control={control} errors={errors} register={register} />
      </DialogContent>
      <DialogActions>
        <FormButton label="Create" loading={isLoading} fullWidth onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}
export default AddStoreModal;
