import * as yup from "yup";
import { AddPersonnelModel, CreateEditStoreModel } from "../../../../shared/merchants";
import { YupShape } from "suregifts-component-lib";
export const storeSchema = yup
  .object<YupShape<CreateEditStoreModel>>({
    name: yup.string().required(),
    address: yup.string().required(),
    merchantId: yup.string().required(),
    contactPhone: yup.string().required(),
    email: yup.string().email().required(),
  })
  .required();
export const personnelSchema = yup
  .object<YupShape<AddPersonnelModel>>({
    email: yup.string().email().required(),
    roleId: yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
  })
  .required();
