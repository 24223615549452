import { ApplicationFilter } from "../../../services/merchants";
import FilterSection from "./FilterSection";
import TableView from "./TableView";
import { useDocumentTitle, useFilter } from "suregifts-component-lib";
import { Outlet } from "react-router-dom";

function ApplicationScreen() {
  useDocumentTitle("Applications");
  const [filter, setFilter] = useFilter<ApplicationFilter>({ page: 1, search: "" } as ApplicationFilter);
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  return (
    <div>
      <FilterSection handleFilter={handleFilter} filter={filter} />
      <TableView filter={filter} handleFilter={handleFilter} />
      <Outlet />
    </div>
  );
}

export default ApplicationScreen;
