import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { Stack } from "@mui/system";
import { Controller, useForm } from "react-hook-form";
import { DialogTitle, FormButton, FormInput, FormSelect } from "suregifts-component-lib";
import { useGetMerchantNames, useGetStoreNames } from "../../../../services/merchants";
import { useRedeemVoucher } from "../../../../services/vouchers";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";

import { RedeemVoucherModel, redeemSchema } from "../../../../shared/voucher";
import { useNavigate } from "react-router-dom";

function RedeemVoucherModal() {
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<RedeemVoucherModel>({
    defaultValues: {
      storeId: "",
      merchantId: "",
      voucherCode: "",
      amountToUse: "",
      pin: "",
    },
    resolver: yupResolver(redeemSchema),
  });
  const { data: merchants } = useGetMerchantNames();
  const { data: stores } = useGetStoreNames(watch("merchantId"));

  const { mutate: redeem, isLoading } = useRedeemVoucher();
  const navigate = useNavigate();
  const handleClose = () => {
    if (isLoading) return;
    navigate("..");
  };
  const submitForm = (data) => {
    redeem(data, {
      onSuccess: () => {
        toast.success("Voucher redeemed successfully.");
        handleClose();
      },
    });
  };
  return (
    <Dialog open maxWidth="xs" onClose={handleClose}>
      <DialogTitle title="Redeem Voucher" handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={2}>
          <Controller control={control} name="merchantId" render={({ field }) => <FormSelect required helperText={errors.merchantId?.message} {...field} label={"Merchant"} options={merchants} />} />
          <Controller control={control} name="storeId" render={({ field }) => <FormSelect required helperText={errors.storeId?.message} {...field} label={"Store"} options={stores} />} />
          <FormInput required value={watch("amountToUse")} label="Amount Used" {...register("amountToUse")} helperText={errors.amountToUse?.message} />
          <FormInput isNumber value={watch("voucherCode")} required label="Voucher Code" {...register("voucherCode")} helperText={errors.voucherCode?.message} />
          <FormInput type="password" isNumber required value={watch("pin")} label="PIN" {...register("pin")} helperText={errors.amountToUse?.message} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton disabled={!isValid} label="Redeem" fullWidth loading={isLoading} onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}

export default RedeemVoucherModal;
