import { Box } from "@mui/material";
import { useState } from "react";
import TableView from "./TableView";

import { PaymentFilter } from "../../../shared/transaction";
import { MarginTop40Box, SearchContainer, PageHeaderTitle, FilterDropdown, SearchInput, SearchButton, DateRangeFilter, useDocumentTitle, useFilter, useAuthContext } from "suregifts-component-lib";
import { useExportPayments } from "../../../services/transaction";
const getMethods = (countryId) => {
  return [
    { text: "All" },
    { text: "Paypal", value: 2 },
    { text: "Bank Transfer", value: 3 },
    {
      text: "Credit/Debit Card",
      value: countryId == 2 ? 9 : 6,
    },
    {
      text: "Crypto",
      value: 7,
    },
  ];
};
const statuses = [
  { text: "All" },
  { text: "Pending Review", value: "-1" },
  { text: "Not Completed", value: "0" },
  {
    text: "Confirmed",
    value: "1",
  },
  { text: "Declined", value: "2" },
];
function PaymentsScreen() {
  const { user } = useAuthContext();
  const [filter, setFilter] = useFilter<PaymentFilter>({ page: 1, search: "" });
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  const { mutate: exportData } = useExportPayments(filter);
  useDocumentTitle("Payments");
  return (
    <div>
      <SearchContainer sx={{ marginTop: "40px" }}>
        <PageHeaderTitle title="Payments" />
        <div style={{ flexGrow: 1 }}></div>
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <DateRangeFilter display={"Date"} valueChanged={(params) => handleFilter(params)} />
          <FilterDropdown
            display={"Status"}
            options={statuses}
            value={filter.statusId}
            onChange={(index, value) => {
              handleFilter({ statusId: value, page: 1 });
            }}
          />
          <FilterDropdown
            display={"Payment Method"}
            options={getMethods(user?.countryId)}
            value={filter.method}
            onChange={(index, value) => {
              handleFilter({ method: value, page: 1 });
            }}
          />
          <SearchInput
            value={filter.search}
            onChange={(e) => {
              handleFilter({ search: e });
            }}
          />
          <SearchButton label="Export" onClick={() => exportData()} />
        </Box>
      </SearchContainer>
      <TableView filter={filter} handlerFilter={handleFilter} />
    </div>
  );
}

export default PaymentsScreen;
