import { Dialog, DialogContent, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import moment from "moment";
import { DialogTitle, MainTable, SearchContainer, TableBody, TitleAndDetailsView, dateOnly, dateTime, useAuthContext } from "suregifts-component-lib";
import { VoucherStatusLabel } from "./VoucherStatusLabel";
import { VoucherInfoData } from "../../shared/voucher";
import React from "react";
import { useNavigate } from "react-router-dom";
type VoucherInfoViewProps = {
  info: VoucherInfoData;
  actionView: React.ReactNode;
};
export const VoucherInfoView = ({ info, actionView }: VoucherInfoViewProps) => {
  const { user } = useAuthContext();

  const navigate = useNavigate();
  const handleClose = () => {
    navigate("..");
  };

  return (
    <Dialog open maxWidth={"md"}>
      <DialogTitle title="Voucher Info" handleClose={handleClose} />
      <DialogContent>
        <InfoDisplay info={info} />
        <div style={{ marginTop: "30px" }}>
          <SearchContainer>
            <Typography sx={{ fontSize: "1.15rem", fontWeight: 500 }}>Transaction History </Typography>
            <div style={{ marginBottom: "20px", display: "flex", flexDirection: "row-reverse" }}>{actionView}</div>
          </SearchContainer>
          <VoucherInfoOperationView info={info} />
        </div>
      </DialogContent>
    </Dialog>
  );
};
type VoucherInfoOperationViewProps = {
  info: VoucherInfoData;
};
const VoucherInfoOperationView = ({ info }: VoucherInfoOperationViewProps) => {
  return (
    <MainTable sx={{ marginTop: "20px" }}>
      <TableHead>
        <TableRow>
          <TableCell>Date/Time</TableCell>
          <TableCell style={{ width: "70%" }}>Narration</TableCell>
          <TableCell>Amount</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {info.operations.map((item) => (
          <TableRow key={item.date}>
            <TableCell>{dateTime(item.date)}</TableCell>
            <TableCell>{item.narration}</TableCell>
            <TableCell>{item.amount}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </MainTable>
  );
};
const InfoDisplay = ({ info }) => {
  return (
    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", rowGap: "20px", columnGap: "40px" }}>
      <TitleAndDetailsView title="Voucher Code" details={info.voucherCode} />
      <TitleAndDetailsView title="Serial Number" details={info.serialNumber} />
      <TitleAndDetailsView title="Value" details={info.value} />
      <TitleAndDetailsView title="Balance" details={info.balance} />
      <TitleAndDetailsView title="Requester Email" details={info.requesterEmail} />
      <TitleAndDetailsView title="Recipient Email" details={info.email} />
      <TitleAndDetailsView title="Recipient Name" details={info.name} />
      <TitleAndDetailsView title="Category" details={info.type} />
      <TitleAndDetailsView title="Voucher Type" details={info.merchantName} />
      <TitleAndDetailsView title="Valid Regions" details={info.countries} />
      <TitleAndDetailsView title="Voucher Status" details={<VoucherStatusLabel text={info.status} status={info.statusId} />} />
      <TitleAndDetailsView title="Expiry Date" details={dateOnly(info.expiryDate)} />
      <TitleAndDetailsView title="Valid Countries" details={info.countries} />
    </div>
  );
};
