import { TableHead, TableRow, TableCell, IconButton } from "@mui/material";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { EditPencilIcon, DeleteIcon, Pagination, MainTable, useNotificationContext, TableBody } from "suregifts-component-lib";
import { useGetStores, useDeleteStore } from "../../../services/merchants";

import { useNavigate } from "react-router-dom";
import { fixedCellStyle } from "../../../helpers/cssHelper";

function TableView({ filter, handleFilter }) {
  const { data: stores, isFetching, refetch } = useGetStores(filter);
  const { mutate: deleteRecord, isLoading: deleting } = useDeleteStore();
  const navigate = useNavigate();
  const { confirm } = useNotificationContext();
  const handleDelete = (store) => {
    confirm({
      onConfirm: () => {
        deleteRecord(store, {
          onSuccess: () => {
            toast.success("Store deleted successufully.");
            refetch();
          },
        });
      },
    });
  };

  return (
    <div>
      <MainTable loading={isFetching} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Store Name</TableCell>
            <TableCell>Merchant Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stores?.items.map((item) => (
            <TableRow key={item.id}>
              <TableCell style={fixedCellStyle}>{item.name}</TableCell>
              <TableCell style={fixedCellStyle}>{item.merchantName}</TableCell>
              <TableCell>{item.email}</TableCell>
              <TableCell>{item.contactPhone}</TableCell>
              <TableCell sx={{}}>
                <IconButton
                  onClick={() => {
                    sessionStorage.setItem("STORE_DATA", JSON.stringify(item));
                    navigate(":storeId/edit");
                  }}
                >
                  <EditPencilIcon />
                </IconButton>
                <IconButton color="error" onClick={() => handleDelete(item)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination
          page={filter.page}
          total={stores?.total}
          onChange={(page) => {
            handleFilter({ page });
          }}
        />
      </div>
    </div>
  );
}

export default TableView;
