import { Box, Button, Divider, Stack } from "@mui/material";
import { useMemo, useState } from "react";
import { useOrderScreenContext } from "./context/ScreenContext";
import { useParams } from "react-router-dom";
import { OrderInfoData, useChangeCardStatus } from "../../../../services/order";
import { DeliveryStatusType, FilterDropdown, FormButton, OptionNaming, OrderItemStatusLabel, PageHeaderTitle, TitleDetailView } from "suregifts-component-lib";
import moment from "moment";
import { toast } from "react-toastify";
import EditRecipientModal from "./modals/EditRecipientModal";
import { GiftCardStatusView } from "./GiftCardStatusView";

const PageHeader = () => {
  const { dispatch, state } = useOrderScreenContext();
  const editDisabled = useMemo(() => {
    var failedStatus = [DeliveryStatusType.Failed, DeliveryStatusType.Canceled];
    return failedStatus.indexOf(state.recipient!.statusId) === 1;
  }, [state.recipient?.statusId]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <PageHeaderTitle title={"Gift Card Details"} />
      <Button
        disabled={editDisabled}
        color="secondary"
        onClick={() => dispatch({ pageType: "Edit" })}
        sx={{
          borderRadius: "0.75rem",
          height: "2.4375rem",
          width: "5.25rem",
        }}
      >
        Edit
      </Button>
    </div>
  );
};
type GiftCardInfoViewProps = {
  order?: OrderInfoData;
};
export function GiftCardInfoView({ order }: GiftCardInfoViewProps) {
  const { dispatch, state } = useOrderScreenContext();
  const { orderId } = useParams();
  const item = state.recipient!;
  const [statusId, setStatusId] = useState(item.statusId);
  const { mutate: changeStatus, isLoading: isChangingStatus } = useChangeCardStatus(orderId, item.id);
  const handleChangeStatus = () => {
    changeStatus(undefined, {
      onSuccess: () => {
        toast.success("Gift card status was changed successfully.");
        dispatch({ pageType: "Main" });
      },
    });
  };
  return (
    <div>
      <PageHeader />
      <Box sx={{ borderRadius: "1.25rem", p: "1.85rem", mt: "1.85rem", backgroundColor: "primary.main" }}>
        <Stack spacing={3}>
          <Stack direction={"row"} spacing={3}>
            <TitleDetailView title="Date Created" detail={moment(item.dateCreated).format("DD.MM.YYYY")} />
            <TitleDetailView align="right" title="Sender's Name" detail={order?.senderName} />
          </Stack>
          <Stack direction={"row"} spacing={3}>
            <TitleDetailView title="Delivery Type" detail={item.deliveryMedium} />
            <TitleDetailView align="right" title="Order Status" detail={<GiftCardStatusView onChange={(statusId) => setStatusId(statusId)} display={item.status} statusId={statusId} />} />
          </Stack>
          {item.statusId !== statusId && <FormButton onClick={handleChangeStatus} loading={isChangingStatus} sx={{ padding: "1.3125rem" }} fullWidth label="Update Order" color="primary" />}
        </Stack>
        <Divider sx={{ my: "2.5rem" }} />
        <Stack spacing={3}>
          <Stack direction={"row"} spacing={3}>
            <TitleDetailView title="Recipient Name" detail={`${item.firstName} ${item.lastName}`} />
            <TitleDetailView align="right" title="Contact" detail={item.email || item.phoneNumber} />
          </Stack>
          {item.address && <TitleDetailView title="Contact" detail={item.address} />}
        </Stack>
      </Box>
      {state.pageType === "Edit" && <EditRecipientModal />}
    </div>
  );
}
