import { useState } from "react";
import FilterSection from "./FilterSection";
import ListView from "./ListView";
import { MarginTop40Box, useDocumentTitle } from "suregifts-component-lib";

function BillCategoriesScreen() {
  useDocumentTitle("Bill Categories");
  return (
    <div>
      <FilterSection />
      <ListView />
    </div>
  );
}

export default BillCategoriesScreen;
