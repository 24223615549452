import { TableHead, TableRow, TableCell, Chip } from "@mui/material";
import { MainTable, Pagination, TableBody, VoucherOperationType, dateTime } from "suregifts-component-lib";
import { useGetTransactions } from "../../../services/vouchers";
import { fixedCellStyle } from "../../../helpers/cssHelper";

export const TableView = ({ filter, handlePage }) => {
  const { data, isFetching } = useGetTransactions({ ...filter, typeId: 8 });
  return (
    <>
      <MainTable loading={isFetching} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Voucher code</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell style={{ width: "35%" }}>Point of Usage</TableCell>

            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items.map((item) => (
            <TableRow key={item.id}>
              <TableCell style={fixedCellStyle}>{item.code}</TableCell>
              <TableCell>
                {item.amount}
                {item.refundStatus && (
                  <>
                    <br />
                    <Chip size="small" variant="outlined" label={item.refundStatus} color="error" />
                  </>
                )}
              </TableCell>
              <TableCell>
                <div>{item.merchantName}</div>
                {item.narration}
              </TableCell>
              <TableCell style={fixedCellStyle}>{dateTime(item.dateTime)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination page={filter.page} total={data?.total} onChange={handlePage} />
      </div>
    </>
  );
};
