import { TableHead, TableRow, TableCell, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { EditPencilIcon, DeleteIcon, Pagination, ThreePeopleIcon, MainTable, useNotificationContext, TableBody, usePagination, useDocumentTitle, useFilter } from "suregifts-component-lib";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useDeleteStore, useGetStores } from "../../../services/merchant-dashboard/merchant";
import { StoreFilter } from "../../../shared/merchants";
import { fixedCellStyle } from "../../../helpers/cssHelper";

function TableView({}) {
  const [filter, setFilter] = useFilter<StoreFilter>({ page: 1 });

  const { data: stores, isFetching, refetch } = useGetStores(filter);
  const { data, total } = usePagination(filter.page, stores, 10);
  const { mutate: deleteRecord, isLoading: deleting } = useDeleteStore();
  const navigate = useNavigate();
  const { confirm } = useNotificationContext();
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  const handleDelete = (store) => {
    confirm({
      onConfirm: () => {
        deleteRecord(store, {
          onSuccess: () => {
            toast.success("Store deleted successufully.");
            refetch();
          },
        });
      },
    });
  };

  return (
    <div style={{ marginTop: "40px" }}>
      <MainTable loading={isFetching}>
        <TableHead>
          <TableRow>
            <TableCell>Store Name</TableCell>

            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Store Type</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item) => (
            <TableRow key={item.id}>
              <TableCell style={fixedCellStyle}>{item.name}</TableCell>
              <TableCell style={fixedCellStyle}>{item.email}</TableCell>
              <TableCell>{item.contactPhone}</TableCell>
              <TableCell>{item.storeType}</TableCell>
              <TableCell sx={{ width: "10%", whiteSpace: "nowrap" }}>
                <IconButton
                  onClick={() => {
                    sessionStorage.setItem("STORE_DATA", JSON.stringify(item));
                    navigate(`${item.id}/edit`);
                  }}
                >
                  <EditPencilIcon />
                </IconButton>
                <IconButton color="error" onClick={() => handleDelete(item)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination
          size={filter.size}
          page={filter.page}
          total={total}
          onChange={(page) => {
            handleFilter({ page });
          }}
        />
      </div>
    </div>
  );
}

export default TableView;
