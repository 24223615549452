import { useMutation, useQuery } from "react-query";
import { Count, NameAndId, PageFilter, ServerError, useAxios } from "suregifts-component-lib";
import { WalletByIdData, WalletTransactionFilter, WalletTransactionDto, StoreData, MerchantData } from "../components/organisms/merchant-wallets/data";
import { MerchantNameDto } from "./merchants";

export const useGetMerchantWallets = () => {
  const axios = useAxios();

  const path = "/merchants/api/wallet/merchants";
  return useQuery<MerchantData[], ServerError>({
    queryKey: ["merchants-wallets"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get(path, {}),
  });
};
export const useGetMerchantWalletById = (merchantId) => {
  const axios = useAxios();
  const path = `/merchants/api/wallet/merchants/${merchantId}`;
  return useQuery<WalletByIdData, ServerError>({
    queryKey: ["merchant-wallet-by-id", { merchantId }],
    queryFn: () => axios.get(path),
  });
};
export const useGetMerchantWalletTransactions = (merchantId: any, params: WalletTransactionFilter) => {
  const axios = useAxios();
  const path = `/merchants/api/wallet/merchants/${merchantId}/transactions`;
  return useQuery<Count<WalletTransactionDto>, ServerError>({
    queryKey: ["merchant-wallet-history", params],
    keepPreviousData: true,
    queryFn: () => axios.get(path, { params }),
  });
};
export const useCreditMerchantWallet = (merchantId) => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, CreditDebitModel>((data) => {
    const path = `/merchants/api/wallet/merchants/${merchantId}/credit`;
    return axios.post(path, data);
  });
};
export const useDebitMerchantWallet = (merchantId) => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, CreditDebitModel>((data) => {
    const path = `/merchants/api/wallet/merchants/${merchantId}/debit`;
    return axios.post(path, data);
  });
};

export const useGetStoreWallets = () => {
  const axios = useAxios();
  const path = "/merchants/api/wallet/stores";
  return useQuery<StoreData[], ServerError>({
    queryKey: ["store-wallets"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get(path),
  });
};
export const useGetStoreWalletById = (storeId) => {
  const axios = useAxios();
  const path = `/merchants/api/wallet/stores/${storeId}`;
  return useQuery<WalletByIdData, ServerError>({
    queryKey: ["store-wallet-by-id", { storeId }],
    queryFn: () => axios.get(path),
  });
};
export const useGetStoreWalletTransactions = (storeId: any, params: WalletTransactionFilter) => {
  const axios = useAxios();
  const path = `/merchants/api/wallet/stores/${storeId}/transactions`;
  return useQuery<Count<WalletTransactionDto>, ServerError>({
    queryKey: ["store-wallet-history", params],
    keepPreviousData: true,
    queryFn: () => axios.get(path, { params }),
  });
};
export const useCreditStoreWallet = (storeId) => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, CreditDebitModel>((data) => {
    const path = `/merchants/api/wallet/stores/${storeId}/credit`;
    return axios.post(path, data);
  });
};
export const useDebitStoreWallet = (storeId) => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, CreditDebitModel>((data) => {
    const path = `/merchants/api/wallet/stores/${storeId}/debit`;
    return axios.post(path, data);
  });
};

export interface CreditDebitModel {
  amount: string;
  narration: string;
}
