import { Box } from "@mui/material";
import { VoucherOperationType, useDocumentTitle } from "suregifts-component-lib";
import RecentActivationView from "../../../components/organisms/RecentActivationView";
import RecentRedemptionView from "../../../components/organisms/RecentRedemptionView";
import { useGetRecentActivations, useGetRecentRedemptions } from "../../../services/vouchers";

import { SummarySection } from "./SummarySection";
import { Outlet } from "react-router-dom";

function VoucherOperationScreen() {
  const { data: activations } = useGetRecentActivations();
  const { data: redemptions } = useGetRecentRedemptions();
  useDocumentTitle("Voucher Operations");
  return (
    <div>
      <SummarySection />
      <Box
        sx={(theme) => ({
          display: "grid",
          gap: "3rem",
          marginTop: "40px",
          gridTemplateColumns: "1fr",
          [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "2fr 1fr",
          },
        })}
      >
        <RecentActivationView data={activations} to={`/merchant-backend/reports?typeId=${VoucherOperationType.Activation}`} />
        <RecentRedemptionView data={redemptions} to={`/merchant-backend/reports?typeId=${VoucherOperationType.Redemption}`} />
      </Box>
      <Outlet />
    </div>
  );
}

export default VoucherOperationScreen;
