import { useMutation, useQuery } from "react-query";
import { useAxios, Count, ServerError, PageFilter } from "suregifts-component-lib";
import { PaymentData, PaymentFilter } from "../shared/transaction";
export const useGetRecentPayments = () => {
  const axios = useAxios();

  const path = "/transactions/api/payments";
  return useQuery<Count<PaymentData>, ServerError, PaymentData[]>({
    queryKey: ["recent-payments"],
    initialData: { items: [], total: 0 },
    queryFn: () =>
      axios.get(path, {
        params: { page: 1, size: 8 },
      }),
    select: (data) => data.items,
  });
};
export const useGetPayments = (filter: PaymentFilter) => {
  const axios = useAxios();

  const path = "/transactions/api/payments";
  return useQuery<Count<PaymentData>, ServerError>({
    queryKey: ["payments", filter],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    queryFn: () =>
      axios.get(path, {
        params: filter,
      }),
  });
};
export const useExportPayments = (filter: PaymentFilter) => {
  var axios = useAxios();

  const path = `/transactions/api/payments/export`;
  return useMutation(() => {
    return axios.get(path, {
      params: filter,
    });
  });
};

export const useRejectPayment = () => {
  const axios = useAxios();

  return useMutation<unknown, ServerError, RejectPaymentModel>((data) => {
    const path = `/transactions/api/payments/${data.paymentId}/reject`;
    return axios.post(path, data);
  });
};

export const useApprovePayment = () => {
  const axios = useAxios();

  return useMutation<unknown, ServerError, string>((paymentId) => {
    const path = `/transactions/api/payments/${paymentId}/approve`;
    return axios.post(path);
  });
};

export interface RejectPaymentModel {
  rejectionReason: string;
  paymentId: any;
}

export const useCreditWallet = (userId) => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, CreditDebitModel>((model) => {
    return axios.post(`/transactions/api/${userId}/credit`, model);
  });
};
export const useDebitWallet = (userId) => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, CreditDebitModel>((model) => {
    return axios.post(`/transactions/api/${userId}/debit`, model);
  });
};
export const useGetWalletHistory = (filter: TransactionFilter) => {
  const axios = useAxios();
  return useQuery<Count<WalletTransactionData>, ServerError>({
    queryKey: ["transactions", filter],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    queryFn: () =>
      axios.get(`/transactions/api/${filter.userId}`, {
        params: filter,
      }),
  });
};
export interface CreditDebitModel {
  amount: number;
  reason: string;
}
export interface TransactionFilter extends PageFilter {
  userId: any;
}
export interface WalletTransactionData {
  clientId: string;
  firstName: string;
  lastName: string;
  email: string;
  transactionId: string;
  invoiceNumber: string;
  amount: string;
  previousBalance: string;
  newBalance: string;
  isCredit: boolean;
  dateCreated: string;
  extraInfo: object;
  externalId: string;
  transactionTypeId: number;
  transactionType: string;
  narration: string;
}
