import { Box, Stack, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuthContext } from "suregifts-component-lib";

const ProfileImageView = () => {
  const { user } = useAuthContext();

  return (
    <div style={{ display: "flex", gap: "1.25rem", position: "relative" }}>
      <Box component={"img"} src={"/assets/images/avater.png"} sx={{ width: "5.625rem", height: "5.625rem", overflow: "hidden", cursor: "pointer", borderRadius: "50%", objectFit: "cover" }}></Box>
      <Stack alignItems="start" justifyContent={"center"}>
        <Typography color="text.secondary" sx={{ fontSize: "0.875rem" }}>
          {user?.role}
        </Typography>
        <Typography fontSize={"1.125rem"}>{user?.email}</Typography>
      </Stack>
    </div>
  );
};
export default ProfileImageView;
