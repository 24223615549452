import { Box, Typography } from "@mui/material";

export type RedemptionInfoViewProps = {
  merchant: string;
  reference: string;
  pointOfUsage: string;
};
export const RedemptionInfoView = (props: RedemptionInfoViewProps) => {
  return (
    <Box sx={{ textAlign: "left", color: "text.secondary", fontSize: "0.875rem", fontWeight: 500 }}>
      <Typography sx={{ fontSize: "0.875rem" }}>{props.reference}</Typography>
      <Typography sx={{ color: "text.primary", fontWeight: 500, fontSize: "1.125rem" }}>{props.pointOfUsage}</Typography>
      {/* <Typography>{props.recipient}</Typography> */}
    </Box>
  );
};
export default RedemptionInfoView;
