import { Box } from "@mui/material";
import { useState } from "react";
import TableView from "./TableView";
import { RefundFilter, useExportRefunds } from "../../../services/refunds";
import { MarginTop40Box, SearchContainer, PageHeaderTitle, DateRangeFilter, FilterDropdown, SearchInput, SearchButton, useFilter } from "suregifts-component-lib";
import { Outlet } from "react-router-dom";
const statuses = [{ text: "All" }, { text: "Pending", value: 0 }, { text: "Approved", value: 1 }, { text: "Rejected", value: 2 }];

export function RefundsScreen() {
  const [filter, setFilter] = useFilter<RefundFilter>({ page: 1 });

  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  const { mutate: exportData } = useExportRefunds(filter);

  return (
    <div>
      <MarginTop40Box>
        <SearchContainer>
          <PageHeaderTitle title="Refunds" />
          <div style={{ flexGrow: 1 }}></div>
          <Box
            sx={{
              display: "flex",
              gap: "1.25rem",
              alignItems: "center",
            }}
          >
            <DateRangeFilter display={"Date"} valueChanged={(params) => handleFilter({ ...params, page: 1 })} />
            <FilterDropdown
              display={"Type"}
              options={statuses}
              value={filter.status}
              onChange={(index) => {
                handleFilter({ status: statuses[index].value, page: 1 });
              }}
            />
            <SearchInput
              value={filter.search}
              onChange={(e) => {
                handleFilter({ search: e, page: 1 });
              }}
            />
            <SearchButton label="Export" onClick={() => exportData()} />
          </Box>
        </SearchContainer>
      </MarginTop40Box>
      <TableView filter={filter} handlerFilter={handleFilter} />
      <Outlet />
    </div>
  );
}
