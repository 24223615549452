import { TableHead, TableRow, TableCell, TableBody, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IOSSwitch, MainTable, Pagination } from "suregifts-component-lib";
import { BillCategoryData, useChangeCategoryStatus, useGetBillCategories } from "../../../../services/utility";
import VisibilityIcon from "@mui/icons-material/Visibility";

type ListViewProps = {};

function ListView({}: ListViewProps) {
  const navigate = useNavigate();
  const {
    data,
    isLoading,
    refetch: loadData,
  } = useGetBillCategories(() => {
    handlePage();
  });

  const [categories, setCategories] = useState<BillCategoryData[] | undefined>([]);
  const [page, setPage] = useState(1);

  const { mutate: changeStatus, isLoading: changingStatus } = useChangeCategoryStatus();
  useEffect(() => {
    handlePage();
  }, [data, page]);

  const handlePage = () => {
    var skip = 10 * (page - 1);
    setCategories(data?.slice(skip, skip + 10));
  };

  return (
    <>
      <MainTable loading={isLoading || changingStatus} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Provider</TableCell>
            <TableCell>Countries</TableCell>
            <TableCell>Show/Hide</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categories?.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.provider}</TableCell>
              <TableCell>{item.countries}</TableCell>
              <TableCell>
                <IconButton>
                  <IOSSwitch
                    checked={item.isActive}
                    onChange={() => {
                      changeStatus(item.id, {
                        onSuccess() {
                          toast.success("Category updated successfully.");
                          loadData();
                        },
                      });
                    }}
                  />
                </IconButton>
                <IconButton
                  onClick={() => {
                    navigate(`/super-admin/${item.id}/billers`);
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination total={data?.length} page={page} onChange={(page) => setPage(page)} />
      </div>
    </>
  );
}
export default ListView;
