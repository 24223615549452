import { Box } from "@mui/material";
import React from "react";
import { SearchContainer, FilterDropdown, DateRangeFilter, SearchInput, SearchButton, PageHeaderTitle } from "suregifts-component-lib";
import { useExportOrders } from "../../../../services/order";
const statuses = [
  { text: "All" },
  { text: "Checkedout", value: 1 },
  {
    text: "Completed",
    value: 4,
  },
  { text: "Canceled", value: 5 },
  { text: "Processing", value: 6 },
  { text: "Failed", value: 7 },
  { text: "Partially Delivered", value: 8 },
];
function FilterSection({ filter, handleFilter }) {
  const { mutate: exportData } = useExportOrders(filter);
  return (
    <div>
      <SearchContainer sx={{ marginTop: "40px" }}>
        <PageHeaderTitle title="Orders" />
        <div style={{ flexGrow: 1 }}></div>
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <FilterDropdown
            display={"Status"}
            value={filter.status}
            options={statuses}
            onChange={(index) => {
              handleFilter({ status: statuses[index].value });
            }}
          />
          <DateRangeFilter display={"Date"} valueChanged={(params) => handleFilter(params)} />

          <SearchInput
            value={filter.search}
            onChange={(e) => {
              handleFilter({ search: e });
            }}
          />
          <SearchButton label="Export" onClick={() => exportData()} />
        </Box>
      </SearchContainer>
    </div>
  );
}

export default FilterSection;
