import { Box } from "@mui/material";
import { OrderInfoView } from "./OrderInfoSection";
import RecipientListView from "./RecipientSection";

export const RecipientListSection = ({ order }) => {
  return (
    <Box
      sx={(theme) => ({
        marginTop: "2.5rem",
        display: "grid",
        gap: "2.375rem",
        gridTemplateColumns: "1fr",
        [theme.breakpoints.up("lg")]: {
          gridTemplateColumns: "5fr 3fr",
        },
      })}
    >
      <RecipientListView data={order} />
      <OrderInfoView data={order} />
    </Box>
  );
};
