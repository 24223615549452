import { Box, TableHead, TableRow, TableCell } from "@mui/material";
import { SearchContainer, PageHeaderTitle, SearchInput, SearchButton, MainTable, TableBody, dateTime, formatMoney, Pagination, PageFilter, Count, useDocumentTitle } from "suregifts-component-lib";
import { WalletTransactionDto, WalletByIdData } from "./data";

type TransactionHistoryViewProps = {
  filter: PageFilter;
  handleFilter: (params) => void;
  data?: Count<WalletTransactionDto>;
  pageData?: WalletByIdData;
};
export const TransactionHistoryView = ({ filter, handleFilter, data, pageData }: TransactionHistoryViewProps) => {
  useDocumentTitle("Wallet Transaction History");
  return (
    <>
      <SearchContainer sx={{ marginTop: "40px" }}>
        <PageHeaderTitle title={`${pageData?.name} Wallet History`} />
        <div style={{ flexGrow: 1 }}></div>
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <SearchInput
            value={filter.search}
            onChange={(e) => {
              handleFilter({ search: e });
            }}
          />
          <SearchButton label="Export" />
        </Box>
      </SearchContainer>
      <MainTable sx={{ marginY: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Date Created</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Type</TableCell>

            <TableCell>Narration</TableCell>
            <TableCell>Previous Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items.map((item) => (
            <TableRow>
              <TableCell>{dateTime(item.dateCreated)}</TableCell>
              <TableCell>{formatMoney(item.amount)}</TableCell>
              <TableCell>{item.type}</TableCell>

              <TableCell>{item.narration}</TableCell>
              <TableCell>{formatMoney(item.previousBalance)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <Pagination page={filter.page} total={data?.total} onChange={(page) => handleFilter({ page })} />
    </>
  );
};
