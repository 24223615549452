import { Box, TableRow, TableCell } from "@mui/material";
import React from "react";
import { PageHeaderTitle, SelectionTable, SelectionTableHead, SelectionTableBody } from "suregifts-component-lib";
import { CartInfoData } from "../../../../services/order";
type RecipientSectionProps = {
  data?: CartInfoData;
};
function RecipientSection({ data }: RecipientSectionProps) {
  return (
    <div>
      <PageHeaderTitle title="Item Details" />
      <Box sx={{ marginTop: "1.5625rem", marginLeft: "-1.25rem", marginRight: "-1.25rem" }}>
        <SelectionTable>
          <SelectionTableHead>
            <TableRow>
              <TableCell>Store Name</TableCell>
              <TableCell>Recipient's Name</TableCell>
              <TableCell>Delivery Method</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </SelectionTableHead>
          <SelectionTableBody>
            {data?.recipients.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.title}</TableCell>
                <TableCell>
                  {item.firstName} {item.lastName}
                </TableCell>
                <TableCell>{item.deliveryMedium}</TableCell>
                <TableCell>{item.value}</TableCell>
              </TableRow>
            ))}
          </SelectionTableBody>
        </SelectionTable>
      </Box>
    </div>
  );
}

export default RecipientSection;
