import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, DialogContent, Stack, DialogActions } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DialogTitle, FormInput, FormButton, DatePicker, YupShape } from "suregifts-component-lib";
import { ReplaceVoucherModel, useReplaceVocuher } from "../../../../services/vouchers";
import moment from "moment";
import * as yup from "yup";
export const schema = yup
  .object<YupShape<ReplaceVoucherModel>>({
    voucherCode: yup.string().required(),
    newValue: yup.number().positive().required().moreThan(0),
  })
  .required();
function ReplaceVoucherModal() {
  const { voucherCode } = useParams();
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm<ReplaceVoucherModel>({
    defaultValues: {
      voucherCode,
      expiryDate: moment().add(1, "year").toISOString(),
      newValue: 0,
    },
    resolver: yupResolver(schema),
  });

  const { mutate: replace, isLoading } = useReplaceVocuher();
  const navigate = useNavigate();
  const handleClose = () => {
    if (isLoading) return;
    navigate("..");
  };
  const submitForm = (data) => {
    replace(data, {
      onSuccess: (result) => {
        toast.success("Voucher replaced successfully.");
        handleClose();
        navigate(`/merchant-backend/voucher-operations/balance?voucherCode=${result!.voucherCode}`);
      },
    });
  };
  return (
    <Dialog open maxWidth="xs" onClose={handleClose}>
      <DialogTitle title="Replace Voucher" handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={2}>
          <FormInput disabled value={watch("voucherCode")} required label="Voucher Code" {...register("voucherCode")} helperText={errors.voucherCode?.message} />
          <Controller
            render={({ field: { value, onChange } }) => (
              <DatePicker
                label="Expiry Date"
                value={value}
                onChange={(e) => {
                  onChange(e);
                }}
              />
            )}
            control={control}
            name="expiryDate"
          />

          <FormInput isNumber value={watch("newValue")} label="New Value" {...register("newValue")} helperText={errors.newValue?.message} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton disabled={!isValid} label="Send" fullWidth loading={isLoading} onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}

export default ReplaceVoucherModal;
