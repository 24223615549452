import { Stack } from "@mui/material";
import { register } from "numeral";
import React from "react";
import { Controller } from "react-hook-form";
import { FormInput, FormSelect } from "suregifts-component-lib";
import { useGetStates } from "../../../../services/merchant-dashboard/merchant";

function AddEditStoreView({ register, control, errors }) {
  const { data: states } = useGetStates();
  return (
    <>
      <Stack spacing={2}>
        <FormInput label="Name" {...register("name")} helperText={errors.name?.message} />
        <FormInput label="Address" {...register("address")} helperText={errors.address?.message} />
        <Controller control={control} name="stateId" render={({ field }) => <FormSelect options={states} label="State" helperText={errors.stateId?.message} {...field} />} />

        <FormInput label="Phone" {...register("contactPhone")} helperText={errors.contactPhone?.message} />
        <FormInput label="Email Address" {...register("email")} helperText={errors.email?.message} />
      </Stack>
    </>
  );
}

export default AddEditStoreView;
