import { Box, SxProps } from "@mui/material";
import { LoadingBox } from "suregifts-component-lib";

export type SummaryCardsContainerProps = {
  children: React.ReactNode;
  sx?: SxProps;
  isLoading?: boolean;
};
export const SummaryCardsContainer = ({ children, sx, isLoading = false }: SummaryCardsContainerProps) => {
  return (
    <LoadingBox
      loading={isLoading}
      sx={[
        (theme) => ({
          display: "grid",
          gridTemplateColumns: "1fr",
          gap: "1.25rem",
          [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "repeat(2,1fr)",
          },
          [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(4,1fr)",
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </LoadingBox>
  );
};
