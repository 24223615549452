import { DashboardIcon, GeneralIcon, RefundIcon, General3Icon, ReportIcon, General2Icon, NavLinkProps } from "suregifts-component-lib";

export const merchantBackendLinks: NavLinkProps[] = [
  {
    to: "/merchant-backend/dashboard",
    icon: DashboardIcon,
    title: "Dashboard",
  },
  {
    to: "/merchant-backend/merchants",
    icon: GeneralIcon,
    title: "Merchants",
  },
  {
    to: "/merchant-backend/stores",
    icon: General3Icon,
    title: "Stores",
  },
  {
    to: "/merchant-backend/applications",
    icon: General2Icon,
    title: "Applications",
  },
  {
    to: "/merchant-backend/voucher-operations",
    icon: ReportIcon,
    title: "Voucher Operations",
  },
  {
    to: "/merchant-backend/redemptions",
    icon: ReportIcon,
    title: "Redemptions",
  },
  {
    to: "/merchant-backend/activations",
    icon: ReportIcon,
    title: "Activations",
  },
];
