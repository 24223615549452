import { Box } from "@mui/material";
import { useMemo } from "react";
import { DateRangeFilter, FilterDropdown, MarginTop40Box, PageHeaderTitle, SearchButton, SearchContainer, SearchInput, VoucherOperationType, emptyOption } from "suregifts-component-lib";
import { useExportTransactions } from "../../../services/merchant-dashboard/voucher";
import { useGetStoreNames } from "../../../services/merchant-dashboard/merchant";
import { VoucherTransactionFilter } from "../../../shared/voucher";

type FilterSectionProps = {
  handleFilter?: (params: any) => void;
  filter: VoucherTransactionFilter;
};

export const FilterSection = ({ handleFilter = () => {}, filter }: FilterSectionProps) => {
  const { data: storeNames } = useGetStoreNames();
  const { mutate: exportReport, isLoading } = useExportTransactions();

  const stores = useMemo(() => {
    var temp;
    if (storeNames) {
      temp = storeNames?.map((item) => ({ text: item.name, value: item.id.toString() }));
    }

    return temp || [];
  }, [storeNames]);

  return (
    <SearchContainer sx={{ marginTop: "40px" }}>
      <PageHeaderTitle title={"Activations"} />
      <div style={{ flexGrow: 1 }}></div>
      <Box
        sx={{
          display: "flex",
          gap: "1.25rem",
          alignItems: "center",
        }}
      >
        <DateRangeFilter display={"Date"} valueChanged={(params) => handleFilter(params)} />
        <FilterDropdown display="Store" value={filter.storeId} options={[emptyOption, ...stores]} onChange={(index, value) => handleFilter({ storeId: value })} />

        <SearchInput
          value={filter.search}
          onChange={(e) => {
            handleFilter({ search: e });
          }}
        />
        <SearchButton
          label="Export"
          disabled={isLoading}
          onClick={() => {
            exportReport({ ...filter, typeId: 1 });
          }}
        />
      </Box>
    </SearchContainer>
  );
};
