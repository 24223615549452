import { CheckListIcon, MarginTop40Box, MerchantPotIcon, PeopleIcon, SummaryCardWithButton, SummaryCardWithIcon, useDocumentTitle } from "suregifts-component-lib";
import RecentOrderSection from "./RecentOrderSection";
import { useGetDashboardData } from "../../../services/customer-support/general";
import { SummaryCardsContainer } from "../../../components/organisms/SummaryWith4CardsBox";
import { CheckBalanceButtonView } from "../../../components/molecules/CheckBalanceButtonView";
import { Outlet, useNavigate } from "react-router-dom";

function DashboardScreen() {
  const { data, isLoading } = useGetDashboardData();
  useDocumentTitle("Dashboard");
  const navigate = useNavigate();
  return (
    <div>
      <MarginTop40Box>
        <SummaryCardsContainer>
          <SummaryCardWithIcon title={data?.totalOrders} info="Total orders" icon={MerchantPotIcon} iconColor="success.main" />
          <SummaryCardWithIcon title={data?.pendingOrders} info="pending orders" icon={CheckListIcon} iconColor="#a082f4" />
          <SummaryCardWithIcon title={data?.totalUsers} info="Total Users" icon={PeopleIcon} iconColor="warning.main" />
          <CheckBalanceButtonView onClick={() => navigate("voucher-check")} label="Check Voucher" />
        </SummaryCardsContainer>
      </MarginTop40Box>
      <RecentOrderSection />
      <Outlet />
    </div>
  );
}

export default DashboardScreen;
