import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, DialogContent, Stack, DialogActions } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DialogTitle, FormInput, FormButton, YupShape } from "suregifts-component-lib";
import { BlacklistVoucherModel, DeactivateVoucherModel, useBlacklistVoucher } from "../../../../services/vouchers";
import * as yup from "yup";
export const schema = yup
  .object<YupShape<DeactivateVoucherModel>>({
    voucherCode: yup.string().required(),
    reason: yup.string().required(),
  })
  .required();
function BlacklistVoucherModal() {
  const { voucherCode } = useParams();
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<BlacklistVoucherModel>({
    defaultValues: {
      voucherCode,
      reason: "",
    },
    resolver: yupResolver(schema),
  });

  const { mutate: redeem, isLoading } = useBlacklistVoucher();
  const navigate = useNavigate();
  const handleClose = () => {
    if (isLoading) return;
    navigate("..");
  };
  const submitForm = (data) => {
    redeem(data, {
      onSuccess: () => {
        toast.success("Voucher redeemed successfully.");
        handleClose();
      },
    });
  };
  return (
    <Dialog open maxWidth="xs" onClose={handleClose}>
      <DialogTitle title="Blacklist Voucher" handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={2}>
          <FormInput disabled required label="Voucher Code" {...register("voucherCode")} helperText={errors.voucherCode?.message} />

          <FormInput required label="Reason" {...register("reason")} helperText={errors.reason?.message} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton disabled={!isValid} label="Send" fullWidth loading={isLoading} onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}

export default BlacklistVoucherModal;
