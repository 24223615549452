import { MarginTop40Box } from "suregifts-component-lib";
import { SummaryView } from "./SummaryView";
import TableView from "./TableView";

function DashboardScreen() {
  return (
    <div>
      <SummaryView />
      <TableView />
    </div>
  );
}

export default DashboardScreen;
