import { FilterSection } from "./FilterSection";
import { TableView } from "./TableView";
import { VoucherTransactionFilter } from "../../../shared/voucher";
import { VoucherOperationType, useDocumentTitle, useFilter } from "suregifts-component-lib";

function RedemptionsScreen() {
  const [filter, setFilter] = useFilter<VoucherTransactionFilter>({ page: 1, search: "", typeId: VoucherOperationType.Redemption.toString() });
  useDocumentTitle("Voucher Reports");
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  const handlePage = (page: number) => {
    setFilter((prev) => ({ ...prev, page: page }));
  };

  return (
    <div>
      <FilterSection filter={filter} handleFilter={handleFilter} />
      <TableView filter={filter} handlePage={handlePage} />
    </div>
  );
}

export default RedemptionsScreen;
