import { TitleAndDetailsView } from "suregifts-component-lib";
import { RefundStatusLabel } from "../RefundStatusLabel";

export const RefundInfoView = ({ refund }) => {
  return (
    <>
      <TitleAndDetailsView title="Reference" details={refund?.refundNumber} />
      <TitleAndDetailsView title="Amount" details={refund?.amount} />
      <TitleAndDetailsView title="Status" details={<RefundStatusLabel status={refund!.statusId} text={refund!.status} />} />
    </>
  );
};
