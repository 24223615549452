import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { DialogTitle, FormButton, FormInput, YupShape } from "suregifts-component-lib";
import { useForm } from "react-hook-form";
import { ApplicationData, RejectApplicationModel, useRejectApplication } from "../../../../services/merchants";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useState } from "react";
export const schema = yup
  .object<YupShape<RejectApplicationModel>>({
    rejectionReason: yup.string().required(),
  })
  .required();
function RejectApplicationModal() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [application] = useState(() => {
    const data = sessionStorage.getItem("APPLICATION_DATA");
    if (!data) return {} as ApplicationData;
    return JSON.parse(data) as ApplicationData;
  });
  const { mutate: reject, isLoading } = useRejectApplication(application.id);
  const handleClose = () => {
    navigate("..");
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<RejectApplicationModel>({
    resolver: yupResolver(schema),
  });
  const submitForm = (model) => {
    reject(model, {
      onSuccess: () => {
        toast.success("Application rejected successfully.");
        queryClient.invalidateQueries("applications");
        handleClose();
      },
    });
  };
  return (
    <Dialog maxWidth="xs" open>
      <DialogTitle title={"Rejection Reason"} handleClose={handleClose} />
      <DialogContent>
        <FormInput multiline size="medium" {...register("rejectionReason")} label="Enter Reason" />
      </DialogContent>
      <DialogActions>
        <FormButton loading={isLoading} onClick={handleSubmit(submitForm)} fullWidth label="Confirm Rejection" color="primary" sx={{ color: "error.main" }} />
      </DialogActions>
    </Dialog>
  );
}

export default RejectApplicationModal;
