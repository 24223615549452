import { Dialog, DialogContent } from "@mui/material";
import { Stack } from "@mui/system";
import { DialogTitle } from "suregifts-component-lib";
import { ChangePasswordSection } from "./ChangePasswordSection";
import { UpdateProfileSection } from "./UpdateProfileSection";

function PreferenceModal({ handleClose, open }) {
  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle title="Update Profile" handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={"2.5rem"}>
          <UpdateProfileSection />
          <ChangePasswordSection />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default PreferenceModal;
