import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useGetOrderById } from "../../../../services/order";
import { useOrderScreenContext } from "./context/ScreenContext";
import { useDocumentTitle } from "suregifts-component-lib";
import { BackLinkView } from "../../../../components/molecules/BackLinkView";
import { GiftInfoSection } from "./GiftInfoSection";
import { RecipientListSection } from "./RecipientListSection";

function OrderDetailsScreen() {
  const { orderId } = useParams();
  const { data: order, isLoading, refetch } = useGetOrderById(orderId);
  useDocumentTitle("Orders");
  const { dispatch, state } = useOrderScreenContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (state.pageType === "Main") {
      refetch();
    }
  }, [state.pageType]);
  if (isLoading) return <div>loading...</div>;

  return (
    <div style={{ marginTop: "2.5rem" }}>
      <BackLinkView
        label={`Order ${order?.orderNumber}`}
        onClick={() => {
          if (state.pageType === "Main") {
            navigate("/orders");
          } else {
            dispatch({ pageType: "Main" });
          }
        }}
      />
      {state.pageType === "Main" && <RecipientListSection order={order} />}
      {(state.pageType === "GiftCard" || state.pageType === "Edit") && <GiftInfoSection order={order} />}
      <Outlet />
    </div>
  );
}

export default OrderDetailsScreen;
