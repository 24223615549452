import { Box } from "@mui/material";
import { GiftCardImageView } from "./GiftCardImageView";
import { GiftCardInfoView } from "./OrderItemDetailsSection";

export const GiftInfoSection = ({ order }) => {
  return (
    <Box
      sx={(theme) => ({
        marginTop: "2.5rem",
        display: "grid",
        gap: "2.375rem",
        gridTemplateColumns: "1fr",
        [theme.breakpoints.up("lg")]: {
          gridTemplateColumns: "5fr 3fr",
        },
      })}
    >
      <GiftCardInfoView order={order} />
      <GiftCardImageView />
    </Box>
  );
};
