import { Dialog, DialogContent, Stack, DialogActions, Typography, OutlinedInput } from "@mui/material";
import { toast } from "react-toastify";
import { useConfirmRefund, useRejectRefund } from "../../../../services/refunds";
import { FormSelect, FormButton, DialogTitle } from "suregifts-component-lib";

import { RefundInfoView } from "./RefundInfoView";
import { useRefundModalState } from "./useRefundModalState";

function ApproveRefundModal() {
  const { mutate: approve, isLoading } = useConfirmRefund();
  const { handleClose, model, refund, setModel } = useRefundModalState();
  return (
    <div>
      <Dialog maxWidth={"xs"} open={true} sx={{}}>
        <DialogTitle title={"Details"} handleClose={handleClose} />
        <DialogContent>
          <Stack spacing={4}>
            <RefundInfoView refund={refund} />
            <div>
              <FormSelect
                label={"Refund Method"}
                value={model.refundMethod}
                options={[
                  { id: "1", name: "Cash Wallet" },
                  { id: "2", name: "Paypal" },
                  { id: "3", name: "Bank Transfer" },
                  { id: "7", name: "Crypto" },
                ]}
                onChange={(e) => {
                  setModel((prev) => ({ ...prev, refundMethod: e.target.value }));
                }}
              />
            </div>
            <div>
              <Typography>Note: </Typography>
              <OutlinedInput multiline rows={2} fullWidth sx={{ marginTop: "0.625rem", borderRadius: "0.75rem" }} value={model.note} onChange={(e) => setModel((prev) => ({ ...prev, note: e.target.value }))}></OutlinedInput>
            </div>
          </Stack>
          <DialogActions>
            <FormButton
              color="success"
              fullWidth
              loading={isLoading}
              onClick={() => {
                approve(model, {
                  onSuccess: () => {
                    toast.success("Refund approved successfully.");
                    handleClose();
                  },
                });
              }}
              label="Confirm"
            ></FormButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ApproveRefundModal;
