import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link } from "@mui/material";

type BackLinkViewProps = {
  label: string;
  onClick?: () => void;
};
export const BackLinkView = ({ label: label, onClick }: BackLinkViewProps) => {
  return (
    <Link href="#" onClick={onClick} style={{ color: "inherit", textDecoration: "none", display: "flex", gap: "18px", fontSize: "18px", fontWeight: 700 }}>
      <ChevronLeftIcon sx={{ padding: 0, margin: 0 }} />
      {label}
    </Link>
  );
};
