import { Box } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SearchContainer, PageHeaderTitle, FilterDropdown, SearchButton, SearchInput } from "suregifts-component-lib";
function FilterSection({}) {
  const [searchParams] = useSearchParams();
  const { merchantId } = useParams();
  const navigate = useNavigate();
  return (
    <div>
      <SearchContainer sx={{ marginTop: "40px" }}>
        <PageHeaderTitle title={`${searchParams.get("store") || ""} Personnels`} />
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <SearchButton
            sx={{ py: "0.125rem" }}
            label="Create Personnel"
            onClick={() => {
              navigate(`add`);
            }}
          />
        </Box>
      </SearchContainer>
    </div>
  );
}

export default FilterSection;
