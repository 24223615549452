import { Box, Typography } from "@mui/material";

export type AmountViewProps = {
  amount: string;
};
export const AmountView = (props: AmountViewProps) => {
  return (
    <Box
      sx={{
        textAlign: "right",
        color: "text.secondary",
        fontSize: "0.875rem",
      }}
    >
      <Typography>Amount</Typography>
      <Typography sx={{ color: "success.main", fontWeight: 500, fontSize: "1.125rem" }}>{props.amount}</Typography>
    </Box>
  );
};

export default AmountView;
