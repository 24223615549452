import { useState } from "react";
import { LoadingBox } from "suregifts-component-lib";

import { useParams } from "react-router-dom";

import { TransactionHistoryView } from "../../../../components/organisms/merchant-wallets/TransactionHistoryView";
import { BalanceCard } from "../../../../components/organisms/merchant-wallets/BalanceCard";
import { CompanyCard } from "../../../../components/organisms/merchant-wallets/CompanyCard";
import { WalletTransactionFilter } from "../../../../components/organisms/merchant-wallets/data";
import { useCreditStoreWallet, useDebitStoreWallet, useGetStoreWalletById, useGetStoreWalletTransactions } from "../../../../services/merchant-wallet";

function StoreWalletHistoryScreen() {
  const { storeId } = useParams();
  const [filter, setFilter] = useState({ page: 1, search: "" } as WalletTransactionFilter);
  const { data: history, isLoading } = useGetStoreWalletTransactions(storeId, filter);
  const { data: store } = useGetStoreWalletById(storeId);
  const { mutate: credit } = useCreditStoreWallet(storeId);
  const { mutate: debit } = useDebitStoreWallet(storeId);

  const handleFilter = (params) => setFilter((prev) => ({ ...prev, ...params }));
  if (!store) return <LoadingBox loading loadingPosition="center" />;
  return (
    <div>
      <LoadingBox
        sx={(theme) => ({
          display: "grid",
          gap: "1.875rem",
          [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "3fr 5fr",
          },
        })}
      >
        <CompanyCard store={store} />
        <BalanceCard store={store} credit={credit} debit={debit} />
      </LoadingBox>
      <TransactionHistoryView pageData={store} filter={filter} handleFilter={handleFilter} data={history} />
    </div>
  );
}

export default StoreWalletHistoryScreen;
