import { Box, TableRow, TableCell } from "@mui/material";
import { VoucherTransactionData } from "../../shared/voucher";
import { PageTitleWithLink, LoadingBox, SelectionTable, SelectionTableHead, SelectionTableBody, dateTime } from "suregifts-component-lib";
type RecentActivationViewProps = {
  data?: VoucherTransactionData[];
  isloading?: boolean;
  to?: string;
};
const RecentActivationView = ({ data, isloading, to }: RecentActivationViewProps) => {
  return (
    <Box>
      <PageTitleWithLink title="Recent Activations" to={to} />
      <LoadingBox loading={isloading} sx={{ marginTop: "1.5625rem", marginLeft: "-1.25rem", marginRight: "-1.25rem" }}>
        <SelectionTable>
          <SelectionTableHead>
            <TableRow>
              <TableCell>Serial No</TableCell>
              <TableCell>Store</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Time</TableCell>
            </TableRow>
          </SelectionTableHead>
          <SelectionTableBody>
            {data?.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.serialNumber}</TableCell>
                <TableCell>{item.pointOfUsage}</TableCell>
                <TableCell>{item.amount}</TableCell>
                <TableCell>{dateTime(item.dateTime)}</TableCell>
              </TableRow>
            ))}
          </SelectionTableBody>
        </SelectionTable>
      </LoadingBox>
    </Box>
  );
};

export default RecentActivationView;
