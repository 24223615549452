import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import AmountView, { AmountViewProps } from "./AmountView";
import RedemptionInfoView, { RedemptionInfoViewProps } from "./RedemptionInfoView";

export type RecentRedemptionsCardProps = {
  dateTime: string;
} & RedemptionInfoViewProps &
  AmountViewProps;

export function RecentRedemptionsCard(props: RecentRedemptionsCardProps) {
  return (
    <Box>
      <Typography sx={{ fontSize: "0.625rem", fontWeight: 500, textTransform: "uppercase" }}>{props.dateTime}</Typography>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        sx={{
          p: "1.5625rem",
          minWidth: "25rem",
          bgcolor: "primary.main",
          mt: "0.9375rem",
          borderRadius: "1.25rem",
          "&:hover": {
            boxShadow: 3,
          },
        }}
      >
        <RedemptionInfoView {...props} />
        <AmountView amount={props.amount} />
      </Box>
    </Box>
  );
}

export default RecentRedemptionsCard;
