import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { useOrderScreenContext } from "../context/ScreenContext";
import { Stack } from "@mui/system";
import { DeliveryMediumType, DialogTitle, FormButton, FormInput, FormSelect } from "suregifts-component-lib";
import { Controller, useForm } from "react-hook-form";
import { EditOrderRecipientModel, useEditCard, useGetDeliveryStates } from "../../../../../services/order";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

function EditRecipientModal() {
  const { orderId } = useParams();
  const { dispatch, state } = useOrderScreenContext();
  const handleClose = () => {
    dispatch({ pageType: "GiftCard", recipient: state.recipient! });
  };
  const { data: states } = useGetDeliveryStates();
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<EditOrderRecipientModel>({
    defaultValues: {
      ...state.recipient,
      deliveryMedium: state.recipient?.deliveryMediumId?.toString() || "",
      stateId: state.recipient?.stateId?.toString() || "",
    },
  });
  const { mutate: editCard, isLoading } = useEditCard(orderId);
  const submitForm = (data) => {
    editCard(data, {
      onSuccess: () => {
        toast.success("Gift item edited successfully.");
        dispatch({ pageType: "Main" });
      },
    });
  };
  return (
    <Dialog open={state.pageType === "Edit"}>
      <DialogTitle title="Edit Card Details" handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={3}>
          <Controller
            control={control}
            name="deliveryMedium"
            render={({ field }) => (
              <FormSelect
                {...field}
                label={"Delivery Type"}
                options={[
                  { name: "Email", id: "1" },
                  { name: "Home Delivery", id: "2" },
                ]}
              />
            )}
          />
          {watch("deliveryMedium") === DeliveryMediumType.Email.toString() && <FormInput {...register("email")} label="Recipient's Emails" />}
          {watch("deliveryMedium") !== DeliveryMediumType.Pdf.toString() && (
            <>
              <Stack direction={"row"} spacing={2}>
                <FormInput {...register("firstName")} label="First Name" />
                <FormInput {...register("lastName")} label="Last Name" />
              </Stack>
              <FormInput {...register("phoneNumber")} label="Recipient's Phone Number" />
            </>
          )}

          {watch("deliveryMedium") === DeliveryMediumType.HomeDelivery.toString() && (
            <>
              <FormInput {...register("city")} label="City" />
              <Controller
                control={control}
                name="stateId"
                render={({ field }) => (
                  <FormSelect
                    helperText={errors.stateId?.message}
                    {...field}
                    label={"State"}
                    options={states?.map((item) => ({
                      id: item.id.toString(),
                      name: item.state,
                    }))}
                  />
                )}
              />
              <FormInput {...register("address")} label="Address" />
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton fullWidth label="Update Details" loading={isLoading} onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}

export default EditRecipientModal;
