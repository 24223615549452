import { TableHead, TableRow, TableCell, Box } from "@mui/material";
import moment from "moment";
import { ApprovalStatusLabel, MainTable, Pagination, TableBody, dateTime } from "suregifts-component-lib";
import { PaymentData, PaymentFilter } from "../../../shared/transaction";

import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PaymentDetailsModal from "./PaymentDetailsModal";
import { fixedCellStyle } from "../../../helpers/cssHelper";
import { TopBottomLabel } from "../../../components/molecules/TopBottomLabel";
import { useGetPayments } from "../../../services/transaction";

type TableViewProps = {
  filter: PaymentFilter;
  handlerFilter: (params: any) => void;
};

function TableView({ filter, handlerFilter }: TableViewProps) {
  const { data, isLoading } = useGetPayments(filter);
  const [selectedPayment, setSelectedPayment] = useState<PaymentData | undefined>();
  return (
    <div>
      <MainTable loading={isLoading} sx={{ marginTop: "2.5rem" }}>
        <TableHead>
          <TableRow>
            <TableCell>Reference</TableCell>
            <TableCell>Sender's Name</TableCell>
            <TableCell>Payment Type</TableCell>
            <TableCell>Payment Method</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Date/Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items.map((item) => (
            <TableRow
              key={item.paymentNumber}
              onClick={() => {
                setSelectedPayment(item);
              }}
              sx={{ cursor: "pointer" }}
            >
              <TableCell>{item.invoiceNumber}</TableCell>
              <TableCell>
                <TopBottomLabel top={`${item.firstName} ${item.lastName}`} bottom={item.email} />
              </TableCell>
              <TableCell>{item.paymentType}</TableCell>
              <TableCell>{item.paymentMethod}</TableCell>
              <TableCell>
                <ApprovalStatusLabel text={item.approvalStatus} status={item.approvalStatusId} />
              </TableCell>
              <TableCell style={fixedCellStyle}>{item.amount}</TableCell>

              <TableCell>{dateTime(item.dateCreated)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination page={filter.page} total={data?.total} onChange={(page) => handlerFilter({ page })} />
      </div>
      {selectedPayment && <PaymentDetailsModal payment={selectedPayment} handleClose={() => setSelectedPayment(undefined)} />}
    </div>
  );
}

export default TableView;
