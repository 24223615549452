import { Outlet } from "react-router-dom";

import { FilterSection } from "./FilterSection";
import TableView from "./TableView";
import { useDocumentTitle, useFilter } from "suregifts-component-lib";
import { BulkVoucherFilter } from "../../../services/vouchers";

function BulkVoucherScreen() {
  var [filter, setFilter] = useFilter<BulkVoucherFilter>({ page: 1, search: "" });
  useDocumentTitle("Bulk Vouchers");
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  return (
    <div>
      <FilterSection filter={filter} handleFilter={handleFilter} />
      <TableView filter={filter} handleFilter={handleFilter} />
      <Outlet />
    </div>
  );
}

export default BulkVoucherScreen;
