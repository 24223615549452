import React, { createContext, useContext } from "react";
import { MerchantDto, useGetMerchantById } from "../../services/merchant-dashboard/merchant";
import { AdminRootScreen } from "suregifts-component-lib";
import { RequireAuth } from "../../routes/RequireAuth";
import NavBar from "../root/nav/NavBar";
type MerchantDashboardContextProps = {
  merchant?: MerchantDto;
};
const MerchantDashboardContext = createContext({} as MerchantDashboardContextProps);

export const MerchantDashboardContextProvider = () => {
  const { data: merchant } = useGetMerchantById();

  return (
    <MerchantDashboardContext.Provider
      value={{
        merchant,
      }}
    >
      <RequireAuth>
        <AdminRootScreen nav={<NavBar />} showModeView={false} />
      </RequireAuth>
    </MerchantDashboardContext.Provider>
  );
};

export const useMerchantDashboardContext = () => useContext(MerchantDashboardContext);
