import { Box } from "@mui/material";
import { LoadingBox, SummaryCardWithButton } from "suregifts-component-lib";

import { CheckBalanceButtonView } from "../../../components/molecules/CheckBalanceButtonView";
import { useGetVoucherSummary } from "../../../services/merchant-dashboard/general";
import { useNavigate } from "react-router-dom";

export const SummarySection = () => {
  const { data, isFetching } = useGetVoucherSummary();
  const navigate = useNavigate();
  return (
    <LoadingBox
      loading={isFetching}
      sx={(theme) => ({
        display: "grid",
        gridTemplateColumns: "1fr",
        gap: "1.25rem",
        marginTop: "40px",
        [theme.breakpoints.up("lg")]: {
          gridTemplateColumns: "repeat(3,1fr)",
        },
      })}
    >
      <SummaryCardWithButton info="Activated Voucher" title={data?.activations} buttonTitle="Activate" onClick={() => navigate("activate")} />
      <SummaryCardWithButton info="Redeemed Voucher" title={data?.redemptions} buttonTitle="Redeem" onClick={() => navigate("redeem")} />
      <CheckBalanceButtonView onClick={() => navigate("balance")} />
    </LoadingBox>
  );
};
