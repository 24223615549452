import { TitleAndDetailsView, formatMoney, dateOnly, dateTime } from "suregifts-component-lib";
import { VoucherStatusLabel } from "../../../components/molecules/VoucherStatusLabel";

export const ReverseTransactionInfo = ({ data }) => {
  return (
    <div style={{ display: "grid", rowGap: "20px", columnGap: "40px" }}>
      <TitleAndDetailsView title="Voucher Code" details={data?.unmaskCode} />
      <TitleAndDetailsView title="Serial Number" details={data.serialNumber} />
      <TitleAndDetailsView title="Voucher Status" details={<VoucherStatusLabel status={data.statusId} text={data.status} />} />
      <TitleAndDetailsView title="Gift Type" details={data.giftCardType} />
      <TitleAndDetailsView title="Value" details={formatMoney(data.value)} />
      <TitleAndDetailsView title="Balance" details={formatMoney(data.balance)} />
      <TitleAndDetailsView title="Expiry Date" details={dateOnly(data.expiryDate)} />
      <TitleAndDetailsView title="Amount Used" details={data.amount} />
      <TitleAndDetailsView title="Narration" details={data.narration} />
      <TitleAndDetailsView title="Merchant" details={data.merchantName} />
      <TitleAndDetailsView title="Point of Usage" details={data.pointOfUsage} />
      <TitleAndDetailsView title="Channel" details={data.usageMedium} />
      <TitleAndDetailsView title="Date" details={dateTime(data.dateTime)} />
    </div>
  );
};
