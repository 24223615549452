import { Box, Typography } from "@mui/material";
import { WalletByIdData } from "./data";

type CompanyCardProps = {
  store: WalletByIdData;
};
export const CompanyCard = ({ store }: CompanyCardProps) => {
  return (
    <div
      style={{
        borderRadius: "1.5625rem",
        background: "conic-gradient(from 151.11deg at 50% 50%, #CC9AC7 0deg, #A082F4 13.37deg, #A6E8F7 106.88deg, #FFF5DD 204.37deg, #FFF4D9 305.62deg, #CC9AC7 360deg)",
        height: "14.0625rem",
        backdropFilter: "blur(1.375rem)",
      }}
    >
      <Box
        sx={{
          height: "calc(100% - 1rem)",
          borderRadius: "1.25rem",
          border: "0.1875rem solid",
          borderColor: "primary.dark",
          margin: "0.5rem",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "space-between",
          padding: "1.875rem",
        }}
      >
        <Box component={"img"} src="/assets/images/logo-dark.png"></Box>
        <Box component={"img"} src="/assets/images/star.png" sx={{ alignSelf: "center" }}></Box>
        <Typography sx={{ color: "primary.main", fontWeight: 700, fontSize: "1.5rem" }}>{store.name}</Typography>
      </Box>
    </div>
  );
};
