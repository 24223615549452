import { Dialog, DialogActions, DialogContent, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { DialogTitle, FormButton, FormInput, YupShape } from "suregifts-component-lib";
import { AssignEmailModel, useAssignEmail } from "../../../../services/vouchers";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup
  .object<YupShape<AssignEmailModel>>({
    email: yup.string().required().email(),
  })
  .required();
function AssignEmailModal() {
  const { voucherCode } = useParams();
  const { mutate: assignEmail, isLoading } = useAssignEmail();
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("..");
  };
  const {
    formState: { isValid },
    handleSubmit,
    register,
  } = useForm<AssignEmailModel>({
    defaultValues: {
      voucherCode: voucherCode,
    },
    resolver: yupResolver(schema),
  });
  const onSubmit = (data: AssignEmailModel) => {
    assignEmail(data, {
      onSuccess: () => {
        toast.success(`${data.voucherCode} was assigned to ${data.email} successfully.`);
        handleClose();
      },
    });
  };
  return (
    <Dialog open maxWidth="xs" onClose={handleClose}>
      <DialogTitle title="Assign  Email" handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={2}>
          <FormInput label="Voucher Code" disabled value={voucherCode} />
          <FormInput {...register("email")} label="Email" />
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton loading={isLoading} disabled={!isValid} title="Send" fullWidth onClick={handleSubmit(onSubmit)} />
      </DialogActions>
    </Dialog>
  );
}

export default AssignEmailModal;
