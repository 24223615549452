import { TableHead, TableRow, TableCell } from "@mui/material";
import moment from "moment";
import { PageTitleWithLink, MainTable, TransactionStatusLabel, ApprovalStatusLabel, TableBody, dateOnly } from "suregifts-component-lib";
import { useGetRecentPayments } from "../../../services/transaction";

function TableView() {
  const { data, isFetching } = useGetRecentPayments();
  return (
    <div style={{ marginTop: "40px" }}>
      <PageTitleWithLink title="Recent Payments" to="/account/payments" />
      <MainTable sx={{ marginTop: "2.5rem" }} loading={isFetching}>
        <TableHead>
          <TableRow>
            <TableCell>Reference</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Payment Type</TableCell>
            <TableCell>Payment Method</TableCell>
            <TableCell>Approval Status</TableCell>
            <TableCell>Date/Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item) => (
            <TableRow key={item.paymentNumber}>
              <TableCell>{item.paymentNumber}</TableCell>
              <TableCell>{item.amount}</TableCell>
              <TableCell>{item.paymentType}</TableCell>
              <TableCell>{item.paymentMethod}</TableCell>

              <TableCell>
                <ApprovalStatusLabel text={item.approvalStatus} status={item.approvalStatusId} />
              </TableCell>
              <TableCell>{dateOnly(item.dateCreated)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
    </div>
  );
}

export default TableView;
