import { DialogActions, Stack } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FormSelect, FormButton, FormInput, DatePicker, DialogTitle, YupShape } from "suregifts-component-lib";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import moment from "moment";
import { BulkVoucherModel, useGenerateInStore, useGenerateSales } from "../../../../services/vouchers";
import { useGetMerchantNames } from "../../../../services/merchants";

const voucherTypes = [
  { name: "Sales", id: "Sales" },
  { name: "In-Store", id: "Instore" },
];
const statuses = [
  { id: "true", name: "Activated" },
  { id: "false", name: "Not Activated" },
];
export const schema = yup
  .object<YupShape<BulkVoucherModel>>({
    requesterEmail: yup.string().email().required(),
    quantity: yup.number().required("Quantity is required."),
  })
  .required();

function GenerateVoucherModal() {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm<BulkVoucherModel>({
    defaultValues: { merchantId: "", typeId: "Sales", activated: "true", expiryDate: moment().add(1, "year").toISOString() },
    resolver: yupResolver(schema),
  });
  const typeId = watch("typeId");
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: merchants } = useGetMerchantNames();
  const { mutate: generateInStore, isLoading: loadingInstore } = useGenerateInStore();
  const { mutate: generateSales, isLoading: loadingSales } = useGenerateSales();
  const handleClose = () => {
    queryClient.invalidateQueries("bulkvouchers");
    navigate("..");
  };

  const onSubmit = (data) => {
    if (typeId === "Sales") {
      generateSales(data, {
        onSuccess: () => {
          toast.success("Voucher Generated successfully.");
          handleClose();
        },
      });
    } else {
      generateInStore(data, {
        onSuccess: () => {
          toast.success("Voucher Generated successfully.");
          handleClose();
        },
      });
    }
  };

  return (
    <div>
      <Dialog maxWidth={"xs"} open sx={{}} scroll="body">
        <DialogTitle title="Generate New Voucher" handleClose={handleClose} />
        <DialogContent>
          <Stack spacing={2}>
            <FormInput helperText={errors.quantity?.message} label="Quantity" {...register("quantity")} />
            {typeId === "Sales" && <FormInput helperText={errors.voucherValue?.message} label="Value" {...register("voucherValue")} />}
            <Controller control={control} name="typeId" render={({ field }) => <FormSelect helperText={errors.typeId?.message} {...field} label={"Voucher Type"} options={voucherTypes} />} />

            <Controller control={control} name="merchantId" render={({ field }) => <FormSelect helperText={errors.typeId?.message} {...field} label={"Merchant"} options={merchants?.map((item) => ({ id: item.id, name: item.name }))} />} />
            <FormInput helperText={errors.requesterEmail?.message} label="Requester Email" {...register("requesterEmail")} />

            {typeId === "Sales" && (
              <>
                <Controller control={control} name="expiryDate" render={({ field: { value, onChange } }) => <DatePicker value={value} label={"Expiry Date"} onChange={(e) => onChange(e)} />} />
                <Controller control={control} name="activated" render={({ field }) => <FormSelect helperText={errors.activated?.message} {...field} label={"Status"} options={statuses} />} />
              </>
            )}
          </Stack>
          <DialogActions>
            <FormButton disabled={!isValid} loading={loadingInstore || loadingSales} fullWidth onClick={handleSubmit(onSubmit)} type="submit" label="Generate Voucher"></FormButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default GenerateVoucherModal;
