import { Box } from "@mui/material";
import { SummaryCardWithButton } from "suregifts-component-lib";

import { CheckBalanceButtonView } from "../../../components/molecules/CheckBalanceButtonView";
import { useGetVoucherSummary } from "../../../services/merchant-backend/general";
import { useNavigate } from "react-router-dom";

export const SummarySection = () => {
  const { data } = useGetVoucherSummary();
  const navigate = useNavigate();
  return (
    <Box
      sx={(theme) => ({
        display: "grid",
        marginTop: "40px",
        gridTemplateColumns: "1fr",
        gap: "1.25rem",
        [theme.breakpoints.up("sm")]: {
          gridTemplateColumns: "repeat(3,1fr)",
        },
      })}
    >
      <SummaryCardWithButton info="Activated Voucher" title={data?.activations} buttonTitle="Activate" onClick={() => navigate("activate")} />

      <SummaryCardWithButton info="Redeemed Voucher" title={data?.redemptions} buttonTitle="Redeem" onClick={() => navigate("Redeem")} />
      <CheckBalanceButtonView onClick={() => navigate("balance")} />
    </Box>
  );
};
