import { createContext, useContext, useReducer } from "react";
import OrderDetailsScreen from "../OrderDetailsScreen";
import { OrderRecipientData } from "../../../../../services/order";
export type OrderScreenContextValueProps = {
  state: ScreenState;
  dispatch: React.Dispatch<ActionProps>;
};
const OrderScreenContext = createContext({} as OrderScreenContextValueProps);

export const useOrderScreenContext = () => {
  return useContext(OrderScreenContext);
};

type ScreenState = {
  pageType: "Main" | "Edit" | "GiftCard";
  recipient?: OrderRecipientData;
};

type BaseActionProps = {
  pageType: "Main" | "Edit";
};

type EditActionProps = {
  pageType: "GiftCard";
  recipient: OrderRecipientData;
};
type ActionProps = BaseActionProps | EditActionProps;

const initialState: ScreenState = { pageType: "Main" };

const reducer = (state: ScreenState, action: ActionProps): ScreenState => {
  switch (action.pageType) {
    case "Edit":
      return { ...state, pageType: "Edit" };
    case "GiftCard":
      return { pageType: "GiftCard", recipient: action.recipient };
    default:
      return { pageType: "Main" };
  }
};

export const OrderScreenContextProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <OrderScreenContext.Provider value={{ state, dispatch }}>
      <OrderDetailsScreen />
    </OrderScreenContext.Provider>
  );
};
