import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { DialogTitle, FormButton, FormInput, FormSelect, StorePersonnelType } from "suregifts-component-lib";
import { useCreatePersonnel, useGetRoles, useGetStoreNames } from "../../../services/merchants";
import { Controller, useForm } from "react-hook-form";
import { Stack } from "@mui/system";
import { toast } from "react-toastify";
import { AddPersonnelModel } from "../../../shared/merchants";
import { yupResolver } from "@hookform/resolvers/yup";
import { personnelSchema } from "../stores/modals/schema";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";

function AddPersonnelModal() {
  const { merchantId } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    watch,
    register,
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<AddPersonnelModel>({
    defaultValues: { merchantId, stores: [], roleId: "" },
    resolver: yupResolver(personnelSchema),
  });
  const { data: stores } = useGetStoreNames(watch("merchantId"));
  const { data: roles } = useGetRoles();
  const handleClose = () => {
    if (isLoading) return;
    queryClient.invalidateQueries("personnels");
    navigate("..");
  };
  const { mutate: createPersonel, isLoading } = useCreatePersonnel(merchantId);
  const submitForm = (data) => {
    createPersonel(data, {
      onSuccess: () => {
        toast.success("Store personnel created successfully.");
        handleClose();
      },
    });
  };

  return (
    <Dialog maxWidth={"xs"} open onClose={handleClose}>
      <DialogTitle title="Create Store Personnel" handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={2}>
          <FormInput required label="Email" {...register("email")} helperText={errors.email?.message} />
          <FormInput required label="First Name" {...register("firstName")} helperText={errors.firstName?.message} />
          <FormInput required label="Last Name" {...register("lastName")} helperText={errors.lastName?.message} />
          <Controller control={control} name="roleId" render={({ field }) => <FormSelect required options={roles} label="Role" helperText={errors.roleId?.message} {...field} />} />

          {watch("roleId") === StorePersonnelType.Personnel.toString() && (
            <Controller control={control} name="stores" render={({ field }) => <FormSelect required multiple options={stores} label="Stores" helperText={errors.stores?.message} {...field} />} />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton disabled={!isValid} label="Create" fullWidth loading={isLoading} onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}

export default AddPersonnelModal;
