import { useEffect, useState } from "react";

import { Outlet, useParams } from "react-router-dom";
import { TableCell, TableRow } from "@mui/material";
import moment from "moment";
import { ColoredAmountLabel, Pagination, SelectionTable, SelectionTableBody, SelectionTableHead, dateOnly, useFilter } from "suregifts-component-lib";
import { TransactionFilter, useGetWalletHistory } from "../../../../services/transaction";

function UserTransactionsScreen() {
  const { userId } = useParams();
  const [filter, setFilter] = useFilter<TransactionFilter>({ page: 1 });
  const { data, isFetching, refetch } = useGetWalletHistory({ ...filter, userId });

  return (
    <div style={{ marginTop: "1.25rem", marginLeft: "-1.25rem", marginRight: "-1.25rem" }}>
      <SelectionTable loading={isFetching}>
        <SelectionTableHead>
          <TableRow>
            <TableCell>Transaction Id</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Amount</TableCell>
          </TableRow>
        </SelectionTableHead>
        <SelectionTableBody>
          {data?.items.map((item) => (
            <TableRow key={item.transactionId}>
              <TableCell>{item.transactionId}</TableCell>
              <TableCell>{dateOnly(item.dateCreated)}</TableCell>
              <TableCell>{item.narration}</TableCell>
              <TableCell sx={{ color: item.isCredit ? "success.main" : "error.main" }}>{item.amount}</TableCell>
            </TableRow>
          ))}
        </SelectionTableBody>
      </SelectionTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination
          size={filter.size}
          page={filter.page}
          total={data?.total}
          onChange={(page) => {
            setFilter((prev) => ({ ...prev, page }));
          }}
        />
      </div>
      <Outlet />
    </div>
  );
}

export default UserTransactionsScreen;
