import { useMutation, useQuery } from "react-query";
import { useAxios, ServerError, Count, DeliveryMediumType, DeliveryStatusType, OrderStatusType, PageFilter } from "suregifts-component-lib";
import { RecentOrderDto } from "./shared";

export const useGetRecentOrders = () => {
  const axios = useAxios();
  return useQuery<RecentOrderDto[], ServerError>({
    queryKey: ["recent-orders"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get("/orders/api/recent", {}),
  });
};

export const useGetAbandonedCart = (filter: PageFilter) => {
  const axios = useAxios();
  return useQuery<Count<CartData>, ServerError>({
    queryKey: ["carts", filter],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    queryFn: () =>
      axios.get("/orders/api/carts", {
        params: filter,
      }),
  });
};
export const useSendCartReminder = (cartId) => {
  const axios = useAxios();
  return useMutation<unknown, ServerError>(() => {
    return axios.post(`/orders/api/carts/${cartId}/send-reminder`);
  });
};
export const useCompleOrder = (cartId) => {
  const axios = useAxios();
  return useMutation<unknown, ServerError>(() => {
    return axios.post(`/orders/api/carts/${cartId}/complete`);
  });
};
export const useGetAbandonedInfoCart = (cartId: any) => {
  const axios = useAxios();
  return useQuery<CartInfoData, ServerError>({
    queryKey: ["carts-by-Id", { cartId }],
    queryFn: () => axios.get(`/orders/api/carts/${cartId}`, {}),
  });
};

export const useGetDeliveryStates = () => {
  const axios = useAxios();
  return useQuery<DeliveryStateName[], ServerError>({
    queryKey: ["delivery-states"],
    keepPreviousData: true,
    queryFn: () => axios.get("/orders/api/delivery-states", {}),
  });
};
export const useGetOrders = (filter: OrderFilter) => {
  const axios = useAxios();
  return useQuery<Count<OrderData>, ServerError>({
    queryKey: ["orders", filter],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    queryFn: () =>
      axios.get("/orders/api", {
        params: filter,
      }),
  });
};
export const useExportOrders = (filter: OrderFilter) => {
  var axios = useAxios();
  return useMutation(() => {
    return axios.get(`/orders/api/export`, {
      params: filter,
    });
  });
};
export const useGetOrderById = (orderId: any) => {
  const axios = useAxios();
  return useQuery<OrderInfoData, ServerError>({
    queryKey: ["orders-byId", { orderId }],
    keepPreviousData: true,
    queryFn: () => axios.get(`/orders/api/${orderId}`, {}),
  });
};
export const useCancelOrder = (orderId) => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, CancelOrderModel>((model) => {
    return axios.post(`/orders/api/${orderId}/cancel`, model);
  });
};
export const useEditCard = (orderId) => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, EditOrderRecipientModel>((model) => {
    return axios.post(`/orders/api/${orderId}/cards/${model.id}`, model);
  });
};
export const useChangeCardStatus = (orderId, cardId) => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, unknown>(() => {
    return axios.post(`/orders/api/${orderId}/cards/${cardId}/status`);
  });
};
export const useResendCard = (orderId, cardId) => {
  const axios = useAxios();
  return useMutation<unknown, ServerError>(() => {
    return axios.post(`/orders/api/${orderId}/cards/${cardId}/resend`);
  });
};
export interface DeliveryStateName {
  state: string;
  id: number;
  amount: number;
}

export interface CartData {
  id: string;
  cartNumber: string;
  dateCreated: Date;
  SendEmail: string;
  senderName: string;
  SenderPhone: string;
  quantity: string;
  hasCoupon: boolean;
  subTotal: string;
  shipping: string;
  discount: string;
  amountDue: string;
  coupon: string;
  printingFee: string;
}
export interface CartInfoData extends CartData {
  recipients: CartRecipient[];
  lastReminderTime: Date;
}
export interface CartRecipient {
  deliveryMediumId: DeliveryMediumType;
  deliveryMedium: string;
  address: string;
  city: string;
  stateId: number;
  state: string;
  id: number;
  giftId: string;
  dateCreated: string;
  bonus: string;
  value: string;
  imageUrl: string;
  statusId: number;
  status: string;
  title: string;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
}
export interface RecentOrderData {
  id: string;
  amount: string;
  date: string;
  orderNumber: string;
  statusId: OrderStatusType;
  status: string;
  senderEmail: string;
  senderName: string;
}
export interface OrderFilter extends PageFilter {
  userId?: string;
  status?: OrderStatusType;
}
export interface OrderData {
  SenderEmail: string;
  SenderPhone: string;
  senderName: string;
  quantity: number;
  id: number;
  orderNumber: string;
  dateCreated: string;
  statusId: number;
  status: string;
  paymentStatusId: number;
  paymentStatus: string;
  paymentMethodId: number;
  paymentMethod: string;
  hasCoupon: boolean;
  subTotal: string;
  shipping: string;
  discount: string;
  amountDue: string;
  coupon: string;
  printingFee: string;
}
export interface OrderInfoData extends OrderData {
  deliveryStatus: string;
  orderItems: OrderRecipientData[];
}
export interface OrderRecipientData {
  dateCreated: Date;
  statusId: DeliveryStatusType;
  status: string;
  deliveryMediumId: DeliveryMediumType;
  deliveryMedium: string;
  address: string;
  city: string;
  stateId: number;
  state: string;
  id: number;
  giftId: string;
  bonus: string;
  value: string;
  imageUrl: string;
  title: string;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  bgPicture: string;
  message: string;
  picture: string;
}
export interface CancelOrderModel {
  reason: string;
}
export interface EditOrderRecipientModel {
  id: any;
  deliveryMedium: string;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  stateId: string;
  address: string;
  city: string;
}
