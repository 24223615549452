import { Box } from "@mui/material";
import { useMemo } from "react";
import { DateRangeFilter, FilterDropdown, OptionNaming, PageHeaderTitle, SearchButton, SearchContainer, SearchInput, emptyOption } from "suregifts-component-lib";
import { useGetMerchantNames, useGetStoreNames } from "../../../services/merchants";

import { VoucherTransactionFilter } from "../../../shared/voucher";
import { useExportTransacitons } from "../../../services/vouchers";

type FilterSectionProps = {
  handleFilter?: (params: any) => void;
  filter: VoucherTransactionFilter;
};
export const FilterSection = ({ handleFilter = () => {}, filter }: FilterSectionProps) => {
  const { data: merchantNames } = useGetMerchantNames();
  const { data: storeNames } = useGetStoreNames(filter.merchantId);
  const { mutate: exportReport } = useExportTransacitons();

  const merchants = useMemo(() => {
    let temp;
    if (merchantNames) {
      temp = merchantNames?.map((item) => ({ text: item.name, value: item.id.toString() } as OptionNaming));
    }
    return temp || [];
  }, [merchantNames]);

  const stores = useMemo(() => {
    let temp;
    if (filter.merchantId && storeNames) {
      temp = storeNames?.map((item) => ({ text: item.name, value: item.id.toString() }));
    }
    return temp || [];
  }, [storeNames, filter.merchantId]);

  return (
    <SearchContainer sx={{ marginTop: "40px" }}>
      <PageHeaderTitle title={"Activations"} />
      <div style={{ flexGrow: 1 }}></div>
      <Box
        sx={{
          display: "flex",
          gap: "1.25rem",
          alignItems: "center",
        }}
      >
        <DateRangeFilter display={"Date"} valueChanged={(params) => handleFilter({ ...params, page: 1 })} />
        <FilterDropdown value={filter.storeId} display="Store" options={[emptyOption, ...stores]} onChange={(index, value) => handleFilter({ storeId: value, page: 1 })} />
        <FilterDropdown
          display="Merchant"
          value={filter.merchantId}
          options={[emptyOption, ...merchants]}
          onChange={(index, value) => {
            handleFilter({ merchantId: value, storeId: undefined, page: 1 });
          }}
        />

        <SearchInput
          value={filter.search}
          onChange={(e) => {
            handleFilter({ search: e, page: 1 });
          }}
        />
        <SearchButton
          label="Export"
          onClick={() => {
            exportReport({ ...filter, typeId: 1 });
          }}
        />
      </Box>
    </SearchContainer>
  );
};
