import { Dialog, DialogContent, Stack, DialogActions, Typography, OutlinedInput } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { useRejectRefund, RefundModel, RefundData } from "../../../../services/refunds";
import { FormButton, DialogTitle } from "suregifts-component-lib";
import { RefundInfoView } from "./RefundInfoView";
import { useNavigate } from "react-router-dom";
import { useRefundModalState } from "./useRefundModalState";

function DeclientRefundModal() {
  const { mutate: reject, isLoading: isRejecting } = useRejectRefund();

  const { handleClose, model, refund, setModel } = useRefundModalState();

  return (
    <div>
      <Dialog maxWidth={"xs"} open={true} sx={{}}>
        <DialogTitle title={"Details"} handleClose={handleClose} />
        <DialogContent>
          <Stack spacing={4}>
            <RefundInfoView refund={refund} />
            <div>
              <Typography>Note: </Typography>
              <OutlinedInput multiline rows={2} fullWidth sx={{ marginTop: "0.625rem", borderRadius: "0.75rem" }} value={model.note} onChange={(e) => setModel((prev) => ({ ...prev, note: e.target.value }))}></OutlinedInput>
            </div>
          </Stack>
          <DialogActions>
            <FormButton
              color="primary"
              sx={{ color: "error.main" }}
              fullWidth
              loading={isRejecting}
              onClick={() => {
                reject(model, {
                  onSuccess: () => {
                    toast.success("Redund rejected successfully.");
                    handleClose();
                  },
                });
              }}
              label="Reject"
            ></FormButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DeclientRefundModal;
