import { Box, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DeliveryMediumType, DeliveryStatusType, FormButton, MarginTop40Box, PageHeaderTitle } from "suregifts-component-lib";
import { useResendCard } from "../../../../services/order";
import { useOrderScreenContext } from "./context/ScreenContext";
import { useMemo } from "react";
import "./style.css";

export function GiftCardImageView() {
  return (
    <div>
      <PageHeaderTitle title={"Selected Greeting Card"} />
      <ImageView />
    </div>
  );
}
const ImageView = () => {
  const { orderId } = useParams();

  const { dispatch, state } = useOrderScreenContext();
  const { mutate: resend, isLoading: isSending } = useResendCard(orderId, state.recipient?.id);
  const handleResend = () => {
    resend(undefined, {
      onSuccess: () => {
        toast.success("Gift item resent successfully.");
        dispatch({ pageType: "GiftCard", recipient: state.recipient! });
      },
    });
  };
  const buttonEnabled = useMemo(() => {
    return state.recipient!.statusId === DeliveryStatusType.Delivered && state.recipient?.deliveryMediumId !== DeliveryMediumType.HomeDelivery;
  }, [state.recipient!.statusId]);
  const theme = useTheme();
  return (
    <>
      <Box
        borderRadius={"1.25rem"}
        sx={{
          marginTop: "40px",
          height: "18.75rem",
          overflow: "hidden",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          "& > div": {
            backgroundImage: `url(${state.recipient?.bgPicture})`,
            backgroundSize: "cover",
          },
        }}
        bgcolor="#CC41AD"
      >
        <Box></Box>
        <Box
          sx={{
            display: "grid",
            padding: "12px",
            gridTemplateRows: "1fr 1fr",
            color: "#000000",
          }}
        >
          <div
            style={{
              overflow: "hidden",
              height: "150px",
              borderRadius: "16px",
              background: "white",
              padding: "8px",
            }}
          >
            <div style={{ border: "1px dashed black", borderRadius: "8px", height: "100%", display: "flex", alignItems: "center" }}>{state.recipient?.message}</div>
          </div>
          <img
            src={state.recipient?.picture}
            style={{
              maxWidth: "150px",
              maxHeight: "115px",
              alignSelf: "center",
              justifySelf: "center",
              borderRadius: "10px",
              objectFit: "cover",
            }}
          />
        </Box>
      </Box>
      {state.recipient!.deliveryMediumId !== DeliveryMediumType.HomeDelivery && (
        <FormButton loading={isSending} disabled={!buttonEnabled} onClick={handleResend} fullWidth label={"Resend Voucher"} color="success" sx={{ mt: "1.85rem", p: "1.3125rem" }} />
      )}
    </>
  );
};
