import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { DialogTitle, FormButton, FormInput, YupShape } from "suregifts-component-lib";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import { useNavigate, useParams } from "react-router-dom";
import { DeactivateVoucherModel, useDeactivateVoucher } from "../../../../services/vouchers";

export const schema = yup
  .object<YupShape<DeactivateVoucherModel>>({
    voucherCode: yup.string().required(),
    reason: yup.string().required(),
  })
  .required();

function DeactivateVoucherModal() {
  const { mutate: deactivate, isLoading } = useDeactivateVoucher();
  const { voucherCode } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<DeactivateVoucherModel>({
    defaultValues: {
      voucherCode,
      reason: "",
    },
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const handleClose = () => {
    if (isLoading) return;
    navigate("..");
  };
  const sumitForm = (data) => {
    deactivate(data, {
      onSuccess: () => {
        toast.success("Voucher deactivated successfully.");
        handleClose();
      },
    });
  };

  return (
    <Dialog open maxWidth="xs" onClose={handleClose}>
      <DialogTitle title="Deactivate Voucher" handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={2}>
          <FormInput disabled label="Voucher Code" {...register("voucherCode")} helperText={errors.voucherCode?.message} />
          <FormInput required label="Reason" {...register("reason")} helperText={errors.reason?.message} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton disabled={!isValid} loading={isLoading} label="Dactivate" fullWidth onClick={handleSubmit(sumitForm)} />
      </DialogActions>
    </Dialog>
  );
}

export default DeactivateVoucherModal;
