import { Box, TableRow, TableCell, Typography } from "@mui/material";
import React from "react";
import { OrderInfoData } from "../../../../services/order";
import { useOrderScreenContext } from "./context/ScreenContext";
import { PageHeaderTitle, SelectionTable, SelectionTableHead, SelectionTableBody, OrderItemStatusLabel } from "suregifts-component-lib";
import { TopBottomLabel } from "../../../../components/molecules/TopBottomLabel";
type RecipientSectionProps = {
  data?: OrderInfoData;
};
function RecipientListView({ data }: RecipientSectionProps) {
  const { dispatch, state } = useOrderScreenContext();
  return (
    <div>
      <PageHeaderTitle title="Item Details" />
      <Box
        sx={{
          marginTop: "1.5625rem",
          marginLeft: "-1.25rem",
          marginRight: "-1.25rem",
          // "& tbody tr td": {
          //   paddingY: "16px !important",
          // },
        }}
      >
        <SelectionTable>
          <SelectionTableHead>
            <TableRow>
              <TableCell>Store Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Recipient</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </SelectionTableHead>
          <SelectionTableBody>
            {data?.orderItems.map((item) => (
              <TableRow sx={{ cursor: "pointer" }} onClick={() => dispatch({ pageType: "GiftCard", recipient: item })} key={item.id}>
                <TableCell>{item.title}</TableCell>
                <TableCell>
                  <OrderItemStatusLabel label={item.status} status={item.statusId} />
                </TableCell>
                <TableCell>
                  <TopBottomLabel bottom={item.email || item.phoneNumber} top={`${item.firstName} ${item.lastName}`} />
                </TableCell>
                <TableCell>{item.value}</TableCell>
              </TableRow>
            ))}
          </SelectionTableBody>
        </SelectionTable>
      </Box>
    </div>
  );
}

export default RecipientListView;
