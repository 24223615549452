import { DashboardIcon, General2Icon, General3Icon, NavLinkProps, PeopleIcon, PeopleNavIcon, RefundIcon, UserIcon } from "suregifts-component-lib";
import { TwoPeople } from "../../../../components/icons/TwoPeople";

const customerSupportLinks: NavLinkProps[] = [
  {
    to: "/customer-support/dashboard",
    icon: DashboardIcon,
    title: "Dashboard",
  },
  {
    to: "/customer-support/orders",
    icon: General2Icon,
    title: "Orders",
  },
  {
    to: "/customer-support/carts",
    icon: General3Icon,
    title: "Abandoned Carts",
  },
  {
    to: "/customer-support/payments",
    icon: General3Icon,
    title: "Payments",
  },
  {
    to: "/customer-support/users",
    icon: TwoPeople,
    title: "Customers",
  },
];

export default customerSupportLinks;
