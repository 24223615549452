import { useMutation, useQuery } from "react-query";
import { VoucherInfoData, ActivateVoucherModel, RedeemVoucherModel, VoucherTransactionData, VoucherTransactionFilter } from "../../shared/voucher";
import { Count, ServerError, VoucherOperationType, useAxios } from "suregifts-component-lib";

export const useCheckBalance = () => {
  const axios = useAxios();
  return useMutation<VoucherInfoData, ServerError, string>((voucherCode) => {
    return axios.get(`/vouchers/api/merchant-dashboard/balance`, {
      params: { voucherCode },
    });
  });
};
export const useActivateVoucher = () => {
  const axios = useAxios();
  return useMutation<VoucherInfoData, ServerError, ActivateVoucherModel>((model) => {
    return axios.post(`/vouchers/api/merchant-dashboard/activate`, model);
  });
};
export const useRedeemVoucher = () => {
  const axios = useAxios();
  return useMutation<VoucherInfoData, ServerError, RedeemVoucherModel>((model) => {
    return axios.post(`/vouchers/api/merchant-dashboard/redeem`, model);
  });
};
export const useGetRecentActivations = () => {
  const axios = useAxios();
  return useQuery<VoucherTransactionData[], ServerError>({
    queryKey: ["recent-activations", {}],
    keepPreviousData: true,
    initialData: [],
    queryFn: () =>
      axios.get("/vouchers/api/merchant-dashboard/transactions/recent", {
        params: { typeId: VoucherOperationType.Activation },
      }),
  });
};
export const useGetRecentRedemptions = () => {
  const axios = useAxios();
  return useQuery<VoucherTransactionData[], ServerError>({
    queryKey: ["recent-redemptions", {}],
    keepPreviousData: true,
    initialData: [],
    queryFn: () =>
      axios.get("/vouchers/api/merchant-dashboard/transactions/recent", {
        params: { typeId: VoucherOperationType.Redemption, size: 3 },
      }),
  });
};
export const useGetTransactions = (params: VoucherTransactionFilter) => {
  const axios = useAxios();
  return useQuery<Count<VoucherTransactionData>, ServerError>({
    queryKey: ["transactions", params],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    queryFn: () =>
      axios.get("/vouchers/api/merchant-dashboard/transactions", {
        params,
      }),
  });
};

export const useExportTransactions = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, VoucherTransactionFilter>((params) => {
    return axios.get("/vouchers/api/merchant-dashboard/transactions/export", { params });
  });
};
