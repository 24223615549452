import FilterSection from "./FilterSection";
import { Outlet, useParams } from "react-router-dom";
import { useDeletePersonnel, useGetPersonnels } from "../../../services/merchants";
import { TableHead, TableRow, TableCell, IconButton } from "@mui/material";
import { DeleteIcon, MainTable, useNotificationContext, TableBody, useDocumentTitle, useFilter, PageFilter, usePagination, Pagination } from "suregifts-component-lib";
import moment from "moment";
import { toast } from "react-toastify";
import { PersonnelData } from "../../../shared/merchants";
import { fixedCellStyle } from "../../../helpers/cssHelper";

function PersonnelScreen() {
  const { merchantId } = useParams();
  const { confirm } = useNotificationContext();
  useDocumentTitle("Store Personnels");
  const { data, isLoading, refetch } = useGetPersonnels(merchantId);
  const [filter, setFilter] = useFilter<PageFilter>({ page: 1 });

  const { total, data: items } = usePagination(filter.page, data);
  const { mutate: deleteRecord, isLoading: isDeleting } = useDeletePersonnel(merchantId);
  function handleDelete(item: PersonnelData): void {
    confirm({
      onConfirm: () => {
        deleteRecord(item, {
          onSuccess: () => {
            toast.success("Personnel deleted successfully.");
            refetch();
          },
        });
      },
    });
  }

  return (
    <div>
      <FilterSection />
      <MainTable
        loading={isLoading || isDeleting}
        sx={{
          marginTop: "40px",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Merchant</TableCell>
            <TableCell>Stores</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Date Created</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items?.map((item) => (
            <TableRow key={item.id}>
              <TableCell style={fixedCellStyle}>{item.fullName}</TableCell>
              <TableCell style={fixedCellStyle}>{item.email}</TableCell>
              <TableCell>{item.merchant}</TableCell>
              <TableCell>{item.stores.join(", ")}</TableCell>
              <TableCell>{item.role}</TableCell>
              <TableCell>{moment(item.dateCreated).format("DD.MM.YYYY")}</TableCell>
              <TableCell sx={{ width: "10%", whiteSpace: "nowrap" }}>
                <IconButton color="error" onClick={() => handleDelete(item)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination
          page={filter.page}
          total={total}
          onChange={(page) => {
            setFilter((prev) => ({ ...prev, page }));
          }}
        />
      </div>
      <Outlet />
    </div>
  );
}

export default PersonnelScreen;
