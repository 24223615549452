import { Typography } from "@mui/material";
import { useMemo } from "react";
import { BulkVoucherStatusType } from "../../../services/vouchers";

type BulkVoucherStatusLabelProps = {
  statusId: BulkVoucherStatusType;
  status: string;
};
export const BulkVoucherStatusLabel = ({ status, statusId }: BulkVoucherStatusLabelProps) => {
  const labelColor = useMemo(() => {
    switch (statusId) {
      case BulkVoucherStatusType.Activated:
        return "success.main";
      case BulkVoucherStatusType.Processing:
      case BulkVoucherStatusType.UnActivated:
        return "warning.main";
      default:
        return "error.main";
    }
  }, [statusId]);
  return (
    <Typography component={"span"} color={labelColor}>
      {status}
    </Typography>
  );
};
