import FilterSection from "./FilterSection";
import { Outlet, useParams } from "react-router-dom";
import { TableHead, TableRow, TableCell, IconButton } from "@mui/material";
import { DeleteIcon, MainTable, PageFilter, Pagination, TableBody, dateOnly, useAuthContext, useDocumentTitle, useFilter, usePagination } from "suregifts-component-lib";
import moment from "moment";
import { toast } from "react-toastify";
import { PersonnelData } from "../../../shared/merchants";
import { useDeletePersonnel, useGetPersonnels } from "../../../services/merchant-dashboard/merchant";
import { useState } from "react";
import { fixedCellStyle } from "../../../helpers/cssHelper";

function PersonnelScreen() {
  useDocumentTitle("Personnels");
  const { user } = useAuthContext();
  const { data: personnels, isFetching: isLoading, refetch } = useGetPersonnels();
  const { mutate: deleteRecord, isLoading: isDeleting } = useDeletePersonnel();
  const [filter, setFilter] = useFilter<PageFilter>({ page: 1 });
  const { data, total } = usePagination(filter.page, personnels, 10);
  function handleDelete(item: PersonnelData): void {
    deleteRecord(item, {
      onSuccess: () => {
        toast.success("Personnel deleted successfully.");
        refetch();
      },
    });
  }

  return (
    <div>
      <FilterSection />
      <MainTable loading={isLoading || isDeleting} sx={{ marginY: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Stores</TableCell>
            <TableCell>Date Created</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item) => (
            <TableRow key={item.id}>
              <TableCell style={fixedCellStyle}>{item.fullName}</TableCell>
              <TableCell style={fixedCellStyle}>{item.email}</TableCell>
              <TableCell>{item.role}</TableCell>
              <TableCell>{item.stores.join(", ")}</TableCell>
              <TableCell>{dateOnly(item.dateCreated)}</TableCell>
              <TableCell>
                <IconButton color="error" onClick={() => handleDelete(item)} disabled={item.email === user?.email}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <Pagination
        size={filter.size}
        page={filter.page}
        total={total}
        onChange={(page) => {
          setFilter((prev) => ({ ...prev, page }));
        }}
      />
      <Outlet />
    </div>
  );
}

export default PersonnelScreen;
