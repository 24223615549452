import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FilterDropdown, PageHeaderTitle, SearchButton, SearchContainer, SearchInput } from "suregifts-component-lib";

const statuses = [{ text: "All" }, { text: "Active", value: true }, { text: "Inactive", value: false }];
function FilterSection({ handleFilter, filter }) {
  const navigate = useNavigate();
  return (
    <SearchContainer sx={{ marginTop: "40px" }}>
      <PageHeaderTitle title="Merchants" />
      <Box
        sx={{
          display: "flex",
          gap: "1.25rem",
          alignItems: "center",
        }}
      >
        <FilterDropdown
          display="Status"
          options={statuses}
          value={filter.active}
          onChange={(index, value) => {
            handleFilter({ active: value, page: 1 });
          }}
        />
        <SearchInput
          value={filter.search}
          onChange={(e) => {
            handleFilter({ search: e, page: 1 });
          }}
        />

        <SearchButton sx={{ py: "0.125rem" }} label="Add Merchant" onClick={() => navigate("add")} />
      </Box>
    </SearchContainer>
  );
}

export default FilterSection;
