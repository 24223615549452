import { FilterSection } from "./FilterSection";
import { TableView } from "./TableView";
import { VoucherTransactionFilter } from "../../../shared/voucher";
import { useDocumentTitle, useFilter } from "suregifts-component-lib";
import { Outlet } from "react-router-dom";

function RedemptionsScreen() {
  const [filter, setFilter] = useFilter<VoucherTransactionFilter>({ page: 1, search: "" });
  useDocumentTitle("Voucher Reports");
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  const handlePage = (page: number) => {
    setFilter((prev) => ({ ...prev, page: page }));
  };

  return (
    <div>
      <FilterSection filter={filter} handleFilter={handleFilter} />
      <TableView filter={filter} handlePage={handlePage} />
      <Outlet />
    </div>
  );
}

export default RedemptionsScreen;
