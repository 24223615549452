import { Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useState } from "react";
import { useOrderScreenContext } from "../context/ScreenContext";
import { DialogTitle, FormButton, FormInput } from "suregifts-component-lib";
import { useCancelOrder } from "../../../../../services/order";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";

function CancelOrderModal() {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [cancelReason, setCancelReason] = useState("");
  const { mutate: cancelOrder, isLoading } = useCancelOrder(orderId);
  const handleClose = () => {
    navigate("..");
  };
  const handleSummit = () => {
    cancelOrder(
      { reason: cancelReason },
      {
        onSuccess: () => {
          toast.success("Order canceled successfully.");
          queryClient.invalidateQueries("orders-byId");
          handleClose();
        },
      }
    );
  };

  return (
    <Dialog open maxWidth="xs">
      <DialogTitle title="Cancel" handleClose={handleClose} />
      <DialogContent>
        <FormInput label="Reason" size="medium" multiline value={cancelReason} onChange={(e) => setCancelReason(e.target.value)} />
      </DialogContent>
      <DialogActions>
        <FormButton disabled={!cancelReason} loading={isLoading} onClick={handleSummit} fullWidth color="primary" sx={{ color: "error.main" }} />
      </DialogActions>
    </Dialog>
  );
}

export default CancelOrderModal;
