import { TableHead, TableRow, TableCell } from "@mui/material";
import moment from "moment";
import { MainTable, Pagination, TableBody, dateTime } from "suregifts-component-lib";

import { fixedCellStyle } from "../../../helpers/cssHelper";
import { useGetTransactions } from "../../../services/merchant-dashboard/voucher";

export const TableView = ({ filter, handleFilter }) => {
  const { data, isFetching } = useGetTransactions({ ...filter, typeId: 1 });
  return (
    <>
      <MainTable loading={isFetching} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Voucher Code</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>channel</TableCell>
            <TableCell>Store</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items.map((item) => (
            <TableRow key={item.id}>
              <TableCell style={fixedCellStyle}>{item.code}</TableCell>
              <TableCell>{item.amount}</TableCell>
              <TableCell style={fixedCellStyle}>{item.usageMedium}</TableCell>
              <TableCell>{item.pointOfUsage}</TableCell>
              <TableCell style={fixedCellStyle}>{dateTime(item.dateTime)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination page={filter.page} total={data?.total} onChange={(page) => handleFilter({ page })} />
      </div>
    </>
  );
};
