import { useQuery } from "react-query";
import { useAxios, ServerError } from "suregifts-component-lib";

export const useGetSummaryData = () => {
  const axios = useAxios();
  return useQuery<SummaryData, ServerError>({
    queryKey: ["users-summary"],
    keepPreviousData: true,
    queryFn: () => axios.get("/general/api/super-admin/summary", {}),
  });
};

export interface SummaryData {
  pendingOrders: string;
  totalOrders: string;
  allMerchants: string;
  totalPayments: string;
}
