import { Box } from "@mui/material";
import { SummaryCardWithIcon, MerchantPotIcon, CheckListIcon, PeopleIcon, useDocumentTitle, VoucherOperationType } from "suregifts-component-lib";
import RecentRedemptionView from "../../../components/organisms/RecentRedemptionView";
import { useGetRecentActivations, useGetRecentRedemptions } from "../../../services/vouchers";
import { useGetMerchantSummary } from "../../../services/merchant-backend/general";
import { SummaryCardsContainer } from "../../../components/organisms/SummaryWith4CardsBox";
import RecentActivationView from "../../../components/organisms/RecentActivationView";

function DashboardScreen() {
  useDocumentTitle("Dashboard");
  return (
    <div>
      <SummarySection />
      <RecentTransactionSection />
    </div>
  );
}
export const RecentTransactionSection = () => {
  const { data: activations, isFetching: loadingActivations } = useGetRecentActivations();
  const { data: redemptions, isFetching: loadingRedemptions } = useGetRecentRedemptions();
  return (
    <Box
      sx={(theme) => ({
        marginTop: "2.5rem",
        display: "grid",
        gap: "2.375rem",
        gridTemplateColumns: "1fr",
        [theme.breakpoints.up("lg")]: {
          gridTemplateColumns: "2fr 1fr",
        },
      })}
    >
      <RecentActivationView data={activations} isloading={loadingActivations} to={`/merchant-backend/reports?typeId=${VoucherOperationType.Activation}`} />
      <RecentRedemptionView data={redemptions} isloading={loadingRedemptions} to={`/merchant-backend/reports?typeId=${VoucherOperationType.Redemption}`} />
    </Box>
  );
};
export const SummarySection = () => {
  const { data: summary, isFetching } = useGetMerchantSummary();

  return (
    <SummaryCardsContainer sx={{ marginTop: "40px" }} isLoading={isFetching}>
      <SummaryCardWithIcon title={summary?.allMerchants} info="All merchants" icon={MerchantPotIcon} />
      <SummaryCardWithIcon title={summary?.newMerchants} info="new merchants " icon={CheckListIcon} />
      <SummaryCardWithIcon title={summary?.pendingMerchants} info="pending approvals" icon={PeopleIcon} />
      <SummaryCardWithIcon title={summary?.inactiveMerchants} info="inactive merchants" icon={CheckListIcon} />
    </SummaryCardsContainer>
  );
};
export default DashboardScreen;
