import { Box, Divider, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { CartInfoData, useSendCartReminder } from "../../../../services/order";
import { FormButton, PageHeaderTitle, TitleDatailInline, TitleDetailView, dateOnly } from "suregifts-component-lib";

export function InfoSection({ data }: { data?: CartInfoData }) {
  const params = useParams();
  const { mutate: sendReminder, isLoading: isSendingReminder } = useSendCartReminder(data?.id);

  const handleSendReminder = () => {
    sendReminder({} as any, {
      onSuccess: () => {
        toast.success("Reminder sent successfully.");
      },
    });
  };

  return (
    <div>
      <PageHeaderTitle title="Cart Details" />
      <Box p="1.85rem" sx={{ mt: "1.25rem", bgcolor: "primary.main", boxShadow: 2, borderRadius: "1.25rem" }}>
        <Stack spacing={"1.85rem"}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <TitleDetailView align="left" title="Date Created" detail={dateOnly(data?.dateCreated)} />
            <TitleDetailView align="right" title="Sender's Name" detail={data?.senderName} />
          </Box>
          <Box>
            <TitleDetailView align="left" title="Sender's Email" detail={data?.SendEmail} />
          </Box>
          <Box>
            <TitleDetailView align="left" title="Sender's Phone" detail={data?.SenderPhone || "N/A"} />
          </Box>
        </Stack>
        <Divider sx={{ my: "2.5rem" }} />
        <Stack spacing={"1.85rem"}>
          <TitleDatailInline title="Last Reminder" detail={dateOnly(data?.lastReminderTime)} />
          <Stack spacing={2} direction={"row"}>
            <FormButton sx={{ px: "0.125rem" }} fullWidth label={"Send Reminder"} loading={isSendingReminder} color="primary" onClick={handleSendReminder} />
          </Stack>
        </Stack>
      </Box>
    </div>
  );
}
