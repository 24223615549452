import { Typography } from "@mui/material";
import { useMemo } from "react";
import { VoucherStatusType } from "suregifts-component-lib";

type VoucherStatusLabelProps = {
  text: string;
  status: VoucherStatusType;
};
export const VoucherStatusLabel = ({ text, status }: VoucherStatusLabelProps) => {
  const color = useMemo(() => {
    switch (status) {
      case VoucherStatusType.Active:
        return "success.main";
      case VoucherStatusType.Generated:
      case VoucherStatusType.Pending:
        return "warning.main";

      default:
        return "error.main";
    }
  }, [status]);

  return (
    <Typography component={"span"} color={color}>
      {text}
    </Typography>
  );
};
