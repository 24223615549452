import { Typography } from "@mui/material";
import { RefundStatusType } from "suregifts-component-lib";
type TransactionStatusLabelProps = {
  text: string;
  status: RefundStatusType;
};
export const RefundStatusLabel = ({ text, status }: TransactionStatusLabelProps) => {
  const getColor = () => {
    switch (status) {
      case RefundStatusType.Rejected:
        return "error.main";
      case RefundStatusType.Approved:
        return "success.main";
      default:
        return "warning.main";
    }
  };
  return (
    <Typography component={"span"} color={getColor()}>
      {text}
    </Typography>
  );
};
