import { Dialog, DialogContent, Stack } from "@mui/material";
import { useState } from "react";
import { RefundStatusLabel } from "../RefundStatusLabel";
import { RefundData } from "../../../../services/refunds";
import { TitleAndDetailsView, DialogTitle } from "suregifts-component-lib";
import { useNavigate } from "react-router-dom";

import { RefundInfoView } from "./RefundInfoView";
import { useRefundModalState } from "./useRefundModalState";

function ViewRefundModal() {
  const { handleClose, refund } = useRefundModalState();
  return (
    <div>
      <Dialog maxWidth={"xs"} open={true} sx={{}}>
        <DialogTitle title={"Details"} handleClose={handleClose} />
        <DialogContent>
          <Stack spacing={4}>
            <RefundInfoView refund={refund} />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ViewRefundModal;
