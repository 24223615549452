import { TableHead, TableRow, TableCell, Chip } from "@mui/material";
import { LoadingBox, MainTable, Pagination, TableBody, VoucherOperationType, dateTime } from "suregifts-component-lib";
import { useState } from "react";

import { fixedCellStyle } from "../../../helpers/cssHelper";
import RedemptionInfoModal from "./RedemptionInfoModal";
import { useQueryClient } from "react-query";
import { useGetTransactions } from "../../../services/vouchers";

export const TableView = ({ filter, handlePage }) => {
  const { data, isFetching } = useGetTransactions({ ...filter, typeId: 8 });
  const [transactionId, setTransactionId] = useState<any>();

  const queryClient = useQueryClient();
  return (
    <>
      <MainTable loading={isFetching} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Voucher code</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Merchant Income</TableCell>
            <TableCell sx={{ width: "35%" }}>Point of Usage</TableCell>

            <TableCell>Channel</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items.map((item) => (
            <TableRow
              key={item.id}
              sx={{
                cursor: "pointer",
              }}
              onClick={() => {
                setTransactionId(item.id);
              }}
            >
              <TableCell style={fixedCellStyle}>{item.code}</TableCell>
              <TableCell
                style={{
                  color: item.refundStatus ? "orange" : "inherit",
                }}
              >
                {item.amount}
                {item.redemptionFee === "₦0.00" ? <></> : <div style={{ fontStyle: "italic" }}>Fee: {item.redemptionFee}</div>}
                {/* {item.refundStatus && (
                  <>
                    <br />
                    <Chip size="small" variant="outlined" label={item.refundStatus} color="warning" />
                  </>
                )} */}
              </TableCell>
              <TableCell>{item.merchantIncome}</TableCell>
              <TableCell>
                <div style={{ fontWeight: 700 }}>{item.merchantName}</div>
                <div style={{ fontStyle: "italic" }}>{item.narration}</div>
              </TableCell>
              <TableCell>{item.usageMedium}</TableCell>
              <TableCell style={fixedCellStyle}>{dateTime(item.dateTime)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination page={filter.page} total={data?.total} onChange={handlePage} />
      </div>
      {transactionId && (
        <RedemptionInfoModal
          handleClose={() => {
            queryClient.invalidateQueries("redemptions");
            setTransactionId(undefined);
          }}
          transactionId={transactionId}
        />
      )}
    </>
  );
};
