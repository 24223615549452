import { TableHead, TableRow, TableCell, IconButton, Dialog, DialogContent, DialogActions } from "@mui/material";
import { DialogTitle, FormButton, FormInput, MainTable, OptionNaming, Pagination, SelectPopover, TableBody, VoucherType, dateOnly, formatNumber, useAuthContext, useNotificationContext } from "suregifts-component-lib";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useState } from "react";
import { toast } from "react-toastify";
import { fixedCellStyle } from "../../../helpers/cssHelper";
import { BulkVoucherStatusLabel } from "./BulkVoucherStatusLabel";
import { BulkVoucherData, BulkVoucherStatusType, useActivateVouchers, useCancelVouchers, useExportVouchers, useGetBulkVouchers } from "../../../services/vouchers";
import { TopBottomLabel } from "../../../components/molecules/TopBottomLabel";
const getOptions = (item: BulkVoucherData, role) => {
  var options: OptionNaming[] = [];
  if (item.history.length > 0) {
    options.push({ text: "View History", value: 0 });
  }
  if (item.totalCreated === item.quantity) {
    options.push({ text: "Download", value: 1 });
  }
  if (item.statusId !== BulkVoucherStatusType.Canceled && item.statusId !== BulkVoucherStatusType.Failed) {
    options.push({ text: "Cancel", value: 2 });
  }
  if (item.statusId === BulkVoucherStatusType.UnActivated && item.typeId === VoucherType.Sales) {
    options.push({ text: "Activate", value: 3 });
  }
  return options;
};
function TableView({ filter, handleFilter }) {
  const { isFetching, data: vouchers, refetch } = useGetBulkVouchers(filter);
  const [history, setHistory] = useState<string[] | undefined>();
  const { isLoading: exporting, mutate: exportVouchers } = useExportVouchers();
  const { isLoading: isActivating, mutate: activateVouchers } = useActivateVouchers();
  const { confirm } = useNotificationContext();
  const [cancel, setCancel] = useState("");
  const { user } = useAuthContext();
  const handleExport = (bulkId: number) => {
    exportVouchers(bulkId, {
      onSuccess: () => {
        toast.success("Vouchers successfully downloaded.");
      },
    });
  };

  return (
    <div>
      <MainTable loading={isFetching || isActivating} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>BatchNumber</TableCell>
            <TableCell>Gift Info</TableCell>
            <TableCell></TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {vouchers?.items.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.id}</TableCell>
              <TableCell>
                <TopBottomLabel top={item.requesterEmail} bottom={item.merchantName} />
              </TableCell>
              <TableCell>
                <TopBottomLabel top={` Quantity: ${item.quantity}`} bottom={`Generated: ${item.totalGenerated}`} />
              </TableCell>
              <TableCell>{formatNumber(item.value)}</TableCell>
              <TableCell style={fixedCellStyle}>{item.type}</TableCell>
              <TableCell style={fixedCellStyle}>
                <BulkVoucherStatusLabel status={item.status} statusId={item.statusId} />
              </TableCell>
              <TableCell style={fixedCellStyle}>
                <TopBottomLabel top={`Created: ${dateOnly(item.dateCreated)}`} bottom={`Expires: ${dateOnly(item.expiryDate)}`} />
              </TableCell>
              <TableCell>
                <SelectPopover
                  onChange={(index, value) => {
                    if (value == 0) {
                      setHistory(item.history);
                    } else if (value == 1) {
                      handleExport(item.id);
                    } else if (value == 2) {
                      setCancel(item.id.toString());
                    } else if (value == 3) {
                      confirm({
                        onConfirm: () => {
                          activateVouchers(item.id, {
                            onSuccess: () => {
                              toast.success("Vouchers activated successfully.");
                              refetch();
                            },
                          });
                        },
                      });
                    }
                  }}
                  options={getOptions(item, user?.role)}
                  render={(handleClick) => (
                    <IconButton onClick={handleClick}>
                      <MoreHorizIcon />
                    </IconButton>
                  )}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination page={filter.page} total={vouchers?.total} onChange={(page) => handleFilter({ page })} />
      </div>
      {history && <HistoryModal data={history} handleClose={() => setHistory(undefined)} />}
      {Boolean(cancel) && (
        <CancelModal
          bulkId={cancel}
          handleClose={() => {
            refetch();
            setCancel("");
          }}
        />
      )}
    </div>
  );
}
const CancelModal = ({ handleClose, bulkId }) => {
  const { isLoading: isCanceling, mutate: cancelVouchers } = useCancelVouchers(bulkId);
  const [reason, setReason] = useState("");
  return (
    <Dialog open maxWidth="xs">
      <DialogTitle handleClose={handleClose} title="Cancel Vouchers" />
      <DialogContent>
        <FormInput multiline size="medium" value={reason} onChange={(e) => setReason(e.target.value)} />
      </DialogContent>
      <DialogActions>
        <FormButton
          loading={isCanceling}
          label="Submit"
          fullWidth
          color="error"
          disabled={!reason}
          onClick={() => {
            cancelVouchers(reason, {
              onSuccess: () => {
                toast.success("Vouchers canceled successfully.");
                handleClose();
              },
            });
          }}
        />
      </DialogActions>
    </Dialog>
  );
};
type HistoryModalProps = {
  handleClose: () => void;
  data: string[];
};
const HistoryModal = ({ handleClose, data }) => {
  return (
    <Dialog open>
      <DialogTitle title="History" handleClose={handleClose} />
      <DialogContent>
        <div
          style={{
            display: "grid",
            gap: "30px",
          }}
        >
          {data.map((item, index) => (
            <div>
              {" "}
              {index + 1}: {item}
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TableView;
