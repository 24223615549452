import { useQuery } from "react-query";
import { useAxios, ServerError } from "suregifts-component-lib";

export const useGetVoucherSummary = () => {
  const axios = useAxios();
  return useQuery<VoucherSummaryData, ServerError>({
    queryKey: ["users-summary"],
    keepPreviousData: true,
    queryFn: () => axios.get("/general/api/merchant-backend/voucher-summary", {}),
  });
};
export const useGetMerchantSummary = () => {
  const axios = useAxios();
  return useQuery<MerchantSummary, ServerError>({
    queryKey: ["users-summary"],
    keepPreviousData: true,
    queryFn: () => axios.get("/general/api/merchant-backend/merchant-summary", {}),
  });
};
export interface VoucherSummaryData {
  activations: string;
  redemptions: string;
  deactivations: string;
}
export interface MerchantSummary {
  allMerchants: string;
  pendingMerchants: string;
  newMerchants: string;
  inactiveMerchants: string;
}
