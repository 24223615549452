import { useQuery } from "react-query";
import { ServerError, useAxios } from "suregifts-component-lib";

export const useGetDashboardData = () => {
  const axios = useAxios();
  return useQuery<DashboardData, ServerError>({
    queryKey: ["dashboard"],
    keepPreviousData: true,
    queryFn: () => axios.get("/general/api/customer-support/dashboard", {}),
  });
};
export const useGetOrdersSummary = () => {
  const axios = useAxios();
  return useQuery<OrderSummaryData, ServerError>({
    queryKey: ["order-summary"],
    keepPreviousData: true,
    queryFn: () => axios.get("/general/api/customer-support/order-summary", {}),
  });
};
export const useGetUsersSummary = () => {
  const axios = useAxios();
  return useQuery<UserSummaryData, ServerError>({
    queryKey: ["users-summary"],
    keepPreviousData: true,
    refetchInterval: 30000,
    queryFn: () => axios.get("/general/api/customer-support/user-summary", {}),
  });
};
export interface DashboardData {
  totalOrders: string;
  pendingOrders: string;
  totalUsers: string;
  totalSubscribers: string;
}
export interface OrderSummaryData {
  deliveredOrders: string;
  pendingOrders: string;
  canceledOrders: string;
  abandonedCarts: string;
}
export interface UserSummaryData {
  totalUsers: string;
  activeUsers: string;
  inactiveUsers: string;
  totalSubscribers: string;
}
