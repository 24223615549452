import { InfoSection } from "./InfoSection";
import { Box } from "@mui/material";
import { useGetAbandonedInfoCart } from "../../../../services/order";
import { Link, useNavigate, useParams } from "react-router-dom";
import RecipientSection from "./RecipientSection";
import { useDocumentTitle } from "suregifts-component-lib";

import { BackLinkView } from "../../../../components/molecules/BackLinkView";
function AbandonedCartInfoScreen() {
  useDocumentTitle("Abandoned Carts");
  const { cartId } = useParams();
  const { data, isLoading } = useGetAbandonedInfoCart(cartId);
  const navigate = useNavigate();
  return (
    <div style={{ marginTop: "2.5rem" }}>
      <BackLinkView label={`Cart #${data?.id}`} onClick={() => navigate("/carts")} />
      <Box
        sx={(theme) => ({
          display: "grid",
          gap: "2.375rem",
          marginTop: "2.5rem",
          gridTemplateColumns: "1fr",
          [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "2fr 1fr",
          },
        })}
      >
        <RecipientSection data={data} />
        <InfoSection data={data} />
      </Box>
    </div>
  );
}

export default AbandonedCartInfoScreen;
