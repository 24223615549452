import { Stack } from "@mui/material";
import { register } from "numeral";
import React, { useMemo } from "react";
import { Controller } from "react-hook-form";
import { FormInput, FormSelect } from "suregifts-component-lib";
import { useGetMerchantNames, useGetStates } from "../../../../services/merchants";

function AddEditStoreView({ register, control, errors }) {
  const { data: merchantNames } = useGetMerchantNames();
  const merchants = useMemo(() => {
    return merchantNames?.filter((x) => !x.isGeneric);
  }, [merchantNames]);
  const { data: states } = useGetStates();
  return (
    <>
      <Stack spacing={2}>
        <FormInput required label="Name" {...register("name")} helperText={errors.name?.message} />

        <FormInput multiline required label="Address" {...register("address")} helperText={errors.address?.message} />
        <Controller control={control} name="stateId" render={({ field }) => <FormSelect required options={states} label="State" helperText={errors.stateId?.message} {...field} />} />
        <Controller control={control} name="merchantId" render={({ field }) => <FormSelect required options={merchants} label="Merchant" helperText={errors.merchantId?.message} {...field} />} />
        <FormInput required label="Phone" {...register("contactPhone")} helperText={errors.contactPhone?.message} />
        <FormInput required label="Email Address" {...register("email")} helperText={errors.email?.message} />
      </Stack>
    </>
  );
}

export default AddEditStoreView;
