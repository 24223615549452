import { TableHead, TableRow, TableCell } from "@mui/material";
import { MainTable, PageFilter, Pagination, TableBody, dateTime } from "suregifts-component-lib";
import { useGetAbandonedCart } from "../../../../services/order";
import { useNavigate } from "react-router-dom";

type TableViewProps = {
  filter: PageFilter;
  handlerFilter: (params: any) => void;
};

function TableView({ filter, handlerFilter }: TableViewProps) {
  const { data, isLoading } = useGetAbandonedCart(filter);
  const navigate = useNavigate();
  return (
    <div>
      <MainTable loading={isLoading} sx={{ marginTop: "2.5rem" }}>
        <TableHead>
          <TableRow>
            <TableCell>Cart Id</TableCell>
            <TableCell>User</TableCell>
            <TableCell>User Email</TableCell>
            <TableCell>No of gifts</TableCell>
            <TableCell>Date Created</TableCell>
            <TableCell>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items.map((item) => (
            <TableRow key={item.cartNumber} onClick={() => navigate(`${item.id}`)} sx={{ cursor: "pointer" }}>
              <TableCell>#{item.cartNumber}</TableCell>
              <TableCell>{item.senderName}</TableCell>
              <TableCell>{item.SendEmail}</TableCell>
              <TableCell>{item.quantity}</TableCell>
              <TableCell>{dateTime(item.dateCreated)}</TableCell>
              <TableCell>{item.amountDue}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination page={filter.page} total={data?.total} onChange={(page) => handlerFilter({ page })} />
      </div>
    </div>
  );
}

export default TableView;
